import L from 'leaflet';
import moment from 'moment';
import {compact} from 'underscore';
import capitalize from 'capitalize';
import {List} from 'immutable';

import Configuration from '../configuration';
import Model from './Model';
import Broker from './Broker';
import Agent from './Agent';
import {slugify} from '../utils/urls';
import {lookupObjectKeyPath} from '../utils/objects';
import {formatPropertyType} from '../support/search';
import GeoLocation from '../maps/GeoLocation';

export default class Listing extends Model {
  static TYPES = ['for_sale', 'for_rent'];

  // From which time do we want to show upcoming open houses?
  static getUpcomingOpenHouseStartingTime() {
    return moment().startOf('day');
  }

  constructor(attributes = null) {
    delete attributes.nuid; // Conflicts with getter
    super(attributes);
    if (this.agent && !(this.agent instanceof Agent)) {
      this.agent = new Agent(this.agent);
    }
    if (this.broker && !(this.broker instanceof Broker)) {
      this.broker = new Broker(this.broker);
    }
  }

  hasFloorPlans() {
    return this.floor_plans && this.floor_plans.count > 0;
  }

  hasVirtualTour() {
    return this.virtual_tour && !!this.virtual_tour.url;
  }

  hasVideos() {
    const {videos} = this;
    return videos && Array.isArray(videos) && videos.length !== 0;
  }

  slugify() {
    return slugify(this.formatTitle());
  }

  formatTitle() {
    return compact([
      this.street_address,
      this.city,
      this.state,
      this.zip_code,
      this.mls_number && `MLS# ${this.mls_number}`,
    ]).join(', ');
  }

  getListingIds() {
    const config = Configuration.get('ui.details_page.listing_ids');
    if (!Array.isArray(config)) {
      // Avoid legacy non-array configuration from tripping up logic
      return [];
    }
    return config
      .map(({label, path}) => {
        const value = lookupObjectKeyPath(path, this.asObject());
        return value ? {label, value} : null;
      })
      .filter((v) => !!v);
  }

  getGeoLocation() {
    const latLng = this.getLatLng();
    if (latLng) {
      return new GeoLocation({latlng: latLng});
    }
    if (this.street_address != null && this.zip_code != null) {
      return new GeoLocation({
        streetAddress: this.street_address,
        zipCode: this.zip_code,
        state: this.state,
        city: this.city,
      });
    }
    return null;
  }

  getLatLng() {
    const position = this.geocode;
    if (position && position.lat && position.lng) {
      try {
        return L.latLng(position.lat, position.lng);
      } catch (err) {
        console.warn('Invalid latlng:', position);
      }
    }
  }

  getFormattedPropertyType() {
    return formatPropertyType(this.property_type);
  }

  get hasStreetAddress() {
    return this.street_address != null || this.property_name != null;
  }

  formatStreetAddress() {
    const address = this.street_address || this.property_name;
    if (address) {
      return address;
    }
    if (Configuration.get('ui.hide_undisclosed_addresses')) {
      return '';
    }
    return '[Undisclosed address]';
  }

  formatCityStateZip() {
    const city = this.city;
    const state = this.state;
    const zip = this.zip_code;
    return compact([city && capitalize.words(city), state && state.toUpperCase(), zip]).join(', ');
  }

  getPhotoUrls() {
    if (!this.photo_count || !this.photo_template) {
      return List();
    }
    let photos = [];
    for (let offset = 0; offset < this.photo_count; offset++) {
      const url = this.photo_template.replace('{offset}', offset).replace('{size}', 'medium');
      photos.push(url);
    }
    if (!this.isActive() && photos.length > 1) {
      photos = [photos[0]];
    }
    return new List(photos);
  }

  getPrimaryPhotoUrl(size) {
    if (!this.photo_count || !this.photo_template) {
      return null;
    }
    const url = this.photo_template
      .replace('{offset}', 0)
      .replace('{size}', encodeURIComponent(size));
    return url;
  }

  isFeatured() {
    return `${this.featured}` === 'true';
  }

  isNewerThan(days) {
    let t;
    if (Configuration.get('data.has_creation_time')) {
      const creationTime = this.external_listing && this.external_listing.creation_time;
      t = creationTime ? moment(creationTime).toDate() : null;
    } else {
      t = moment(this.created_at).toDate();
    }
    return t && t >= moment().startOf('day').subtract(days, 'days').toDate();
  }

  isActive() {
    return (
      !this.status ||
      this.status.toLowerCase() === 'active' ||
      this.status.toLowerCase() === 'active-contingent' ||
      this.status.toLowerCase() === 'under_contract' ||
      this.status.toLowerCase() === 'pending'
    );
  }

  isSold() {
    return !!this.status && this.status.toLowerCase() === 'sold';
  }

  isOffMarket() {
    return !!this.status && this.status.toLowerCase() === 'off_market';
  }

  // Returns numeric part of UID. Backwards compatibility with Grove UIDs.
  get nuid() {
    const match = /^post.listing:.*\$(\d+)$/.exec(this.id);
    return match ? match[1] : this.id;
  }

  // Returns an array of {start, end} times, as Moment instances.
  getOpenHouseDates() {
    if (Configuration.get('data.open_houses.disabled')) {
      return [];
    }
    return (this.open_house_dates || [])
      .concat(this.promoted_open_house_dates || [])
      .concat(this.virtual_open_house_dates || [])
      .map((item) => ({
        start: moment(item.start),
        end: moment(item.end),
        isVirtual: !!item.url,
        url: item.url,
      }));
  }

  hasUpcomingOpenHouseItems() {
    return this.getUpcomingOpenHouseItems().length > 0;
  }

  // Returns an array of {start, end} times, as Moment instances.
  getUpcomingOpenHouseItems() {
    const since = Listing.getUpcomingOpenHouseStartingTime();
    return this.getOpenHouseDates()
      .filter((item) => item.start.isAfter(since))
      .sort((a, b) => (a.start.isAfter(b.start) ? 1 : -1));
  }

  previousPrice() {
    if (this.price_history && this.price_history.length > 0) {
      return this.price_history[0].old_price;
    }
    return null;
  }

  getDescription() {
    const descriptionParts = [];
    const price = this.price;
    const rent = this.rent;
    if (price) {
      descriptionParts.push(`Price: $${price}`);
    }
    if (rent) {
      descriptionParts.push(`Rent: $${rent}`);
    }
    const priceRange = this.price_range;
    if (priceRange) {
      descriptionParts.push(`Price from: $${priceRange.min}`);
    }
    const rentRange = this.rent_range;
    if (rentRange) {
      descriptionParts.push(`Rent from: $${rentRange.min}`);
    }
    const address = this.undisclosed_address ? null : this.street_address;
    const city = this.city;
    const state = this.state;
    const zipCode = this.zip_code;
    if (address || city) {
      descriptionParts.push(`Address: ${compact([address, city, state, zipCode]).join(', ')}`);
    }

    const description = descriptionParts.join(', ');
    return description;
  }
}
