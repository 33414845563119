import React, {useEffect, useState} from 'react';

import {PriorityControls} from '../PriorityControls.react';
import Sizer from '../common/Sizer';
import ListingQuery, {
  DEFAULT_ORDER,
  ListingSearchResultSet,
} from '../../../lib/search/ListingQuery';
import ListingResultList from './ListingResultList';
import ListingQueryDescription from '../search/ListingQueryDescription';
import {zeus} from '../../../lib/zeus';

export const SimilarListingsGrid = ({listing}) => {
  const [query] = useState(ListingQuery.newSimilarityQuery(listing));
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [resultSet, setResultSet] = useState();
  useEffect(() => {
    query.setOrder(order);
    const q = query.toGraphQL();
    q.limit = 30;
    q.filter = Object.assign(q.filter || {}, {
      excludeIds: [listing.id],
    });
    let cancelled = false;
    (async () => {
      const data = await zeus(`/api/query/listings`, {queryObj: q});
      if (!cancelled) {
        const rs = ListingSearchResultSet.fromObject(data.listings);
        setResultSet(rs);
      }
    })();
    return () => {
      cancelled = true;
    };
  }, [listing, query, order]);
  if (!resultSet) {
    return <div className="SimilarListingsGrid loading"></div>;
  }
  return (
    <div className="SimilarListingsGrid">
      {query.canOrder() && (
        <div className="SimilarListingsGrid_head">
          <h3>Drag to change the sort order.</h3>
          <PriorityControls order={order} orderables={query.getOrderables()} onChange={setOrder} />
          <p className="SimilarListingsGrid_head_desc">
            Showing listings: <ListingQueryDescription query={query} />
          </p>
        </div>
      )}
      <div className="SimilarListingsGrid_results">
        <Sizer
          child={({width}) => (
            <ListingResultList
              width={width}
              query={query}
              resultSet={resultSet}
              showCloseMatchDivider={false}
              showExactnessLabel={false}
              showMapHint={false}
            />
          )}
        />
      </div>
    </div>
  );
};
