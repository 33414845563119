import {parseLatLng, parseLatLngBounds} from '../utils/geo';

// TODO: Make configurable
const DEFAULT_COUNTRIES = ['USA', 'US', 'United States'];

export default class GeoLocation {
  constructor(attributes) {
    if (attributes) {
      this.latlng = parseLatLng(attributes.latlng);
      this.bounds = parseLatLngBounds(attributes.bounds);
      for (const key of [
        'streetAddress',
        'city',
        'zipCode',
        'zipCodes',
        'state',
        'country',
        'county',
        'fulltext',
        'isCurrentLocation',
        'shape',
        'territory',
      ]) {
        const value = attributes[key];
        if (value != null) {
          this[key] = value;
        }
      }
    }
  }

  format() {
    if (this.isCurrentLocation) {
      return 'Your location';
    }
    if (this.territory) {
      return this.territory.title;
    }
    let parts = [];
    if (this.streetAddress) {
      parts.push(this.streetAddress);
    }
    if (this.city) {
      parts.push(this.city);
    }
    if (this.zipCode && this.state) {
      parts.push(`${this.state} ${this.zipCode}`);
    } else {
      if (this.zipCode) {
        parts.push(this.zipCode);
      }
      if (this.state) {
        parts.push(this.state);
      }
    }
    if (this.zipCodes && this.zipCodes.length) {
      parts = parts.concat(this.zipCodes);
    }
    if (this.county && !this.zipCode && !this.state) {
      parts.push(this.county);
    }
    if (this.country && DEFAULT_COUNTRIES.indexOf(this.country) === -1) {
      parts.push(this.country);
    }
    if (parts.length === 0 && this.fulltext && this.fulltext.length > 0) {
      parts.push(this.fulltext);
    }
    return parts.join(', ');
  }

  equals(other) {
    return (
      other instanceof GeoLocation &&
      this.streetAddress == other.streetAddress &&
      this.city == other.city &&
      this.state == other.state &&
      this.zipCode == other.zipCode &&
      this.zipCodes == other.zipCodes &&
      this.country == other.country &&
      this.county == other.county &&
      this.fulltext == other.fulltext &&
      this.shape == other.shape &&
      this.isCurrentLocation == other.isCurrentLocation &&
      (this.territory
        ? other.territory != null && this.territory.equals(other.territory)
        : other.territory == null) &&
      (this.latlng
        ? other.latlng != null && this.latlng.equals(other.latlng)
        : other.latlng == null) &&
      (this.bounds
        ? other.bounds != null && this.bounds.equals(other.bounds)
        : other.bounds == null)
    );
  }

  isEmpty() {
    return !(
      this.streetAddress ||
      this.city ||
      this.state ||
      this.zipCode ||
      this.zipCodes ||
      this.country ||
      this.county ||
      this.fulltext ||
      this.shape ||
      this.isCurrentLocation ||
      this.territory
    );
  }

  get latLng() {
    return this.latlng;
  }

  isSet() {
    return !this.isEmpty();
  }

  asObject() {
    return {
      streetAddress: this.streetAddress,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      zipCodes: this.zipCodes,
      country: this.country,
      county: this.county,
      fulltext: this.fulltext,
      shape: this.shape,
      isCurrentLocation: this.isCurrentLocation,
      territory: this.territory,
      latlng: this.latLng ? [this.latLng.lat, this.latLng.lng] : null,
      bounds: this.bounds
        ? [
            this.bounds.getNorth(),
            this.bounds.getEast(),
            this.bounds.getSouth(),
            this.bounds.getWest(),
          ]
        : null,
    };
  }
}
