import {LOCATION_CHANGE} from 'react-router-redux';
import {Promise} from 'bluebird';

import {absoluteUrlFromPath} from '../utils/urls';
import {analyticsEvent, ANALYTICS_EVENT} from '../actions/analytics';
import ListhubMetricsAdapter from '../analytics/listhub';
import GoogleAnalyticsAdapter from '../analytics/googleAnalytics';

const ADAPTERS = [GoogleAnalyticsAdapter, ListhubMetricsAdapter];

// Centralizes analytics capture.
export function createAnalyticsMiddleware() {
  const adapters = configureAdapters({});

  if (adapters.size === 0) {
    return null;
  }

  const handleAnalyticsEvent = (event) => {
    return iterateIgnoringErrors(adapters, (adapter) =>
      adapter.handleAnalyticsEvent(event.name, event.data)
    );
  };

  let previousUrl = null;
  return (_) => (next) => (action) => {
    switch (action.type) {
      case ANALYTICS_EVENT:
        handleAnalyticsEvent(action);
        break;
      case LOCATION_CHANGE: {
        const {payload} = action;
        const url = absoluteUrlFromPath(`${payload.pathname}${payload.search}`);
        if (url !== previousUrl) {
          previousUrl = url;
          handleAnalyticsEvent(analyticsEvent('pageView', {url}));
        }
        break;
      }
    }
    return next(action);
  };
}

// Iterate using a mapper that produces promises, handling errors in each mapper.
async function iterateIgnoringErrors(items, mapper) {
  await Promise.all(
    items.map((item) => {
      const result = mapper(item);
      if (result) {
        return result.catch((err) => {
          console.log('Warning: Error handling analytics event, ignoring:', err);
        });
      }
      return undefined;
    })
  );
}
function configureAdapters(options) {
  return ADAPTERS.map((adapter) => {
    try {
      return adapter.configure(options);
    } catch (err) {
      console.log(`Unable to start analytics adapter ${adapter.name}, ignoring:`, err);
      return null;
    }
  }).filter((a) => a != null);
}
