import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {analyticsEvent} from '../../lib/actions';
import EmailLink from './common/EmailLink';
import PhoneLink from './common/PhoneLink';

export default connect()(
  class BrokerDetails extends React.Component {
    static propTypes = {
      broker: PropTypes.object.isRequired,
      view: PropTypes.string,
      dispatch: PropTypes.func.isRequired,
    };

    static defaultProps = {
      view: 'horizontal',
    };

    render() {
      const {broker, view} = this.props;
      const {name, phone, website, email, logo_url} = broker;

      return (
        <div className={`BrokerDetails ${view}`}>
          <div className="BrokerDetails_header">
            <div className="BrokerDetails_header_description">
              {name && <h3 className="BrokerDetails_name">{name}</h3>}

              <ul className="BrokerDetails_info">
                {logo_url && view === 'vertical' && (
                  <li className="BrokerDetails_info_item">
                    <img src={logo_url} title={broker.name} />
                  </li>
                )}
                {phone && (
                  <li className="BrokerDetails_info_item" data-item-type="phone">
                    <PhoneLink
                      number={phone}
                      type="phone"
                      onShow={() =>
                        this.props.dispatch(
                          analyticsEvent('clickedBrokerClickToCallButton', {broker})
                        )
                      }
                    />
                  </li>
                )}
                {website && (
                  <li className="BrokerDetails_info_item" data-item-type="web">
                    <a href={website} target="_blank">
                      Website
                    </a>
                  </li>
                )}
                {email && (
                  <li className="BrokerDetails_info_item" data-item-type="email">
                    <EmailLink
                      email={email}
                      onShow={() =>
                        this.props.dispatch(analyticsEvent('clickedBrokerShowEmail', {broker}))
                      }
                    />
                  </li>
                )}
              </ul>
            </div>

            {logo_url && view === 'horizontal' && (
              <div className="BrokerDetails_header_logo">
                <img src={logo_url} title={broker.name} />
              </div>
            )}
          </div>
        </div>
      );
    }
  }
);
