import React from 'react';

import Configuration from '../../../lib/configuration';
import {CMMortgageCalculatorLine} from './CMMortgageCalculatorLine';
import {CMMortgageCalculatorDonutBranded} from './CMMortgageCalculatorDonutBranded';
import {CMMortgageCalculatorLineBranded} from './CMMortgageCalculatorLineBranded';

const ListingDetailMortgageCalculator = ({listing}) => {
  const calculatorType = Configuration.get('ui.details_page.mortgage_calculator');
  if (!calculatorType) {
    return null;
  }
  const cordlessPid = Configuration.get('ads.cordless_pid');
  if (calculatorType === 'cordless_line') {
    return (
      cordlessPid && (
        <CMMortgageCalculatorLine
          address={listing.formatStreetAddress()}
          zip={listing.zip_code}
          price={listing.price ? String(listing.price) : null}
          pid={cordlessPid}
        />
      )
    );
  }
  if (calculatorType === 'cordless_donut_branded') {
    return (
      cordlessPid && (
        <CMMortgageCalculatorDonutBranded
          address={listing.formatStreetAddress()}
          zip={listing.zip_code}
          price={listing.price ? String(listing.price) : null}
          pid={cordlessPid}
        />
      )
    );
  }
  if (calculatorType === 'cordless_line_branded') {
    return (
      cordlessPid && (
        <CMMortgageCalculatorLineBranded
          address={listing.formatStreetAddress()}
          zip={listing.zip_code}
          price={listing.price ? String(listing.price) : null}
          pid={cordlessPid}
        />
      )
    );
  }
  return null;
};

export default ListingDetailMortgageCalculator;
