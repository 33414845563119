export function lookupKeyPath(keypath, obj) {
  if (!obj) {
    return undefined;
  }

  const index = keypath.indexOf('.');
  if (index === -1) {
    return obj[keypath];
  }
  const key = keypath.slice(0, index);
  const rest = keypath.slice(index + 1);

  const value = obj[key];
  if (value === undefined) {
    return value;
  }

  return lookupKeyPath(rest, value);
}
