import React from 'react';

import styles from './HouseTile.module.css';
import {substitute_undefined} from '../../../lib/utils/misc';

const HouseTile = ({listing}) => {
  if (!listing) {
    return null;
  }
  return (
    <a className={styles.root} href={listing.url}>
      <div className={styles.details}>
        <div>
          {listing.city && listing.state
            ? `${listing.city}, ${listing.state}`
            : listing.city || listing.state || ''}
        </div>
        <div>
          {substitute_undefined(listing.bedrooms, '-')} bd /{' '}
          {substitute_undefined(listing.bathrooms, '-')} br
        </div>
      </div>
      <img
        className={styles.photo}
        src={listing.photo_template?.replace('{offset}', '0')?.replace('{size}', 'medium')}
      />
    </a>
  );
};

export default HouseTile;
