import React from 'react';
import PropTypes from 'prop-types';
import {map, max} from 'underscore';
import ReactDOM from 'react-dom';

const START_ZINDEX = 50000;

let stackElement;

// Creates a special layer in a stack of layers in a different part of
// the DOM tree, and maintains the props transfer. Useful for floaters
// and modals that need to be rendered by another component.
class LayeredComponent extends React.Component {
  static propTypes = {
    renderLayer: PropTypes.func.isRequired,
  };

  static getStackElement() {
    let element = stackElement;
    if (!element) {
      // TODO: We must add .enclosure_widget here get the style classes
      //   when embedded as widget. Make this more elegant and generic!
      stackElement = document.createElement('div');
      stackElement.className = 'enclosure_widget';
      document.body.appendChild(stackElement);
      element = stackElement;
    }
    return element;
  }

  componentWillUnmount() {
    this._unrenderLayer();
  }

  componentDidUpdate(_prevProps, _prevState) {
    this._renderLayer();
  }

  componentDidMount() {
    this._renderLayer();
  }

  _renderLayer() {
    const childNodes = LayeredComponent.getStackElement().childNodes;
    if (childNodes.length >= 0) {
      const zIndexes = map(childNodes, (child) => parseInt(child.style.zIndex));
      const zIndex = zIndexes.length > 0 ? max(zIndexes) + 1 : START_ZINDEX;

      const layer = this.props.renderLayer({zIndex});
      if (layer) {
        if (!this._layerElement) {
          this._layerElement = document.createElement('div');
          LayeredComponent.getStackElement().appendChild(this._layerElement);
        }
        this._layerElement.style.zIndex = zIndex;
        ReactDOM.render(layer, this._layerElement);
        return;
      }
    }

    this._unrenderLayer();
  }

  _unrenderLayer() {
    const element = this._layerElement;
    if (element) {
      this._layerElement = null;
      ReactDOM.unmountComponentAtNode(element);
      element.parentNode.removeChild(element);
    }
  }

  render() {
    return null;
  }
}

export default LayeredComponent;
