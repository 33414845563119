import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'capitalize';

import {formatNumberWithCommas} from '../../../../lib/node_utils';
import Dialog from '../../common/Dialog';
import ListingQuery, {ListingSearchResultFacets} from '../../../../lib/search/ListingQuery';
import Configuration from '../../../../lib/configuration';
import GenericFacetFilter from './GenericFacetFilter';
import RangeDropdownFilter from './RangeDropdownFilter';
import LocationFilter from './LocationFilter';
import TextFilter from './TextFilter';
import Labeled from './Labeled';
import {
  formatPrice,
  OPEN_HOUSE_ITEMS,
  NEW_LISTING_DATE_ITEMS,
} from '../../../../lib/support/search';

class ListingFilterDialog extends React.Component {
  static propTypes = {
    query: PropTypes.instanceOf(ListingQuery).isRequired,
    facets: PropTypes.instanceOf(ListingSearchResultFacets).isRequired,
    onUpdateQuery: PropTypes.func.isRequired,
    showType: PropTypes.bool,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    showType: true,
    visible: false,
  };

  constructor(props) {
    super(props);
    this._locationRequired = Configuration.get('ui.search.require_location') === true;
    this._videos = Configuration.get('ui.search.videos') === true;
    this._photos = Configuration.get('ui.search.hide_photos_filter') !== true;
    this._virtual_tours = Configuration.get('ui.search.show_virtual_tour_filter') === true;
    this._nearest_schools = Configuration.get('ui.search.show_nearest_schools_filter') === true;
    this._subdivision = Configuration.get('ui.search.show_subdivision_filter') === true;
  }

  render() {
    const {query, facets: f, onUpdateQuery, showType} = this.props;
    const facets = f.facets;
    const showFeatures = (Configuration.get('ui.search.show_feature_ids') || []).length > 0;

    return (
      <Dialog
        visible={this.props.visible}
        className="ListingFilterDialog"
        onClose={this.props.onClose}>
        <div className="ListingFilterDialog_buttons">
          <a onClick={() => this.props.onClose()}>{'Close'}</a>
        </div>
        <div className="ListingFilterDialog_content">
          <Labeled label="Find near">
            <LocationFilter {...{query, facets, onUpdateQuery}} required={this._locationRequired} />
          </Labeled>

          {showType && (
            <Labeled label="Category">
              <GenericFacetFilter
                {...{query, facets, onUpdateQuery}}
                attrName="type"
                notResettable
                mutex
              />
            </Labeled>
          )}

          {!!Configuration.get('ui.search.sections') && (
            <Labeled label="Section">
              <GenericFacetFilter
                {...{query, facets, onUpdateQuery}}
                attrName="section"
                unsetLabel={Configuration.get('ui.search.sections.default')}
                ignoreEmpty
                mutex
              />
            </Labeled>
          )}

          <Labeled label="Type">
            <GenericFacetFilter
              {...{query, facets, onUpdateQuery}}
              attrName="property_type"
              multiSelect
              ignoreEmpty
            />
          </Labeled>

          <Labeled label="Beds">
            <GenericFacetFilter
              {...{query, facets, onUpdateQuery}}
              attrName="bedrooms"
              rangeKey="bedrooms_range.max"
              formatter={formatNumberWithCommas}
            />
          </Labeled>

          <Labeled label="Baths">
            <GenericFacetFilter {...{query, facets, onUpdateQuery}} attrName="bathrooms" />
          </Labeled>

          {query.get('type') === 'for_sale' ? (
            <Labeled label="Price">
              <RangeDropdownFilter
                {...{query, facets, onUpdateQuery}}
                attrName="price"
                formatter={formatPrice}
              />
            </Labeled>
          ) : (
            <Labeled label="Rent">
              <RangeDropdownFilter
                {...{query, facets, onUpdateQuery}}
                attrName="rent"
                formatter={formatPrice}
              />
            </Labeled>
          )}

          {this._nearest_schools && (
            <Labeled label="Schools">
              <TextFilter
                {...{query, onUpdateQuery}}
                attrName="nearest_schools"
                placeholder="Name of school"
              />
            </Labeled>
          )}

          {this._subdivision && (
            <Labeled label="Subdivision">
              <TextFilter
                {...{query, onUpdateQuery}}
                attrName="subdivision"
                placeholder="Name of subdivision"
              />
            </Labeled>
          )}

          <Labeled label="Square footage">
            <RangeDropdownFilter {...{query, facets, onUpdateQuery}} attrName="square_footage" />
          </Labeled>

          <Labeled label="Lots">
            <RangeDropdownFilter {...{query, facets, onUpdateQuery}} attrName="lots" />
          </Labeled>

          {showFeatures && (
            <Labeled label="Required Features">
              <GenericFacetFilter
                {...{query, facets, onUpdateQuery}}
                attrName="required_features"
                facetName="features"
                ignoreKeys={query.get('exclude_features')}
                zIndex={1006}
                notResettable
                multiSelect
              />
            </Labeled>
          )}

          {showFeatures && (
            <Labeled label="Exclude Features">
              <GenericFacetFilter
                {...{query, facets, onUpdateQuery}}
                attrName="exclude_features"
                facetName="features"
                ignoreKeys={query.get('required_features')}
                zIndex={1006}
                notResettable
                multiSelect
              />
            </Labeled>
          )}

          {this._photos && (
            <Labeled label="Photos">
              <GenericFacetFilter
                {...{query, facets, onUpdateQuery}}
                attrName="has_photos"
                normalizer={(v) => !!v}
                ignoreKeys={['false']}
              />
            </Labeled>
          )}

          {this._videos && (
            <Labeled label="Videos">
              <GenericFacetFilter
                {...{query, facets, onUpdateQuery}}
                attrName="has_videos"
                normalizer={(v) => !!v}
                ignoreKeys={['false']}
              />
            </Labeled>
          )}

          {this._virtual_tours && (
            <Labeled label="Virtual Tours">
              <GenericFacetFilter
                {...{query, facets, onUpdateQuery}}
                attrName="has_virtual_tours"
                normalizer={(v) => !!v}
                ignoreKeys={['false']}
              />
            </Labeled>
          )}

          {!Configuration.get('data.open_houses.disabled') && (
            <Labeled label="Open house">
              <GenericFacetFilter
                {...{query, facets, onUpdateQuery}}
                attrName="open_house_dates"
                items={OPEN_HOUSE_ITEMS}
              />
            </Labeled>
          )}

          <Labeled label="New listings">
            <GenericFacetFilter
              {...{query, facets, onUpdateQuery}}
              attrName="publishing_timeframe"
              items={NEW_LISTING_DATE_ITEMS}
            />
          </Labeled>

          {!!Configuration.get('ui.search.status_filter') && (
            <Labeled label="Status">
              <GenericFacetFilter
                {...{query, facets, onUpdateQuery}}
                attrName="status"
                onlyKeys={Configuration.get('ui.search.status_filter.options')}
                multiSelect
                notResettable
                zIndex={1006}
                formatter={(s) => capitalize.words(s).replace(/_/g, ' ')}
              />
            </Labeled>
          )}

          <Labeled label="Listing ID">
            <TextFilter
              {...{query, facets, onUpdateQuery}}
              placeholder="E.g. MLS number"
              attrName="listing_id"
            />
          </Labeled>

          {!!Configuration.get('ui.search.show_year_built_filter') && (
            <Labeled label="Year Built (or newer)">
              <TextFilter
                {...{query, facets, onUpdateQuery}}
                placeholder="E.g. 1984"
                attrName="year_built"
                validator={(value) => value === '' || /^\d{4}$/.test(value)}
              />
            </Labeled>
          )}

          <Labeled label="Description">
            <TextFilter {...{query, facets, onUpdateQuery}} attrName="text" />
          </Labeled>
        </div>
      </Dialog>
    );
  }
}

export default ListingFilterDialog;
