import React from 'react';
import PropTypes from 'prop-types';

import {ScheduledFunction} from '../../../lib/node_utils';
import {linkState} from '../../../lib/support/react';

class TextInput extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    'aria-label': PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    valueLink: PropTypes.object,
    valid: PropTypes.bool,
    selectOnFocus: PropTypes.bool,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  };

  static defaultProps = {
    placeholder: '',
    type: 'text',
    value: null,
    readOnly: false,
    selectOnFocus: false,
  };

  state = {
    value: this.props.value,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({value: nextProps.value});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.onChange && prevState.value !== this.state.value) {
      ScheduledFunction.wrap(this._triggerChange).schedule(250);
    }
  }

  render() {
    return (
      <span
        className="TextInput"
        data-type={this.props.type}
        data-valid={this.props.valid}
        data-required={this.props.required}>
        {this.props.type === 'textarea' ? (
          <textarea
            aria-label={this.props['aria-label'] || this.props.placeholder}
            ref={(e) => (this.inputElement = e)}
            rows="5"
            readOnly={this.props.readOnly}
            value={this.state.value}
            onChange={linkState(this, 'value')}
            placeholder={this.props.placeholder}
            onFocus={this.props.onFocus}
          />
        ) : (
          <input
            aria-label={this.props['aria-label'] || this.props.placeholder}
            ref={(e) => (this.inputElement = e)}
            type={this.props.type}
            readOnly={this.props.readOnly}
            size="20"
            value={this.state.value}
            onChange={linkState(this, 'value')}
            placeholder={this.props.placeholder}
            onFocus={this._handleFocus}
            onBlur={this.props.onBlur}
          />
        )}
        <span className="TextInput_status" />
      </span>
    );
  }

  focus() {
    this.inputElement.focus();
  }

  getInputElement() {
    return this.inputElement;
  }

  _handleFocus = (event) => {
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
    if (this.props.selectOnFocus) {
      const input = event.target;
      setTimeout(() => {
        if (input.setSelectionRange) {
          input.setSelectionRange(0, input.value.length);
        } else if (typeof input.select === 'function') {
          input.select();
        }
      }, 1);
    }
  };

  _triggerChange = () => {
    if (this.props.onChange) {
      this.props.onChange(this.state.value);
    }
  };
}

export default TextInput;
