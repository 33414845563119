import React from 'react';
import PropTypes from 'prop-types';
import {uniq} from 'underscore';

import './Panorama.scss';
import {observeWindowSize, WindowSizeShape} from '../wrappers/windowSizeObserver';
import Configuration from '../../../lib/configuration';
import Carousel from '../common/carousel/Carousel';
import {YouTubeVideo} from '../common/carousel/YouTubeVideo';
import {Html5Video} from '../common/carousel/Html5Video';
import Image from '../common/carousel/Image';
import Sizer from '../common/Sizer';

const photoHeights = {
  featured: 600,
  nonFeatured: 400,
  min: 300,
};

export default observeWindowSize(
  class Panorama extends React.Component {
    static propTypes = {
      listing: PropTypes.object.isRequired,
      windowDimensions: WindowSizeShape,
    };

    _desiredPhotoAspect = 2 / 3;

    render() {
      const {listing} = this.props;

      const photoUrls = listing.getPhotoUrls().toJS();

      const largePhotos = Configuration.get('ui.details_page.large_photos');
      const maxHeight = Math.min(
        this.props.windowDimensions.height,
        listing.isFeatured() || largePhotos ? photoHeights.featured : photoHeights.nonFeatured
      );

      const videos = uniq(
        Object.values(listing.videos || {}).filter((v) => v && v.id && v.provider),
        false,
        (p) => p.id
      );

      return (
        <div className="Panorama">
          {photoUrls.length > 0 || videos.length > 0 ? (
            <Sizer
              child={({width}) => (
                <div className="Panorama_photos">
                  <Carousel
                    count={videos.length + photoUrls.length}
                    width={width}
                    height={Math.floor(
                      Math.min(
                        maxHeight,
                        Math.max(photoHeights.min, width * this._desiredPhotoAspect)
                      )
                    )}
                    cellRenderer={(idx, hasFocus) =>
                      idx < videos.length ? (
                        renderVideo(videos[idx], width, maxHeight, hasFocus)
                      ) : (
                        <Image url={photoUrls[idx - videos.length]} alt={listing.formatTitle()} />
                      )
                    }
                    cellWidth={Math.round(
                      Math.min(maxHeight, width * this._desiredPhotoAspect) /
                        this._desiredPhotoAspect
                    )}
                    spacing={2}
                  />
                </div>
              )}
            />
          ) : (
            <div className="Panorama_photos">
              <div className="Panorama_photos_none">
                <span>Check back for photo updates.</span>
              </div>
            </div>
          )}

          {listing.isFeatured() && (
            <div className="Panorama_featured">
              <span>Featured Listing</span>
            </div>
          )}
        </div>
      );
    }
  }
);

function renderVideo(video, width, height, hasFocus) {
  switch (video.provider) {
    case 'youtube':
      return <YouTubeVideo id={video.id} width={width} height={height} hasFocus={hasFocus} />;
    case 'html5':
      return <Html5Video url={video.url} width={width} height={height} hasFocus={hasFocus} />;
  }
  return null;
}
