import React from 'react';
import PropTypes from 'prop-types';

import Button from './common/Button';
import {urlForBackToSearch} from '../../lib/support/routing';

export default class ErrorPage extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    returnLink: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    returnLink: false,
  };

  render() {
    return (
      <div className="ErrorPage">
        <div className="ErrorPage_content">
          <h1>{this.props.title}</h1>
          {this.props.message && <h2>{this.props.message}</h2>}
          {this.props.returnLink && (
            <div className="Error_return">
              <Button kind="submit" href={urlForBackToSearch()} label="Go back" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
