import {cloneElement} from 'react';

/**
 * Clones one or more React elements, supplying an index-based key.
 * Null, undefined and boolean elements are ignored (but counted as
 * index).
 */
export function cloneElementsWithIndexKeys(...elements) {
  return cloneRecursively('', ...elements);
}

function cloneRecursively(prefix, ...elements) {
  return elements
    .map((elem, idx) => {
      if (!isValid(elem)) {
        return null;
      }
      if (typeof elem !== 'object') {
        return elem;
      }
      if (Array.isArray(elem)) {
        return elem.map((child) => cloneRecursively(`${idx}`, child)).filter(isValid);
      }
      return cloneElement(elem, {
        key: (prefix && `${prefix}-${idx}`) || idx,
      });
    })
    .filter((e) => !!e);
}

function isValid(elem) {
  return elem != null && typeof elem !== 'boolean';
}
