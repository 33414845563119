import Configuration from '../configuration';
import {zeus} from '../zeus';

export const LOAD_MARKER_CLUSTERS_REQUEST = 'LOAD_MARKER_CLUSTERS_REQUEST';
export const LOAD_MARKER_CLUSTERS_SUCCESS = 'LOAD_MARKER_CLUSTERS_SUCCESS';
export const LOAD_MARKER_CLUSTERS_FAILURE = 'LOAD_MARKER_CLUSTERS_FAILURE';

let requestCount = 0;

export function requestClusteredMapMarkersForQuery({query, bounds, zoomLevel}) {
  return async (dispatch, getState) => {
    const {clusteredMarkers: state} = getState();

    query = query.clone();
    query.set('bounding_area', Configuration.get('data.bounding_area'));

    if (
      state.bounds &&
      (state.bounds.equals(bounds) || state.bounds.contains(bounds)) &&
      state.query &&
      state.query.equals(query) &&
      state.zoomLevel &&
      state.zoomLevel == zoomLevel
    ) {
      return;
    }

    if (
      state.inflight &&
      state.inflight.bounds &&
      state.inflight.bounds.equals(bounds) &&
      state.inflight.query &&
      state.inflight.query.equals(query) &&
      state.inflight.zoomLevel &&
      state.inflight.zoomLevel === zoomLevel
    ) {
      return;
    }

    const id = ++requestCount;

    dispatch({
      type: LOAD_MARKER_CLUSTERS_REQUEST,
      id,
      query,
      bounds,
      zoomLevel,
    });

    let clusters;
    let limited;
    try {
      const resp = await zeus(`/api/map/marker-clusters`, {
        queryObj: {
          query: query.toGraphQL(),
          bounding_box: boundsToGraphQL(bounds),
          zoom: zoomLevel,
          includeGeometries: false, // For debugging
        },
      });
      clusters = resp.clusters;
      limited = !!resp.limited;
    } catch (err) {
      dispatch({
        type: LOAD_MARKER_CLUSTERS_FAILURE,
        id,
        err,
      });
      return;
    }
    dispatch({
      type: LOAD_MARKER_CLUSTERS_SUCCESS,
      id,
      bounds,
      query,
      clusters,
      limited,
      zoomLevel,
    });
  };
}

function boundsToGraphQL(b) {
  if (b) {
    return {
      min: {lat: b.getNorthWest().lat, lng: b.getNorthWest().lng},
      max: {lat: b.getSouthEast().lat, lng: b.getSouthEast().lng},
    };
  }
  return null;
}
