export function decapitalize(s) {
  if (s.length > 1 && isUpperCase(s.slice(0, 1)) && !isUpperCase(s.slice(1, 2))) {
    return s.slice(0, 1).toLowerCase() + s.slice(1);
  }
  return s;
}

function isUpperCase(s) {
  return s === s.toUpperCase();
}

// Format a number to a certain number of decimal places. If the argument
// is not a valid number, returns `null`.
export function formatNumberWithDecimals(v, decimalDigits = 2, trimZeros = false) {
  v = +v;
  if (isNaN(v)) {
    return null;
  } else {
    v = v.toFixed(decimalDigits);
    return trimZeros ? v.replace(/\.?0+$/, '') : v.replace(/\.0+$/, '');
  }
}
