import React from 'react';
import PropTypes from 'prop-types';

import styles from './IconLink.module.scss';

export class IconLink extends React.PureComponent {
  static propTypes = {
    icon: PropTypes.func,
    iconAfter: PropTypes.func,
    children: PropTypes.node,
    size: PropTypes.number,
    href: PropTypes.string.isRequired,
  };

  static defaultProps = {
    size: 30,
  };

  render() {
    const {icon: Icon, iconAfter: IconAfter, size, href} = this.props;
    return (
      <a href={href}>
        <span className={styles.root}>
          {Icon && (
            <span className={styles.ib}>
              <Icon size={size} />
            </span>
          )}
          {this.props.children && <span className={styles.l}>{this.props.children}</span>}
          {IconAfter && (
            <span className={styles.ia}>
              <IconAfter size={size} />
            </span>
          )}
        </span>
      </a>
    );
  }
}
