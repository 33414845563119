import {isEqual} from 'underscore';
import L from 'leaflet';

L.Polydraw = {};
L.Polydraw = L.Handler.extend({
  includes: L.Evented.prototype,

  options: {
    handleIcon: new L.DivIcon({
      html: '<span></span>',
      iconSize: new L.Point(25, 25),
      className: 'leaflet-polydraw-handle',
    }),
    shapeOptions: {
      className: 'polydraw_line',
      pointerEvents: 'none',
    },
    zIndex: 2000,
  },

  initialize(map, options) {
    L.Handler.prototype.initialize.call(this, map);

    this._container = map._container;
    this._vertices = [];
    this._handles = [];

    if (options && options.shapeOptions) {
      options.shapeOptions = L.Util.extend({}, this.options.shapeOptions, options.shapeOptions);
    }
    if (options && options.handleIcon) {
      options.handleIcon = L.Util.extend({}, this.options.handleIcon, options.handleIcon);
    }
    L.setOptions(this, options);
  },

  setOptions(options) {
    L.setOptions(this, options);
  },

  setLatLngs(latlngs) {
    latlngs = this._coerceLatLngs(latlngs);
    const current = this.getLatLngs();
    if (isEqual(current, latlngs)) {
      return;
    }

    this._internalClear();
    for (let i = 0; i < latlngs.length; i++) {
      this._vertices.push({latlng: latlngs[i]});
    }
    this._complete = true;
    this._recreateShape();
    this.fire('polydraw:changed', {polydraw: this});
  },

  isDragging() {
    return !!this._draggingHandle;
  },

  isComplete() {
    return this._complete;
  },

  _coerceLatLngs(latlngs) {
    if (!latlngs.toGeoJSON && Array.isArray(latlngs)) {
      latlngs = latlngs.map((ll) => new L.LatLng(ll[0], ll[1]));
    }
    return latlngs;
  },

  getLatLngs() {
    return this._vertices.filter((v) => !v.preliminary).map((v) => v.latlng);
  },

  getLatLngFloats() {
    return this.getLatLngs().map((latlng) => [latlng.lat, latlng.lng]);
  },

  addHooks() {
    this._extendLayer = new L.LayerGroup();
    this._map.addLayer(this._extendLayer);

    L.DomUtil.addClass(this._container, 'leaflet-crosshair');

    if (this._map.doubleClickZoom.enabled()) {
      this._doubleClickZoomDisabled = true;
      this._map.doubleClickZoom.disable();
    } else {
      this._doubleClickZoomDisabled = false;
    }

    L.DomEvent.on(this._map, 'mousedown', this._handleMouseDown, this);
    L.DomEvent.on(this._map, 'mousemove', this._handleMouseMove, this);
    L.DomEvent.on(this._map, 'mouseup', this._handleMouseUp, this);

    this._recreateShape();

    this.fire('polydraw:enabled', this);
  },

  removeHooks() {
    if (!this._map) {
      return;
    }

    this._map.removeLayer(this._extendLayer);
    this._extendLayer = null;
    this._extendPath = null;
    this._extendMarker = null;

    if (this._doubleClickZoomDisabled) {
      this._map.doubleClickZoom.enable();
      this._doubleClickZoomDisabled = false;
    }

    L.DomEvent.off(this._map, 'mousedown', this._handleMouseDown, this);
    L.DomEvent.off(this._map, 'mousemove', this._handleMouseMove, this);
    L.DomEvent.off(this._map, 'mouseup', this._handleMouseUp, this);

    L.DomUtil.removeClass(this._container, 'leaflet-crosshair');

    this._recreateShape();

    this.fire('polydraw:disabled', this);
  },

  clear() {
    this._internalClear();
    this._updateShape();
    this.fire('polydraw:changed', {polydraw: this});
  },

  _internalClear() {
    this._removeShape();
    if (this._extendMarker) {
      this._extendMarker.remove();
      this._extendMarker = null;
    }
    if (this._extendPath) {
      this._extendPath.remove();
      this._extendPath = null;
    }
    this._complete = false;
    this._vertices = [];
    this._handles = [];
  },

  _handleMouseMove(event) {
    if (!this.enabled() || this._complete) {
      if (this._extendMarker) {
        this._extendMarker.remove();
        this._extendMarker = null;
      }
      if (this._extendPath) {
        this._extendPath.remove();
        this._extendPath = null;
      }
      return;
    }

    if (!this._extendMarker) {
      const marker = new L.Marker(event.latlng, {
        zIndexOffset: this.options.zIndexOffset + 1,
        draggable: false,
        opacity: 0.5,
        icon: this.options.handleIcon,
      });
      this._extendLayer.addLayer(marker);
      this._extendMarker = marker;
    }
    this._extendMarker.setLatLng(event.latlng);

    if (this._extendPath) {
      this._extendPath.remove();
      this._extendPath = null;
    }
    if (this._vertices.length >= 1) {
      this._extendPath = L.polyline(
        [this._vertices[this._vertices.length - 1].latlng, event.latlng],
        this.options.shapeOptions
      );
      this._extendLayer.addLayer(this._extendPath);
    }
  },

  _handleMouseDown(event) {
    if (!this._complete) {
      const originalEvent = event.originalEvent;
      this._mouseDownOrigin = L.point(originalEvent.clientX, originalEvent.clientY);
    }
  },

  _handleMouseUp(event) {
    if (!this._mouseDownOrigin) {
      return;
    }

    // detect mouse up on an existing vertex
    for (let i = 0; i < this._vertices.length; ++i) {
      const vertex = this._vertices[i];
      const dist = this._map
        .latLngToLayerPoint(event.latlng)
        .distanceTo(this._map.latLngToLayerPoint(vertex.latlng));
      if (dist < 9 * (window.devicePixelRatio || 1)) {
        this._mouseDownOrigin = null;
        return;
      }
    }

    // We detect clicks within a certain tolerance, otherwise let it
    // be interpreted as a drag by the map
    const distance = L.point(event.originalEvent.clientX, event.originalEvent.clientY).distanceTo(
      this._mouseDownOrigin
    );
    if (Math.abs(distance) < 9 * (window.devicePixelRatio || 1)) {
      const latlng = event.latlng;
      this._vertices.push({latlng});
      this._updateShape();
      this.fire('polydraw:changed', {
        polydraw: this,
        edited: true,
      });
    }
    this._mouseDownOrigin = null;
  },

  _recreateShape() {
    this._removeShape();
    this._updateShape();
  },

  _removeShape() {
    if (this._shapeGroup) {
      this._map.removeLayer(this._shapeGroup);
      this._shapeGroup = null;
    }
    this._polygon = null;
    this._handles = [];
  },

  _updateShape() {
    const group = this._ensureShapeGroup();

    if (!this.enabled() || this._vertices.length === 0) {
      if (this._polygon) {
        group.removeLayer(this._polygon);
        this._polygon = null;
      }
      return;
    }

    const points = [];
    for (let i = 0; i < this._vertices.length; i++) {
      const vertex = this._vertices[i];
      const handle = this._handles[i];
      if (handle) {
        const marker = handle.marker;
        if (!this._draggingHandle && !marker.getLatLng().equals(vertex.latlng)) {
          marker.setLatLng(vertex.latlng);
        }
        marker.setOpacity(vertex.preliminary ? 0.5 : 1.0);
      } else {
        const marker = new L.Marker(vertex.latlng, {
          zIndexOffset: this.options.zIndexOffset + 1,
          draggable: this.enabled(),
          opacity: vertex.preliminary ? 0.5 : 1.0,
          icon: this.options.handleIcon,
        });
        marker
          .on('dragstart', this._onHandleMarkerDragStart, this)
          .on('drag', this._onHandleMarkerDrag, this)
          .on('dragend', this._onHandleMarkerDragEnd, this)
          .on('click', this._onHandleMarkerClick, this);

        group.addLayer(marker);

        const newHandle = {
          vertex,
          marker,
        };
        marker._polydraw_handle = newHandle;
        this._handles[i] = newHandle;
      }
      points.push(vertex.latlng);
    }
    if (this._complete) {
      points.push(this._vertices[0].latlng);
    }

    if (this._polygon) {
      this._polygon.setLatLngs(points);
    } else {
      this._polygon = L.polyline(points, this.options.shapeOptions);
      group.addLayer(this._polygon);
    }
  },

  _ensureShapeGroup() {
    const group = this._shapeGroup;
    if (group) {
      return group;
    }
    this._shapeGroup = new L.LayerGroup();
    this._map.addLayer(this._shapeGroup);
    return this._shapeGroup;
  },

  _removeVertexAt(index) {
    const handle = this._handles[index];
    if (handle) {
      if (this._shapeGroup) {
        this._shapeGroup.removeLayer(handle.marker);
        handle.marker = null;
      }

      this._vertices.splice(index, 1);
      this._handles.splice(index, 1);
    }
  },

  _removePreliminaryHandles() {
    for (let i = 0; i < this._vertices.length; ) {
      if (this._vertices[i].preliminary) {
        this._removeVertexAt(i);
      } else {
        i++;
      }
    }
  },

  _removePreliminaryHandlesAt(handle) {
    let index = this._handles.indexOf(handle);
    if (this._vertices[index].preliminary) {
      return;
    }

    const prevIndex = index > 0 ? index - 1 : this._handles.length - 1;
    const previousHandle = this._handles[prevIndex];
    if (previousHandle && previousHandle.vertex.preliminary) {
      this._removeVertexAt(prevIndex);
      if (prevIndex < index) {
        index--;
      }
    }

    const nextIndex = index < this._handles.length - 1 ? index + 1 : 0;
    if (prevIndex !== nextIndex) {
      const nextHandle = this._handles[nextIndex];
      if (nextHandle && nextHandle.vertex.preliminary) {
        this._removeVertexAt(nextIndex);
      }
    }

    this._updateShape();
  },

  _addPreliminaryHandlesAt(handle) {
    if (this._vertices.length <= 2) {
      return;
    }

    let index = this._handles.indexOf(handle);
    if (this._vertices[index].preliminary) {
      return;
    }

    const prevIndex = index > 0 ? index - 1 : this._handles.length - 1;
    const previousHandle = this._handles[prevIndex];
    if (previousHandle && !previousHandle.vertex.preliminary) {
      const halfwayPoint = this._calculateHalfwayPoint(
        handle.vertex.latlng,
        previousHandle.vertex.latlng
      );
      this._vertices.splice(prevIndex + 1, 0, {
        latlng: halfwayPoint,
        preliminary: true,
      });
      this._handles.splice(prevIndex + 1, 0, null);
      if (index !== 0) {
        index++;
      }
    }

    const nextIndex = index < this._handles.length - 1 ? index + 1 : 0;
    if (prevIndex !== nextIndex) {
      const nextHandle = this._handles[nextIndex];
      if (nextHandle && !nextHandle.vertex.preliminary) {
        const halfwayPoint = this._calculateHalfwayPoint(
          handle.vertex.latlng,
          nextHandle.vertex.latlng
        );
        this._vertices.splice(index + 1, 0, {
          latlng: halfwayPoint,
          preliminary: true,
        });
        this._handles.splice(index + 1, 0, null);
      }
    }

    this._updateShape();
  },

  _onHandleMarkerClick(event) {
    L.DomEvent.stopPropagation(event);

    if (this._lastDragEndEvent && Date.now() - this._lastDragEndEvent < 1000) {
      return;
    }

    const handle = event.target._polydraw_handle;
    if (
      !this._complete &&
      (handle === this._handles[0] || handle === this._handles[this._handles.length - 1])
    ) {
      this._complete = true;
      this._updateShape();
      this.fire('polydraw:changed', {
        polydraw: this,
        edited: true,
      });
    } else if (this._vertices.filter((v) => !v.preliminary).length > 3) {
      let index = this._handles.indexOf(handle);
      if (!this._vertices[index].preliminary) {
        this._removePreliminaryHandlesAt(handle);
        index = this._handles.indexOf(handle);
        if (index !== -1) {
          this._removeVertexAt(index);
          this._updateShape();
          this.fire('polydraw:changed', {
            polydraw: this,
            edited: true,
          });
        }
      }
    } else {
      this._addPreliminaryHandlesAt(handle);
    }
  },

  _onHandleMarkerDragStart(event) {
    //L.DomEvent.stopPropagation(event);

    const handle = event.target._polydraw_handle;
    if (!handle.vertex.preliminary) {
      this._removePreliminaryHandles();
    }
    handle.vertex.preliminary = false;
    this._draggingHandle = handle;
    this.fire('polydraw:dragStart', this);

    this._updateShape();
  },

  _onHandleMarkerDrag(event) {
    //L.DomEvent.stopPropagation(event);

    const handle = event.target._polydraw_handle;
    if (handle === this._draggingHandle) {
      handle.vertex.latlng = event.target.getLatLng();
      this._updateShape();
    }
  },

  _onHandleMarkerDragEnd(event) {
    // L.DomEvent.stopPropagation(event);

    this._lastDragEndEvent = Date.now();

    const handle = event.target._polydraw_handle;
    if (handle !== this._draggingHandle) {
      return;
    }

    this._draggingHandle = null;
    this._updateShape();
    this._removePreliminaryHandles();
    this._addPreliminaryHandlesAt(handle);
    this.fire('polydraw:dragEnd', this);
    this.fire('polydraw:changed', {
      polydraw: this,
      edited: true,
    });
  },

  _calculateHalfwayPoint(a, b) {
    // Taken from leaflet-draw.js, with thanks!
    const map = this._map;
    const p1 = map.project(a);
    const p2 = map.project(b);
    return map.unproject(p1._add(p2)._divideBy(2));
  },
});
