import React, {Component} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import {isEqual} from 'underscore';

export class Floater extends Component {
  constructor(props) {
    super(props);

    this._handleWindowResize = this._handleWindowResize.bind(this);

    this.state = {
      style: {},
    };
  }

  componentDidMount() {
    $(window).on('resize', this._handleWindowResize);
    this._updatePosition();
  }

  componentWillUnmount() {
    $(window).off('resize', this._handleWindowResize);
  }

  componentDidUpdate(/* prevProps, prevState */) {
    this._updatePosition();
  }

  render() {
    return (
      <div
        className={this.props.className}
        ref={(node) => (this._node = node)}
        style={this.state.style}>
        {this.props.children}
      </div>
    );
  }

  _updatePosition() {
    const $floater = $(this._node);
    if ($floater.length === 0) {
      return;
    }

    const width = $floater.outerWidth();
    const height = $floater.outerHeight();

    if (!(width || height)) {
      setTimeout(() => {
        if ($floater.outerWidth() || $floater.outerHeight()) {
          this._updatePosition();
        }
      }, 0);
      return;
    }

    const $parent = $(this._findParentElement());
    if ($parent.length === 0) {
      return;
    }

    const parentWidth = $parent.outerWidth();
    const parentHeight = $parent.outerHeight();

    const [offsetX, offsetY] = this.props.offset;

    const style = {
      position: 'absolute',
      zIndex: this.props.zIndex,
    };

    const placement = this.props.placement;
    if (placement.indexOf('bottom') !== -1) {
      style.top = $parent.offset().top + parentHeight;
    } else if (placement.indexOf('top') !== -1) {
      style.top = $parent.offset().top;
    } else if (placement.indexOf('middle') !== -1) {
      style.top = $parent.offset().top + parentHeight / 2 - height / 2;
    }
    style.top += offsetY;

    if (placement.indexOf('left') !== -1) {
      style.left = $parent.offset().left;
    } else if (placement.indexOf('right') !== -1) {
      style.left = $parent.offset().left + parentWidth - width;
    } else if (placement.indexOf('leftOf') !== -1) {
      style.left = $parent.offset().left - width;
    } else if (placement.indexOf('rightOf') !== -1) {
      style.left = $parent.offset().left + parentWidth;
    } else if (placement.indexOf('center') !== -1) {
      style.left = $parent.offset().left + parentWidth / 2 - width / 2;
    }
    style.left += offsetX;

    if (this.props.keepWithinViewport) {
      if (style.left < 0) {
        style.left = 0;
      } else if (style.left + width > $(window).outerWidth()) {
        style.left = $(window).outerWidth() - width;
      }
    }

    if (this.props.useParentWidth) {
      style.minWidth = parentWidth;
    }

    if (!isEqual(style, this.state.style)) {
      this.setState({style});
    }
  }

  _findParentElement() {
    if (this.props.parent) {
      if (typeof this.props.parent === 'function') {
        return this.props.parent();
      }
      return this.props.parent;
    } else if (this._node) {
      return this._node.parentNode;
    }
  }

  _handleWindowResize(/* event */) {
    this._updatePosition();
  }
}

Floater.propTypes = {
  // Optional class name.
  className: PropTypes.string,

  // Floater contents.
  children: PropTypes.node,

  // Array of [x, y].
  offset: PropTypes.arrayOf(PropTypes.number),

  // Optional z-index.
  zIndex: PropTypes.number,

  // Placement, an array of [horizontal, vertical].
  placement: PropTypes.arrayOf(PropTypes.string),

  // Parent element relative to which the floater should be positioned.
  // If a function, must be a function that returns an element.
  parent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),

  // If true, use parent width.
  useParentWidth: PropTypes.bool,

  // If true, never allow the floater to go outside viewport.
  keepWithinViewport: PropTypes.bool,
};

Floater.defaultProps = {
  offset: [0, 0],
  placement: ['bottom', 'left'],
  keepWithinViewport: true,
  useParentWidth: true,
  className: null,
};
