import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import {getOffsetLeft} from '../../../../lib/node_utils';
import Ticks from './Ticks';
import Counter from './Counter';
import Arrow from './Arrow';

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  ((tick) => {
    window.setTimeout(() => tick(Date.now()), 1000 / 30);
  });

export default class Carousel extends React.Component {
  static displayName = 'Carousel';

  static propTypes = {
    count: PropTypes.number.isRequired,
    cellRenderer: PropTypes.func.isRequired,
    cellWidth: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    spacing: PropTypes.number.isRequired,
    showArrows: PropTypes.bool.isRequired,
    showTicks: PropTypes.bool.isRequired,
    windowDimensions: PropTypes.object,
  };

  static defaultProps = {
    showArrows: true,
    showTicks: true,
    spacing: 0,
  };

  state = {position: 0};
  _snapSpeed = 10;

  componentDidMount() {
    this._mounted = true;
    document.body.addEventListener('touchend', this._handleDocumentMouseUp);
    document.body.addEventListener('mouseup', this._handleDocumentMouseUp);
    document.addEventListener('keydown', this._handleDocumentKeyDown);
    requestAnimationFrame(this._animationTick);
  }

  componentWillUnmount() {
    this._mounted = false;
    document.body.removeEventListener('touchend', this._handleDocumentMouseUp);
    document.body.removeEventListener('mouseup', this._handleDocumentMouseUp);
    document.removeEventListener('keydown', this._handleDocumentKeyDown);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.cellWidth > 0 &&
      (nextProps.width !== this.props.width || nextProps.cellWidth !== this.props.cellWidth)
    ) {
      this.setState({
        position: this._snappedPosition(this.state.position),
        targetPosition: null,
      });
    }
  }

  render() {
    const {count, width, height, cellWidth, spacing, cellRenderer} = this.props;
    if (count === 0 || width <= 0 || cellWidth <= 0) {
      // Avoid returning null here so that a parent element won't be empty
      return <div />;
    }

    const {position} = this.state;
    const x = position - (width - cellWidth) / 2;
    const translate = Math.floor(-mod(x, cellWidth));
    const startIndex = mod(Math.floor(x / cellWidth), count);
    const currentIndex = this._indexFromPosition(position);
    const arrowSize = width >= 800 && height >= 400 && cellWidth >= 600 ? 'big' : 'small';

    return (
      <div
        className="Carousel"
        style={{height}}
        data-dragging={this.state.dragging || this.state.animating}
        onClick={this._handleClick}
        onMouseDown={this._handleMouseDown}
        onMouseMove={this._handleMouseMove}
        onTouchStart={this._handleTouchStart}
        onTouchMove={this._handleTouchMove}>
        <div
          className="Carousel_viewport"
          style={{
            transform: `translate3d(${translate}px, 0, 0)`,
            msTransform: `translate(${translate}px, 0)`,
            MozTransform: `translate3d(${translate}px, 0, 0)`,
            WebkitTransform: `translate3d(${translate}px, 0, 0)`,
          }}>
          {mapViewportWindow(
            count,
            startIndex,
            Math.ceil(width / cellWidth) + 1,
            (idx, index, key) => (
              <div
                key={key}
                data-current={index === currentIndex}
                style={{
                  width: cellWidth - spacing,
                  height,
                  left: idx * cellWidth,
                  top: 0,
                  position: 'absolute',
                  overflow: 'hidden',
                }}>
                {cellRenderer(index, index === currentIndex)}
              </div>
            )
          )}
        </div>
        {this.props.showArrows && count > 1 && (
          <div className="Carousel_arrowPrev">
            <Arrow
              direction="left"
              size={arrowSize}
              onClick={() => this._snapToIndex(this._indexFromPosition(position) - 1)}
            />
          </div>
        )}
        {this.props.showArrows && count > 1 && (
          <div className="Carousel_arrowNext">
            <Arrow
              direction="right"
              size={arrowSize}
              onClick={() => this._snapToIndex(this._indexFromPosition(position) + 1)}
            />
          </div>
        )}
        {this.props.showTicks && count > 1 && count > 10 ? (
          <div className="Carousel_counter">
            <Counter count={count} current={currentIndex + 1} />
          </div>
        ) : (
          <div className="Carousel_ticks">
            <Ticks
              count={count}
              current={currentIndex + 1}
              onClick={(index) => this._snapToIndex(index)}
            />
          </div>
        )}
      </div>
    );
  }

  _snapToIndex = (index) => {
    // Use delta to move, to avoid wrapping the index (e.g. moving 1 -> 24 jumps right 24 frames)
    const delta = index - this._indexFromPosition(this.state.position);
    const newPosition = this._snappedPosition(this.state.position) + delta * this.props.cellWidth;
    if (newPosition !== this.state.targetPosition) {
      this.setState({
        targetPosition: newPosition,
        draggedDirection: 0,
      });
      this._startAnimation();
    }
  };

  _handleDocumentKeyDown = (event) => {
    if (!(event.metaKey || event.altKey || event.ctrlKey)) {
      if (event.keyCode === 37) {
        this._snapToIndex(this._indexFromPosition(this.state.position) - 1);
      } else if (event.keyCode === 39) {
        this._snapToIndex(this._indexFromPosition(this.state.position) + 1);
      }
    }
  };

  _positionFromIndex = (index) => {
    return index * this.props.cellWidth;
  };

  _indexFromPosition = (position) => {
    return mod(Math.round(position / this.props.cellWidth), this.props.count);
  };

  _snappedPosition = (position) => {
    return Math.round(position / this.props.cellWidth) * this.props.cellWidth;
  };

  _pageXToContainer = (x) => {
    return x - getOffsetLeft(ReactDOM.findDOMNode(this));
  };

  _animationTick = () => {
    if (this._mounted) {
      this._snap();
      if (this._animating) {
        requestAnimationFrame(this._animationTick);
      }
    }
  };

  _startAnimation = () => {
    if (!this._animating) {
      this._animating = true;
      requestAnimationFrame(this._animationTick);
    }
  };

  _stopAnimation = () => {
    this._animating = false;
  };

  _snap = () => {
    const {dragging, draggedDirection, position, targetPosition} = this.state;
    const {cellWidth} = this.props;
    if (dragging || cellWidth <= 0) {
      this._stopAnimation();
      return;
    }

    let wantPosition;
    if (targetPosition != null) {
      wantPosition = targetPosition;
    } else if (draggedDirection < 0) {
      wantPosition = Math.floor(position / cellWidth) * cellWidth;
    } else if (draggedDirection > 0) {
      wantPosition = Math.ceil(position / cellWidth) * cellWidth;
    } else {
      wantPosition = Math.round(position / cellWidth) * cellWidth;
    }

    const delta = wantPosition - position;
    const newPosition = position + (Math.abs(delta) < 1 ? 0 : delta) / this._snapSpeed;
    if (newPosition === position) {
      this._stopAnimation();
      return;
    }

    this.setState({position: newPosition});
    this._startAnimation();
  };

  _handleClick = (event) => {
    if (!this._canMove) {
      return;
    }
    const index = this._indexFromPosition(this.state.position);
    this._snapToIndex(
      index + (this._pageXToContainer(event.pageX) < this.props.width / 2 ? -1 : 1)
    );
  };

  _handleMouseDown = (event) => {
    this._beginDrag(event.pageX);
  };

  _handleMouseMove = (event) => {
    this._continueDrag(event.pageX);
  };

  _handleDocumentMouseUp = (/* event */) => {
    if (this.state.dragging) {
      this.setState({
        dragging: false,
        draggedDirection: this.state.position - this.state.dragStartPosition,
        targetPosition: null,
      });
      this._snap();
    }
  };

  _handleTouchStart = (event) => {
    if (event.touches.length === 1) {
      this._beginDrag(event.touches.item(0).pageX);
    }
  };

  _handleTouchMove = (event) => {
    if (event.touches.length === 1) {
      this._continueDrag(event.touches.item(0).pageX);
    }
  };

  _beginDrag = (pageX) => {
    if (!this._canMove) {
      return;
    }
    this.setState({
      dragging: true,
      dragOffset: this._pageXToContainer(pageX) + this.state.position,
      dragStartPosition: this.state.position,
      targetPosition: null,
    });
  };

  _continueDrag = (pageX) => {
    if (this.state.dragging) {
      this.setState({
        position: -(this._pageXToContainer(pageX) - this.state.dragOffset),
        targetPosition: null,
      });
    }
  };

  get _canMove() {
    return this.props.count > 1;
  }
}

/**
 * Given a virtual array of length `count`, produce a window starting at
 * `startIndex` of `length` entries, by mapping each virtual index with a
 * function.
 *
 * For each index, the mapper is called with arguments
 * `(absoluteIndex, virtualIndex, key)`, where the absolute index is
 * relative to the window (i.e., starting at zero), and the virtual
 * index is the item index from the virtual array. The result of this
 * function are an array of mapper results.
 *
 * For example, let's say our virtual array are the cells A, B, C, D, E:
 *
 *        +-------+
 *  AAA BB|B CCC D|DD EEE
 *  AAA BB|B CCC D|DD EEE
 *  AAA BB|B CCC D|DD EEE
 *        +-------+
 *            ^
 *            +--- viewport
 *
 * We want to render B, C and D because they are all visible inside the
 * viewport. These correspond to indexes 1, 2, 3 in our virtual array of
 * cells. The starting index is 1, because B is the first visible cell.
 * So we call mapViewportWindow(5, 1, 3, <function>). The mapper function
 * will then receive three calls with arguments (0, 1), (1, 2), (2, 3).
 */
function mapViewportWindow(count, startIndex, length, mapper) {
  const slice = [];
  let j = 0;
  let i = startIndex;
  const usedKeys = {};
  while (slice.length < length) {
    let key = i;
    if (Object.prototype.hasOwnProperty.call(usedKeys, key)) {
      // We sometimes need to display the same photo several times
      key += `-${j}`;
    }
    slice.push(mapper(j, i, key));
    i = mod(i + 1, count);
    j++;
    usedKeys[key] = true;
  }
  return slice;
}

function mod(v, n) {
  return ((v % n) + n) % n;
}
