import React from 'react';
import PropTypes from 'prop-types';

export default class EmailLink extends React.Component {
  static propTypes = {
    onShow: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
  };

  state = {visible: false};

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.email !== this.props.email) {
      this.setState({visible: false});
    }
  }

  render() {
    const {email, onShow} = this.props;
    const {visible} = this.state;

    return (
      <a
        href={visible ? `mailto:${email}` : '#'}
        onClick={(event) => {
          if (!visible) {
            event.preventDefault();
            this.setState({visible: true});
            if (onShow) {
              onShow();
            }
          }
        }}>
        {visible ? email : 'Show email'}
      </a>
    );
  }
}
