import {zeroPad} from './formatting';

const weekDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Format a date verbosely (day, month, possibly year).
 */
export function formatVerboseDate(date) {
  const today = new Date();
  let result = `${weekDayNames[date.getDay()]}, ${monthNames[date.getMonth()]} ${date.getDate()}`;
  if (date.getFullYear() !== today.getFullYear()) {
    result += ` ${date.getFullYear()}`;
  }
  return result;
}

/**
 * Formats time as "3 pm" or "9:42 am".
 */
function formatAMPMTime(hours, minutes) {
  const m = minutes !== 0 ? `:${zeroPad(minutes, 2)}` : '';
  const h = hours % 12 === 0 ? 12 : hours % 12;
  if (hours < 12) {
    return `${h + m} am`;
  } else {
    return `${h + m} pm`;
  }
}

/**
 * Format a clock time approximately.
 */
export function formatInformalTime(date) {
  const hours = date.getHours() + Math.round(date.getMinutes() / 60.0);
  if (hours === 0) {
    return 'around midnight';
  } else {
    const minutes = date.getMinutes();
    return formatAMPMTime(hours, minutes);
  }
}
