import React from 'react';

import styles from './HouseCarousel.module.css';
import {substitute_undefined} from '../../../lib/utils/misc';

const HouseCarousel = ({listings}) => {
  return (
    <div className={styles.root}>
      {listings
        ? listings.map((listing) => {
            return (
              <a className={styles.house} key={listing.id} href={listing.url}>
                <div className={styles.detailsTop}>
                  <div>
                    {listing.boosted ? substitute_undefined(listing.agent?.name, '') : null}
                  </div>
                  <div>{substitute_undefined(listing.broker?.name, '')}</div>
                </div>
                <div className={styles.detailsBottom}>
                  <div>
                    <div>
                      {listing.city && listing.state
                        ? `${listing.city}, ${listing.state}`
                        : listing.city || listing.state || ''}
                    </div>
                  </div>
                  <div>
                    <div>
                      {substitute_undefined(listing.bedrooms, '-')} bd /{' '}
                      {substitute_undefined(listing.bathrooms, '-')} br
                    </div>
                  </div>
                </div>
                <img
                  className={styles.photo}
                  src={listing.photo_template
                    ?.replace('{offset}', '0')
                    ?.replace('{size}', 'medium')}
                />
              </a>
            );
          })
        : null}
    </div>
  );
};

export default HouseCarousel;
