import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class FilterBar extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    direction: PropTypes.oneOf(['horizontal', 'vertical']),
    minWidth: PropTypes.number,
  };

  static defaultProps = {
    direction: 'horizontal',
  };

  render() {
    const style = {};
    if (this.props.minWidth) {
      style.minWidth = this.props.minWidth;
    }
    return (
      <div className="FilterBar" data-direction={this.props.direction} style={style}>
        <div className="FilterBar_items">
          <ul>{this.props.children}</ul>
        </div>
      </div>
    );
  }
}

export class FilterBarItem extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    childLabels: PropTypes.arrayOf(PropTypes.string),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    itemClassName: PropTypes.string,
    className: PropTypes.string,
    direction: PropTypes.oneOf(['horizontal', 'vertical']),
  };

  render() {
    const {children, childLabels, label, direction, itemClassName} = this.props;

    const columns = direction === 'horizontal' && children ? children.length : 1;

    const childLabelsTags = childLabels && childLabels.map((l) => <label>{l}</label>);

    let labelTag = '';
    if (label) {
      labelTag = <label>{label}</label>;
    } else if (!childLabelsTags) {
      labelTag = <label>&nbsp;</label>;
    }

    return (
      <li className={itemClassName}>
        <div
          className={classNames(
            'FilterBarItem',
            {FilterBarItem_labelless: !this.props.label},
            this.props.className
          )}>
          {labelTag}
          {childLabelsTags && <Columns columns={columns}>{childLabelsTags}</Columns>}
          <Columns className="FilterBarItem_content" columns={columns}>
            {children}
          </Columns>
        </div>
      </li>
    );
  }
}

class Columns extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    columns: PropTypes.number.isRequired,
  };

  render() {
    const {children, columns} = this.props;
    let content;

    if (columns === 1) {
      content = children;
    } else {
      const columnSpacing = 4;
      const columnWidth = Math.floor((100 - (columns - 1) * columnSpacing) / columns);
      const lastColumnWidth = 100 - (columnWidth + columnSpacing) * (columns - 1);
      content = children.map((item, idx) => {
        const isFirst = idx === 0;
        const isLast = idx + 1 === columns;
        const style = {
          width: `${isLast ? lastColumnWidth : columnWidth}%`,
          float: isLast ? 'right' : 'left',
          marginLeft: isFirst ? 0 : `${columnSpacing}%`,
        };
        return <div style={style}>{item}</div>;
      });
      content.push(<div style={{clear: 'both'}} />);
    }

    return <div className={this.props.className}>{content}</div>;
  }
}
