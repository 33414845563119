import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import L from 'leaflet';
import {any, isEqual} from 'underscore';
import {debounce} from 'debounce';
import {withRouter} from 'react-router';

import currentLocation from '../../../images/current_location_17x17.png';
import '../../../lib/maps/polydraw';
import {Listing} from '../../../lib/models';
import {MapToolbar, MapToolbarButton, MapToolbarToggleButton} from '../MapToolbar';
import Sizer from '../common/Sizer';
import NumberOrRange from '../common/NumberOrRange';
import Configuration from '../../../lib/configuration';
import Platform from '../../../lib/platform';
import ClusterLayer from '../../../lib/maps/ClusterLayer';
import {
  requestClusteredMapMarkersForQuery,
  setListingSearchMapFocus,
  setListingSearchMapFocusById,
  updateListingSearchMap,
} from '../../../lib/actions';
import {satelliteTileLayer, streetsTileLayer} from '../../../lib/maps/leaflet';
import {urlForListing} from '../../../lib/support/routing';
import ListingQuery from '../../../lib/search/ListingQuery';

const MAX_ZOOM = 16;
const MAX_ZOOM_LEVEL_FOR_CLUSTERING = 15;
const DEFAULT_ZOOM = 10;

class ListingResultMap extends React.Component {
  static propTypes = {
    shape: PropTypes.array,
    focusedListing: PropTypes.instanceOf(Listing),
    latLng: PropTypes.shape({lat: PropTypes.number, lng: PropTypes.number}),
    bounds: PropTypes.instanceOf(L.LatLngBounds),
    isCurrentLocation: PropTypes.bool,
    onShapeChanged: PropTypes.func,
    onToggleMaximize: PropTypes.func,
    showToolbar: PropTypes.bool,
    showMaximize: PropTypes.bool,
    maximized: PropTypes.bool,
    height: PropTypes.number.isRequired,
    clusters: PropTypes.array,
    limited: PropTypes.bool,
    query: PropTypes.instanceOf(ListingQuery),
    listingSearchMapState: PropTypes.shape({
      center: PropTypes.object,
      zoom: PropTypes.number,
      maximized: PropTypes.bool,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    showToolbar: true,
    showMaximize: false,
    limited: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      shapeComplete: (this.props.shape && this.props.shape.length > 0) || false,
      drawing: !!this.props.shape,
      dragging: false,
      points: this.props.shape || [],
      shiftKey: false,
      maximized: this.props.maximized,
      hasMouse: false,
      interacting: false,
    };

    this._handleDocumentKeyUp = this._handleDocumentKeyUp.bind(this);
    this._handleDocumentKeyDown = this._handleDocumentKeyDown.bind(this);
  }

  componentDidMount() {
    this._mounted = true;

    document.addEventListener('keydown', this._handleDocumentKeyDown);
    document.addEventListener('keyup', this._handleDocumentKeyUp);
    window.addEventListener('resize', this._handleWindowResize);

    // FIXME: We delay loading to avoid bug in Leaflet where layers
    //   aren't initially displayed.
    setTimeout(this._wrapCallback(this._setup), 1);

    this._checkIfCompact();
  }

  componentWillUnmount() {
    this._mounted = false;

    document.removeEventListener('keydown', this._handleDocumentKeyDown);
    document.removeEventListener('keyup', this._handleDocumentKeyUp);
    window.removeEventListener('resize', this._handleWindowResize);

    this._clusterLayer = null;
    this._boundingPolygonLayer = null;
    this._locationMarker = null;

    if (this._leafletMap) {
      this._leafletMap.remove();
      this._leafletMap = null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {hasMouse} = this.state;
    let repositionTask;

    if (this._polydraw) {
      const {points} = this.state;
      if (
        !isEqual(prevState.points, points) ||
        prevState.shapeComplete !== this.state.shapeComplete
      ) {
        this._polydraw.setLatLngs(points);
        repositionTask = repositionTask || this._fitToShape;
        if (this.props.onShapeChanged) {
          this.props.onShapeChanged((this.state.shapeComplete && points) || null);
        }
      }

      if (prevState.drawing !== this.state.drawing) {
        if (this.state.drawing) {
          this._polydraw.enable();
          this.props.dispatch(setListingSearchMapFocus());
        } else {
          this._polydraw.clear();
          this._polydraw.disable();
        }
      }
    }

    if (
      this.props.latLng &&
      this.props.isCurrentLocation &&
      !(prevProps.latLng && prevProps.latLng.equals(this.props.latLng))
    ) {
      repositionTask = repositionTask || this._fitToCurrentLocation;
    }

    if (this.props.bounds && !(prevProps.bounds && prevProps.bounds.equals(this.props.bounds))) {
      repositionTask = repositionTask || this._fitToBounds;
    }

    if (!hasMouse && this.props.focusedListing) {
      // Shift key toggles zoom to focus
      if (this.state.shiftKey !== prevState.shiftKey) {
        repositionTask = repositionTask || this._repositionFromFocus;
      }

      // Reposition to focused listing
      if (
        this._canDisplayOverlays() &&
        prevProps.focusedListing &&
        this.props.focusedListing.id !== prevProps.focusedListing.id
      ) {
        repositionTask = repositionTask || this._repositionFromFocus;
      }

      // Drop map focus if we're hovering
      if (prevState.hasMouse && this.props.focusedListing) {
        this.props.dispatch(setListingSearchMapFocus());
      }
    }

    if (
      !isEqual(prevProps.latLng, this.props.latLng) ||
      prevProps.isCurrentLocation !== this.props.isCurrentLocation
    ) {
      this._updateLocationMarker();
    }

    if (this.props.onToggleMaximize && prevState.maximized !== this.state.maximized) {
      this.props.onToggleMaximize(this.state.maximized);
    }

    if (prevProps.height !== this.props.height) {
      this._invalidateMapSize();
    }

    if (repositionTask) {
      repositionTask();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.maximized !== this.props.maximized) {
      this.setState({maximized: nextProps.maximized});
    }

    if (!isEqual(nextProps.shape, this.props.shape)) {
      if (nextProps.shape) {
        this.setState({
          points: nextProps.shape || [],
          shapeComplete: nextProps.shape != null,
          drawing: true,
        });
      } else {
        this.setState({
          points: [],
          shapeComplete: false,
          drawing: false,
        });
      }
    }

    if (!nextProps.query.equals(this.props.query)) {
      this._requestMarkers(nextProps.query);
    }

    if (
      !isEqual(nextProps.clusters, this.props.clusters) ||
      nextProps.query.hasExactCriteria() !== this.props.query.hasExactCriteria()
    ) {
      this._updateClusterLayer(nextProps.clusters, nextProps.query.hasExactCriteria());
    }

    if (
      nextProps.listingSearchMapState.zoom == null &&
      !isEqual(nextProps.listingSearchMapState.zoom, this.props.listingSearchMapState.zoom)
    ) {
      this._fitToBoundingArea();
    }

    if (!isEqual(nextProps.listingSearchMapState.center, this.props.listingSearchMapState.center)) {
      this._repositionFromStore(nextProps.listingSearchMapState);
    }
  }

  _fitToBoundingArea() {
    const polygon = Configuration.get('data.bounding_area');
    if (polygon) {
      this._fit(L.latLngBounds(polygon.map((ll) => L.latLng(ll[1], ll[0]))));
    }
  }

  render() {
    let {height} = this.props;

    return (
      <div className="ListingResultMap" data-drawing={this.state.drawing}>
        <div className="ListingResultMap_drawtools">
          {this.props.showToolbar && (
            <MapToolbar>
              <MapToolbarToggleButton
                compact={this.state.compact}
                title={this.state.compact ? 'Draw' : 'Draw search area'}
                name="compose"
                active={this.state.drawing}
                onClick={this._handleDrawButtonClick}
              />
              <MapToolbarButton
                compact={this.state.compact}
                title="Clear"
                name="clear"
                onClick={this._handleClearButtonClick}
                enabled={this.state.points.length > 0}
              />
            </MapToolbar>
          )}
          {this.props.limited && (
            <div className="ListingResultMap_limited">
              <span>first 10k shown</span>
            </div>
          )}
        </div>

        {this.props.showMaximize && (
          <span className="ListingResultMap_maximize">
            <MapToolbar>
              <MapToolbarToggleButton
                title=""
                name="maximize"
                active={this.state.maximized}
                onClick={this._handleMaximizeButtonClick}
              />
            </MapToolbar>
          </span>
        )}

        <div
          className="ListingResultMap_content"
          onMouseOver={this._handleMouseOver}
          onMouseLeave={this._handleMouseLeave}>
          <Sizer
            onChange={this._invalidateMapSize}
            child={() => (
              <div
                className="ListingResultMap_content_leafletContainer"
                ref={(node) => (this._containerElement = node)}
                style={{height}}
              />
            )}
          />

          {this._renderShapeHelpOverlay()}

          {this._renderPopupOverlay()}

          {this.state.drawing && this._polydraw && this._polydraw.isComplete() && (
            <div className="ListingResultMap_content_drawHelp">
              {'Change search area by dragging the handles.'}
            </div>
          )}
        </div>
      </div>
    );
  }

  _renderShapeHelpOverlay() {
    if (
      !(
        this._leafletMap &&
        this.state.points.length > 0 &&
        this._polydraw &&
        !this._polydraw.isComplete()
      )
    ) {
      return null;
    }

    const point = this.state.points.length == 2 ? this.state.points[1] : this.state.points[0];

    const {x, y} = this._leafletMap.latLngToContainerPoint(point);

    const width = 120;

    return (
      <div
        className="ListingResultMapTooltip"
        style={{position: 'absolute', left: x - width / 2, top: y + 15, width}}>
        <p>
          {this.state.points.length == 1
            ? 'First point! Click on the map to add more.'
            : this.state.points.length == 2
            ? 'Second point! Add at least one more.'
            : 'Add more points, or complete your shape by clicking here.'}
        </p>
      </div>
    );
  }

  _renderPopupOverlay() {
    const map = this._leafletMap;
    if (!map || this.state.dragging) {
      return null;
    }

    const listing = this.props.focusedListing;
    if (!(listing && listing.getLatLng())) {
      return null;
    }
    const latLng = listing.getLatLng();

    // Outside of current map view
    if (!map.getBounds().contains(latLng)) {
      return null;
    }

    const {x, y} = map.latLngToContainerPoint(latLng);

    const width = 120;

    const address = listing.undisclosed_address ? 'Undisclosed Address' : listing.street_address;
    const price = listing.price || listing.rent;

    const canZoomWithShift =
      map.getZoom() !== MAX_ZOOM &&
      !(this.state.hasMouse || this.state.shiftKey || Platform.hasTouchEvents());

    return (
      <div
        className="ListingResultMapPopup"
        style={{zIndex: 500, position: 'absolute', left: x - width / 2, top: y + 15, width}}
        key={listing.id}
        onClick={() => this._handleListingPopupHide()}>
        <div className="ListingResultMapPopup_content">
          <div className="ListingResultMapPopup_content_description">
            <p>
              <strong>{address}</strong>
            </p>
            {price && (
              <p>
                <NumberOrRange value={price} currency="$" />
              </p>
            )}
          </div>
          {canZoomWithShift && (
            <div className="ListingResultMapPopup_content_zoom">
              <strong>Shift</strong>
              {' Zoom'}
            </div>
          )}
        </div>
      </div>
    );
  }

  _setup = () => {
    const config = {
      center: this.props.listingSearchMapState.center,
      zoom: this.props.listingSearchMapState.zoom || DEFAULT_ZOOM,
      zoomControl: true,
      maxZoom: MAX_ZOOM,
      minZoom: 4,
      markerZoomAnimation: true,
      keyboard: false,
      attributionControl: true,
    };

    this._leafletMap = L.map(this._containerElement, config)
      .on(
        'mousedown touchstart',
        this._wrapCallback(() => this.setState({interacting: true}))
      )
      .on(
        'movestart',
        this._wrapCallback(() => this._setMoving(true))
      )
      .on(
        'moveend',
        this._wrapCallback(() => this._setMoving(false))
      )
      .on('zoomend moveend viewreset', this._wrapCallback(this._handleMapRepositioned));

    streetsTileLayer().addTo(this._leafletMap);

    L.control
      .layers({Streets: streetsTileLayer(), Satellite: satelliteTileLayer()}, null, {
        position: 'bottomright',
      })
      .addTo(this._leafletMap);

    L.control.scale().addTo(this._leafletMap);

    if (Configuration.get('debug.show_map_bounding_area')) {
      const boundingPolygon = Configuration.get('data.bounding_area');
      if (boundingPolygon) {
        this._boundingPolygonLayer = L.geoJson({
          type: 'Polygon',
          coordinates: [boundingPolygon],
        }).addTo(this._leafletMap);
      }
    }

    this._polydraw = new L.Polydraw(this._leafletMap, {zIndexOffset: 4})
      .on('polydraw:enabled', () => this.setState({drawing: true}))
      .on('polydraw:disabled', () => this.setState({drawing: false}))
      .on('polydraw:dragStart', () => this.setState({dragging: true}))
      .on('polydraw:dragEnd', () => this.setState({dragging: false}))
      .on('polydraw:changed', this._handlePolydrawChanged);
    if (this.state.points.length) {
      this._polydraw.enable();
      this._polydraw.setLatLngs(this.state.points);
    }

    this._updateClusterLayer();
    this._requestMarkers();
    this._updateLocationMarker();
    this._initialPosition();
  };

  _initialPosition() {
    if (this.state.points && this.state.points.length) {
      this._fitToShape(this.state.points, {force: true});
    } else if (this.props.latLng && this.props.isCurrentLocation) {
      this._fitToCurrentLocation();
    } else if (this.props.bounds) {
      this._fitToBounds();
    } else if (this.props.listingSearchMapState.zoom == null) {
      this._fitToBoundingArea();
    } else {
      this._repositionFromStore();
    }
  }

  _canDisplayOverlays() {
    return !(this.state.drawing && this._polydraw && !this._polydraw.isComplete());
  }

  _handleClusterHover = (cluster) => {
    if (!this._canDisplayOverlays()) {
      return;
    }

    if (!this._isSingleCluster(cluster)) {
      this.props.dispatch(setListingSearchMapFocus(null));
      return;
    }

    const {id} = cluster.m[0];
    this.props.dispatch(setListingSearchMapFocusById(id));
    return;
  };

  _handleClusterClick = (cluster) => {
    if (this.state.drawing && this._polydraw && !this._polydraw.isComplete()) {
      return;
    }

    if (!this._canDisplayOverlays()) {
      return;
    }

    if (this._isSingleCluster(cluster)) {
      const {id} = cluster.m[0];
      const {focusedListing} = this.props;
      if ((focusedListing && focusedListing.id) === id) {
        this._clickedListing(focusedListing);
      } else {
        this.props.dispatch(setListingSearchMapFocusById(id));
      }
      return;
    }

    if (this._zoomCircle) {
      this._zoomCircle.remove();
    }

    const latLng = L.latLng(cluster.p);
    const radius = cluster.r;

    const circle = L.circle(latLng, radius, {
      className: `leaflet-cluster-zoom-radius ${
        cluster.exact > 0
          ? 'leaflet-cluster-zoom-radius-exact'
          : 'leaflet-cluster-zoom-radius-close'
      }`,
      pointerEvents: 'none',
    });
    this._leafletMap.addLayer(circle);
    this._zoomCircle = circle;
    setTimeout(() => {
      circle.remove();
    }, 1000);

    if (this._isSingleCluster(cluster)) {
      this._leafletMap.setView(latLng, MAX_ZOOM);
    } else {
      const zoom = this._leafletMap.getZoom();
      const bounds = circle.getBounds().pad(-0.02);
      this._leafletMap.fitBounds(bounds);
      if (this._leafletMap.getZoom() === zoom) {
        // If same zoom, zoom in further
        this._leafletMap.setZoom(zoom + 1);
        this._leafletMap.fitBounds(bounds);
      }
    }
  };

  _isSingleCluster(cluster) {
    return (
      this._leafletMap.getZoom() > MAX_ZOOM_LEVEL_FOR_CLUSTERING &&
      cluster.m &&
      cluster.m.length === 1
    );
  }

  _updateClusterLayer(clusters = this.props.clusters, hasExactCriteria = false) {
    if (!this._leafletMap) {
      return;
    }

    let layer = this._clusterLayer;
    if (!layer) {
      layer = new ClusterLayer(this._leafletMap, MAX_ZOOM_LEVEL_FOR_CLUSTERING)
        .on('click', this._handleClusterClick)
        .on('hover', this._handleClusterHover);
      layer.enable();
      this._clusterLayer = layer;
    }
    layer.setClusters(clusters, hasExactCriteria);
  }

  _fitToShape = (points = this.state.points, {force} = {force: false}) => {
    if (!(points && points.length && this._leafletMap)) {
      return;
    }
    const mapBounds = this._leafletMap.getBounds();
    if (force || any(points, (ll) => !mapBounds.contains(ll))) {
      this._fit(L.latLngBounds(points), {pad: false});
    }
  };

  _fitToCurrentLocation = (latLng = this.props.latLng) => {
    if (latLng && this._leafletMap) {
      this._leafletMap.setView(latLng, 11);
    }
  };

  _fitToBounds = (bounds = this.props.bounds) => {
    if (bounds) {
      this._fit(bounds);
    }
  };

  _fit(bounds, {pad} = {pad: true}) {
    if (bounds && this._leafletMap) {
      const options = {animate: false};
      if (pad) {
        options.padding = [25, 25];
      }
      this._leafletMap.fitBounds(bounds, options);
    }
  }

  _repositionFromStore = ({center, zoom} = this.props.listingSearchMapState) => {
    const map = this._leafletMap;
    if (!map) {
      return;
    }

    zoom = zoom || map.getZoom();
    if (center && zoom && !(zoom === map.getZoom() && center.equals(map.getCenter()))) {
      map.setView(center, zoom);
    }
  };

  _repositionFromFocus = () => {
    const map = this._leafletMap;
    if (!map) {
      return;
    }

    const listing = this.props.focusedListing;
    if (!listing) {
      return;
    }

    const latLng = listing.getLatLng();
    if (!latLng) {
      return;
    }

    if (this.state.shiftKey) {
      map.setView(latLng, MAX_ZOOM);
    } else if (this.props.listingSearchMapState.zoom) {
      map.setZoom(this.props.listingSearchMapState.zoom);
    }
  };

  _handleListingPopupHide() {
    this.props.dispatch(setListingSearchMapFocus());
  }

  _handleMouseOver = () => {
    this.setState({hasMouse: true});
  };

  _handleMouseLeave = () => {
    this.setState({hasMouse: false});
  };

  _handleMaximizeButtonClick = () => {
    this.setState((state) => ({
      maximized: !state.maximized,
    }));
  };

  _requestMarkers = debounce((query = this.props.query) => {
    const leafletMap = this._leafletMap;
    if (!leafletMap) {
      return;
    }
    this.props.dispatch(
      requestClusteredMapMarkersForQuery({
        query,
        zoomLevel: leafletMap.getZoom(),
        bounds: leafletMap.getBounds(),
      })
    );
  }, 500);

  _invalidateMapSize = () => {
    if (!this._leafletMap) {
      return;
    }
    try {
      this._leafletMap.invalidateSize(false);
    } catch (err) {
      // FIXME: We swallow the exception here, which is generally
      //   "Cannot read property 'x' of undefined" and evidently caused
      //   by a bug in Leaflet. Probably fixed in newer Leaflet.
    }
  };

  _setMoving(moving) {
    this.setState({moving});
  }

  _handleMapRepositioned = (event) => {
    if (this.state.moving) {
      return;
    }

    const map = this._leafletMap;
    if (!map) {
      return;
    }
    if (this._polydraw) {
      this._polydraw._recreateShape();
    }
    if (this.props.focusedListing) {
      // Forces layer to render
      this.forceUpdate();
    } else if (this.state.interacting || event.type === 'zoomend' || event.type === 'moveend') {
      this.props.dispatch(
        updateListingSearchMap({
          zoom: map.getZoom(),
          center: map.getCenter(),
        })
      );
    }

    this._requestMarkers();
  };

  _handleWindowResize = () => {
    this._repositionFromStore();
    this._checkIfCompact();
    if (this.state.targetListingId) {
      this.setState({targetListingId: null});
    }
  };

  _checkIfCompact() {
    if (this._containerElement) {
      this.setState({compact: this._containerElement.offsetWidth < 400});
    }
  }

  _handleDrawButtonClick = () => {
    this.setState((state) => ({
      drawing: !state.drawing,
      points: state.drawing ? state.points : [],
    }));
  };

  _handleClearButtonClick = (event) => {
    event.preventDefault();
    this.setState(() => ({drawing: false, points: []}));
  };

  _handlePolydrawChanged = (event) => {
    const points = this._polydraw.getLatLngFloats();
    this.setState({
      points,
      shapeComplete: this._polydraw.isComplete(),
    });
  };

  _handleDocumentKeyDown(event) {
    if (this._mounted && !this.state.hasMouse && isShiftKeyEvent(event)) {
      this.setState({shiftKey: true});
    }
  }

  _handleDocumentKeyUp(event) {
    if (this._mounted && !this.state.hasMouse && isShiftKeyEvent(event)) {
      this.setState({shiftKey: false});
    }
  }

  _clickedListing(listing) {
    window.location.href = urlForListing(listing, this.props.location.search);
  }

  _updateLocationMarker() {
    if (this._locationMarker) {
      this._locationMarker.remove();
      this._locationMarker = null;
    }
    if (!this._leafletMap) {
      return;
    }
    if (this.props.latLng && this.props.isCurrentLocation) {
      this._locationMarker = new L.marker([0, 0], {
        icon: new L.Icon({
          iconUrl: currentLocation,
          iconSize: [17, 17],
        }),
      });
      this._locationMarker.setLatLng(this.props.latLng).addTo(this._leafletMap);
      this._repositionFromStore();
    }
  }

  // Wraps a function in a check for whether the component is still mounted.
  _wrapCallback = (fn) => {
    return (...args) => {
      if (this._mounted) {
        fn(...args);
      }
    };
  };
}

function isShiftKeyEvent(event) {
  return event.keyCode === 16 && !(event.metaKey || event.altKey || event.ctrlKey);
}

export default connect((state) => ({
  clusters: state.clusteredMarkers.clusters || [],
  limited: !!state.clusteredMarkers.limited,
  listingSearchMapState: state.listingSearchMap,
  focusedListing: state.listingSearchMap.focus,
}))(withRouter(ListingResultMap));
