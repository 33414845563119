import crypto from 'crypto';
import {isEqual} from 'underscore';
import deepclone from 'deepclone';
import {Collection} from 'immutable';

// Simple superclass of model objects.
export default class Model {
  constructor(attributes = null) {
    if (attributes) {
      Object.assign(this, deepclone(attributes));
    }
  }

  // Clone this model.
  clone() {
    const attributes = {};
    for (const [key, value] of Object.entries(this)) {
      attributes[key] = deepclone(value);
    }
    return new this.__proto__.constructor(attributes);
  }

  // May be overridden by descendants.
  hashCode() {
    return hashObject(this);
  }

  // May be overridden by descendants.
  equals(other) {
    return this.isSame(other) && isEqual(this.asObject(), other.asObject());
  }

  // Returns true if this is the same as another model by identity.
  isSame(other) {
    return (
      other &&
      other.__proto__ === this.__proto__ &&
      ((this.id != null && other.id === this.id) || (this.uid != null && other.uid == this.uid))
    );
  }

  // Return plain object version of this model.
  asObject() {
    const result = {};
    for (const [key, value] of Object.entries(this)) {
      if (!canSerialize(value)) {
        continue;
      }
      result[key] = asObjectValue(value);
    }
    return result;
  }
}

function canSerialize(value) {
  return !(
    typeof value === 'function' ||
    value instanceof Model ||
    (value instanceof Collection && (value.isEmpty() || value.first() instanceof Model))
  );
}

function asObjectValue(value) {
  if (value instanceof Collection) {
    return value.toJS();
  }
  return value;
}

function hashObject(input) {
  const hash = crypto.createHash('md5');
  add(input, hash);
  return hash.digest('hex');

  function add(value, h) {
    if (value == null) {
      h.write('null');
      return;
    }
    if (Array.isArray(value)) {
      for (const v of value) {
        add(v, h);
      }
    }
    switch (typeof value) {
      case 'object':
        for (const k of Object.keys(value).sort()) {
          // Skip special keys like __proto__
          if (k.slice(0, 2) !== '__') {
            continue;
          }
          add(k, h);
          add(value[k], h);
        }
        break;
      case 'string':
        h.write(value);
        break;
      case 'number':
      case 'symbol':
        h.write(`${value}`);
        break;
      case 'boolean':
        h.write(value ? 't' : 'f');
        break;
      case 'undefined':
        h.write('U');
        break;
      case 'function':
        // Skip
        break;
      default:
        // Fallback, just in case
        h.write(JSON.stringify(value));
    }
  }
}
