import React from 'react';
import PropTypes from 'prop-types';
import {isNumber} from 'underscore';

import {formatNumberWithCommas} from '../../../lib/node_utils';
import {formatNumberWithDecimals} from '../../../lib/utils/text';
import {formatBedroomCount} from '../../../lib/support/search';

export default class NumberOrRange extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    range: PropTypes.object,
    unit: PropTypes.node,
    currency: PropTypes.string,
    type: PropTypes.string,
    prefix: PropTypes.string,
    placeholder: PropTypes.node,
    allowMissing: PropTypes.bool,
    rangeSeparator: PropTypes.node,
    trimZeros: PropTypes.bool,
  };

  static defaultProps = {
    allowMissing: false,
    rangeSeparator: '–',
  };

  render() {
    const {value, range, unit, rangeSeparator, prefix} = this.props;
    let isNegative = false;

    let formatter;
    if (this.props.type === 'bedrooms') {
      formatter = formatBedroomCount;
    } else if (value % 1) {
      formatter = (v) => formatNumberWithDecimals(v, 2, !!this.props.trimZeros);
    } else {
      formatter = (v) => formatNumberWithCommas(Math.round(v));
    }

    let content;
    if (range && range.min != null && range.max != null) {
      content = (
        <span>
          <span className="NumberOrRange_from">{formatter(range.min)}</span>
          <span className="NumberOrRange_sep">{rangeSeparator}</span>
          <span className="NumberOrRange_to">{formatter(range.max)}</span>
        </span>
      );
    } else if (value != null) {
      if (value.normalized_count != null) {
        isNegative = value.normalized_count < 0;
        content = formatter(Math.abs(value.normalized_count));
      } else if (isNumber(value)) {
        isNegative = value < 0;
        content = formatter(Math.abs(value));
      }
    }
    if (content) {
      return (
        <span className="NumberOrRange">
          {prefix && <span className="NumberOrRange_prefix">{prefix} </span>}
          {isNegative && '-'}
          {this.props.currency}
          {content}
          {unit && <span className="NumberOrRange_unit">{unit}</span>}
        </span>
      );
    }

    if (!this.props.allowMissing) {
      return null;
    }

    const {placeholder} = this.props;
    return (
      <span className="NumberOrRange">
        {placeholder ? (
          <span className="NumberOrRange_placeholder">{placeholder}</span>
        ) : (
          <span className="NumberOrRange_missing">Unknown</span>
        )}
      </span>
    );
  }
}
