import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'capitalize';

import {isNumberOrNumericRange} from '../../../lib/utils/numbers';
import NumberOrRange from '../common/NumberOrRange';
import {Table, TableRow, TableCell} from '../common/Table';

function any(list) {
  return Array.isArray(list) && list.length > 0;
}

export default class ListingInfoBox extends React.Component {
  static propTypes = {
    listing: PropTypes.object.isRequired,
  };

  render() {
    const {listing} = this.props;
    const amount = listing.price || listing.rent;
    const amountRange = listing.price_range || listing.rent_range;
    const {pets, bathrooms, county} = listing;
    const squareFootage = (listing.size || {}).square_footage;
    const squareFootageRange = (listing.size || {}).square_footage_range;
    const acreage = (listing.size || {}).acreage;
    const acreageRange = (listing.size || {}).acreage_range;
    const plats = (listing.size || {}).plats;
    const platsRange = (listing.size || {}).plats_range;
    const status = capitalize.words(listing.status || 'active').replace(/_/g, ' ');

    return (
      <div className="ListingInfoBox">
        {pets && (
          <div className="ListingInfoBox_item">
            <h3>{'Pet Policy'}</h3>
            <ul>
              <li>{pets.allowed ? 'Pets Allowed' : 'No Pets Allowed'}</li>
              {pets.deposit && (
                <li>
                  <strong>Deposit:</strong> {pets.deposit}
                </li>
              )}
              {pets.policy && <li>{pets.policy}</li>}
            </ul>
          </div>
        )}

        <div className="ListingInfoBox_item">
          <h3>{'At a Glance'}</h3>
          <Table
            rows={[
              listing.type === 'for_sale' && (
                <TableRow cells={[<TableCell>Type</TableCell>, <TableCell>Sale</TableCell>]} />
              ),

              listing.type === 'for_rent' && (
                <TableRow cells={[<TableCell>Type</TableCell>, <TableCell>Rent</TableCell>]} />
              ),

              listing.mls_number && (
                <TableRow
                  cells={[
                    <TableCell>{'MLS ID'}</TableCell>,
                    <TableCell>{listing.mls_number}</TableCell>,
                  ]}
                />
              ),

              <TableRow
                cells={[<TableCell>{'Status'}</TableCell>, <TableCell>{status}</TableCell>]}
              />,

              !listing.isOffMarket() &&
                (isNumberOrNumericRange(amount) || isNumberOrNumericRange(amountRange)) && (
                  <TableRow
                    cells={[
                      <TableCell>
                        {listing.type === 'for_sale'
                          ? listing.isSold()
                            ? 'Sales Price'
                            : 'Asking Price'
                          : 'Rent'}
                      </TableCell>,
                      <TableCell>
                        <NumberOrRange value={amount} range={amountRange} currency="$" />
                      </TableCell>,
                    ]}
                  />
                ),

              isNumberOrNumericRange(squareFootage) ||
              isNumberOrNumericRange(squareFootageRange) ? (
                <TableRow
                  cells={[
                    <TableCell>Size</TableCell>,
                    <TableCell>
                      <NumberOrRange
                        value={squareFootage}
                        range={squareFootageRange}
                        unit="ft²"
                        allowMissing
                      />
                    </TableCell>,
                  ]}
                />
              ) : null,

              isNumberOrNumericRange(acreage) || isNumberOrNumericRange(acreageRange) ? (
                <TableRow
                  cells={[
                    <TableCell>Acreage</TableCell>,
                    <TableCell>
                      <NumberOrRange value={acreage} range={acreageRange} allowMissing />
                    </TableCell>,
                  ]}
                />
              ) : null,

              (isNumberOrNumericRange(plats) || isNumberOrNumericRange(platsRange)) && (
                <TableRow
                  cells={[
                    <TableCell>Plats</TableCell>,
                    <TableCell>
                      <NumberOrRange value={listing.plats} range={listing.plats_range} />
                    </TableCell>,
                  ]}
                />
              ),

              listing.price != null &&
                listing.price.value != null &&
                squareFootage &&
                squareFootage.value > 0 && (
                  <TableRow
                    cells={[
                      <TableCell>{'Price / ft²'}</TableCell>,
                      <TableCell>
                        <NumberOrRange
                          value={Math.round(listing.price / squareFootage)}
                          currency="$"
                        />
                      </TableCell>,
                    ]}
                  />
                ),

              listing.property_type != null && (
                <TableRow
                  cells={[
                    <TableCell>Property Type</TableCell>,
                    <TableCell>{listing.getFormattedPropertyType()}</TableCell>,
                  ]}
                />
              ),

              any(listing.property_sub_types) && (
                <TableRow
                  cells={[
                    <TableCell>Property Subtype</TableCell>,
                    <TableCell>{listing.property_sub_types.join(', ')}</TableCell>,
                  ]}
                />
              ),

              any(listing.styles) && (
                <TableRow
                  cells={[
                    <TableCell>{'Style'}</TableCell>,
                    <TableCell>{listing.styles.join(', ')}</TableCell>,
                  ]}
                />
              ),

              // FIXME: Year should never be zero, fix upstream
              listing.year_built != null && listing.year_built != 0 ? (
                <TableRow
                  cells={[
                    <TableCell>{'Year built'}</TableCell>,
                    <TableCell>{listing.year_built}</TableCell>,
                  ]}
                />
              ) : null,

              isNumberOrNumericRange(listing.stories_count) && (
                <TableRow
                  cells={[
                    <TableCell>Stories</TableCell>,
                    <TableCell>{listing.stories_count}</TableCell>,
                  ]}
                />
              ),

              isNumberOrNumericRange(listing.rooms_count) && (
                <TableRow
                  cells={[
                    <TableCell>Rooms</TableCell>,
                    <TableCell>{listing.rooms_count}</TableCell>,
                  ]}
                />
              ),

              (isNumberOrNumericRange(listing.bedrooms) ||
                isNumberOrNumericRange(listing.bedrooms_range)) && (
                <TableRow
                  cells={[
                    <TableCell>Bedrooms</TableCell>,
                    <TableCell>
                      <NumberOrRange
                        value={listing.bedrooms}
                        range={listing.bedrooms_range}
                        type="bedrooms"
                        allowMissing
                      />
                    </TableCell>,
                  ]}
                />
              ),

              isNumberOrNumericRange(listing.bathrooms) && (
                <TableRow
                  cells={[
                    <TableCell>Bathrooms</TableCell>,
                    <TableCell>
                      <NumberOrRange value={listing.bathrooms} allowMissing />
                    </TableCell>,
                  ]}
                />
              ),

              bathrooms && bathrooms.full_count != null && (
                <TableRow
                  cells={[
                    <TableCell>Full Baths</TableCell>,
                    <TableCell>
                      <NumberOrRange value={bathrooms.full_count} allowMissing />
                    </TableCell>,
                  ]}
                />
              ),

              bathrooms && bathrooms.half_count != null && (
                <TableRow
                  cells={[
                    <TableCell>&frac12; Baths</TableCell>,
                    <TableCell>
                      <NumberOrRange value={bathrooms.half_count} allowMissing />
                    </TableCell>,
                  ]}
                />
              ),

              bathrooms && bathrooms.quarter_count != null && (
                <TableRow
                  cells={[
                    <TableCell>&frac14; Baths</TableCell>,
                    <TableCell>
                      <NumberOrRange value={bathrooms.quarter_count} allowMissing />
                    </TableCell>,
                  ]}
                />
              ),

              bathrooms && bathrooms.three_quarter_count != null && (
                <TableRow
                  cells={[
                    <TableCell>&frac34; Baths</TableCell>,
                    <TableCell>
                      <NumberOrRange value={bathrooms.three_quarter_count} allowMissing />
                    </TableCell>,
                  ]}
                />
              ),

              isNumberOrNumericRange(listing.fireplaces_count) && (
                <TableRow
                  cells={[
                    <TableCell>Fireplaces</TableCell>,
                    <TableCell>{listing.fireplaces_count}</TableCell>,
                  ]}
                />
              ),

              listing.subdivision && (
                <TableRow
                  cells={[
                    <TableCell>{'Subdivision'}</TableCell>,
                    <TableCell>{listing.subdivision}</TableCell>,
                  ]}
                />
              ),

              listing.nearest_schools && (
                <TableRow
                  cells={[
                    <TableCell>Schools</TableCell>,
                    <TableCell>
                      <ul>
                        {listing.nearest_schools.elementary && (
                          <li>{listing.nearest_schools.elementary.name}</li>
                        )}
                        {listing.nearest_schools.middle && (
                          <li>{listing.nearest_schools.middle.name}</li>
                        )}
                        {listing.nearest_schools.high && (
                          <li>{listing.nearest_schools.high.name}</li>
                        )}
                        {(listing.nearest_schools.uncategorized_list || []).map((s) => (
                          <li key={s}>{s}</li>
                        ))}
                      </ul>
                    </TableCell>,
                  ]}
                />
              ),

              listing.zip_code && (
                <TableRow
                  cells={[
                    <TableCell>{'Postal Code'}</TableCell>,
                    <TableCell>{listing.zip_code}</TableCell>,
                  ]}
                />
              ),

              county && (
                <TableRow
                  cells={[<TableCell>County</TableCell>, <TableCell>{county}</TableCell>]}
                />
              ),

              listing
                .getListingIds()
                .map(({label, value}, idx) => (
                  <TableRow
                    key={idx}
                    cells={[<TableCell>{label}</TableCell>, <TableCell>{value}</TableCell>]}
                  />
                )),
            ]}
          />
        </div>
      </div>
    );
  }
}
