import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import styles from './ListingGrid.module.scss';
import Sizer from '../common/Sizer';
import {Listing} from '../../../lib/models';
import {inGroupsOf} from '../../../lib/support/immutable';
import ListingTile from '../listing_tile';

export class ListingGrid extends React.Component {
  static propTypes = {
    listings: ImmutablePropTypes.iterableOf(PropTypes.instanceOf(Listing)),
  };

  static targetCellWidth = 350;
  static cellPadding = 20;

  render() {
    const {listings} = this.props;
    if (listings.isEmpty()) {
      return null;
    }

    return (
      <div className={styles.root}>
        <Sizer
          child={({width}) => {
            const cellsPerRow = Math.min(
              listings.size,
              Math.round(
                Math.max(1, width / (ListingGrid.targetCellWidth + ListingGrid.cellPadding / 2))
              )
            );
            const layout = 'portrait';
            const rows = inGroupsOf(listings, cellsPerRow, {pad: true});
            return (
              <div className={styles.rows}>
                {rows
                  .map((row, rowIdx) => (
                    <div className={styles.row} key={rowIdx}>
                      {row
                        .map((listing, listingIdx) => (
                          <div className={styles.cell} key={listingIdx}>
                            {listing && (
                              <ListingTile listing={listing} layout={layout} stickySearch />
                            )}
                          </div>
                        ))
                        .toJS()}
                    </div>
                  ))
                  .toJS()}
              </div>
            );
          }}
        />
      </div>
    );
  }
}
