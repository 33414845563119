// TODO: Deprecated and replaced by ReorderableList

import classNames from 'classnames';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {findDOMNode, unmountComponentAtNode} from 'react-dom';
import $ from 'jquery';
import {select, compact, find, clone, isEqual} from 'underscore';

import {Floater} from './Floater.react';
import {Overlay} from './Overlay.react';

const PriorityItem = function (label, name) {
  this.label = label;
  this.name = name;
};

class PriorityBarDragProxy extends Component {
  remove() {
    const parentNode = this.domNode.parentNode;
    if (parentNode) {
      unmountComponentAtNode(parentNode);
      $(parentNode).remove();
    }
  }

  render() {
    const style = {
      position: 'absolute',
      left: this.props.position[0],
      top: this.props.position[1],
      width: this.props.width,
      zIndex: this.props.zIndex,
    };
    return (
      <div className="PriorityBarDragProxy" style={style} ref={(node) => (this.domNode = node)}>
        <span className="PriorityBar_cell_rank">{this.props.rank}</span>
        <span className="PriorityBar_cell_label">
          {this.props.item ? this.props.item.label : null}
        </span>
      </div>
    );
  }
}

PriorityBarDragProxy.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
  width: PropTypes.number.isRequired,
  zIndex: PropTypes.number,
  rank: PropTypes.number,
  item: PropTypes.shape({
    label: PropTypes.string,
  }),
};

PriorityBarDragProxy.defaultProps = {
  position: [0, 0],
};

class PriorityBarCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
    };
    this._handleMouseUp = this._handleMouseUp.bind(this);
    this._handleMouseMove = this._handleMouseMove.bind(this);
    this._handleTouchMove = this._handleTouchMove.bind(this);
  }

  _renderLayer() {
    return (
      this.state.dragging && (
        <Overlay onShouldClose={() => this._cancelDragging()}>
          <Floater>
            <div className="PriorityBarDragProxyFloater">
              <PriorityBarDragProxy
                rank={this.props.rank}
                item={this.props.item}
                position={this.state.dragPosition}
                width={$(findDOMNode(this)).outerWidth()}
              />
            </div>
          </Floater>
        </Overlay>
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dragging !== this.state.dragging) {
      if (this.state.dragging) {
        $(document).on('mousemove', this._handleMouseMove);
        $(document).on('touchmove', this._handleTouchMove);
        $(document).on('mouseup touchend', this._handleMouseUp);
      } else {
        $(document).off('mouseup touchend', this._handleMouseUp);
        $(document).off('touchmove', this._handleTouchMove);
        $(document).off('mousemove', this._handleMouseMove);

        if (this.props.onRepositioned) {
          this.props.onRepositioned(this);
        }
      }
    }
  }

  componentWillUnmount() {
    $(document).off('mouseup touchend', this._handleMouseUp);
    $(document).off('touchmove', this._handleTouchMove);
    $(document).off('mousemove', this._handleMouseMove);
  }

  render() {
    return (
      <div
        className="PriorityBar_cell"
        data-name={this.props.item.name}
        data-dragging={this.state.dragging}
        onMouseDown={(event) => this._handleMouseDown(event)}
        onTouchStart={(event) => this._handleTouchStart(event)}
        ref={(node) => (this.domNode = node)}>
        <span className="PriorityBar_cell_rank">{this.props.rank}</span>
        <span className="PriorityBar_cell_label">{this.props.item.label}</span>
        {this._renderLayer()}
      </div>
    );
  }

  _updateDragPosition(event, dragOffset) {
    const dragPosition = [event.pageX - dragOffset[0], event.pageY - dragOffset[1]];
    this.setState({dragPosition});

    if (this.props.onRepositioning) {
      this.props.onRepositioning(this, this.props.item, [event.pageX, event.pageY]);
    }
  }

  _cancelDragging() {
    this.setState({dragging: false});
  }

  _handleTouchStart(event) {
    if (event.originalEvent) {
      event = event.originalEvent;
    }
    if (event.touches.length === 1) {
      event.preventDefault();
      const touch = event.touches.item(0);
      const offset = $(this.domNode).offset();
      const dragOffset = [touch.pageX - offset.left, touch.pageY - offset.top];
      this.setState({dragging: true, dragOffset});
      this._updateDragPosition(touch, dragOffset);
    }
  }

  _handleMouseDown(event) {
    event.preventDefault();

    const offset = $(this.domNode).offset();
    const dragOffset = [event.pageX - offset.left, event.pageY - offset.top];
    this.setState({dragging: true, dragOffset});
    this._updateDragPosition(event, dragOffset);
  }

  _handleMouseUp(/* event */) {
    this.setState({dragging: false});
  }

  _handleMouseMove(event) {
    this._updateDragPosition(event, this.state.dragOffset);
  }

  _handleTouchMove(event) {
    if (event.originalEvent) {
      event = event.originalEvent;
    }
    if (event.touches.length === 1) {
      this._updateDragPosition(event.touches.item(0), this.state.dragOffset);
    }
    event.preventDefault();
  }
}

PriorityBarCell.propTypes = {
  rank: PropTypes.number,
  item: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
  }),
  onRepositioning: PropTypes.func,
  onRepositioned: PropTypes.func,
};

class PriorityBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: this.props.items.map((item) => item.name),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      order: nextProps.items.map((item) => item.name),
    });
  }

  render() {
    return (
      <div
        className={classNames({
          PriorityBar: true,
          PriorityBar_horizontal: this.props.horizontal,
        })}>
        <ul ref={(node) => (this.ul = node)}>
          {this._getItemsOrdered().map((item, index) => (
            <li key={`child-${item.name}`}>
              <PriorityBarCell
                item={item}
                rank={index + 1}
                onRepositioned={() => this._handleRepositioned()}
                onRepositioning={(cell, i, position) =>
                  this._handleRepositioning(cell, i, position)
                }
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }

  _getItemsOrdered() {
    return compact(
      this.state.order.map((name) => find(this.props.items, (item) => item.name === name))
    );
  }

  _handleRepositioned() {
    if (this.props.onOrderChanged) {
      this.props.onOrderChanged(this._getItemsOrdered());
    }
  }

  _handleRepositioning(cell, item, position) {
    let itemIndex;
    for (let i = 0; i < this.props.items.length; i++) {
      if (item.name === this.props.items[i].name) {
        itemIndex = i;
        break;
      }
    }
    if (itemIndex >= 0) {
      const currentIndex = this.state.order.indexOf(item.name);
      if (currentIndex !== -1) {
        find(
          $(this.ul).find('.PriorityBar_cell'),
          function (element, i) {
            const $el = $(element);
            if (this._isInsideCellElement($el, position)) {
              if (this.state.order[i] !== item.name) {
                const order = clone(this.state.order);
                this._moveArrayElement(order, currentIndex, i);
                this.setState({order});
              }
              return true;
            }
          }.bind(this)
        );
      }
    }
  }

  _moveArrayElement(array, from, to) {
    if (to !== from) {
      const target = array[from];
      const increment = to < from ? -1 : 1;
      for (let k = from; k !== to; k += increment) {
        array[k] = array[k + increment];
      }
      array[to] = target;
    }
  }

  _isInsideCellElement($el, position) {
    const left = $el.offset().left;
    const top = $el.offset().top;
    const width = $el.outerWidth();
    const height = $el.outerHeight();
    return (
      position[0] >= left &&
      position[0] < left + width &&
      position[1] >= top &&
      position[1] < top + height
    );
  }
}

PriorityBar.propTypes = {
  horizontal: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onOrderChanged: PropTypes.func,
};

PriorityBar.defaultProps = {
  horizontal: false,
};

// FIXME: Total hack, move back into Homeland and make label rendering pluggable
const LABELS = {
  square_footage: 'Sq.ft.',
  bathrooms: 'Baths',
  bedrooms: 'Beds',
  has_photos: 'Photos',
  property_type: 'Type',
};

export class PriorityControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this._createItems(),
    };
  }

  componentDidMount() {
    this._changed();
  }

  componentDidUpdate(prevProps /*, prevState */) {
    if (
      !isEqual(prevProps.order, this.props.order) ||
      !isEqual(prevProps.orderables, this.props.orderables)
    ) {
      this._changed();
    }
    /*
    if (this.props.onChange &&
      !isEqual(prevState.items, this.state.items)) {
      this.props.onChange(this.state.items);
    }
    */
  }

  render() {
    return (
      <div className="PriorityControls">
        <PriorityBar
          items={this.state.items}
          horizontal={this.props.horizontal}
          onOrderChanged={(items) => this._handleOrderChanged(items)}
        />
      </div>
    );
  }

  _createItems() {
    const order = this.props.order;
    const orderables = this.props.orderables;
    return select(order, (key) => orderables.indexOf(key) !== -1).map(
      (key) => new PriorityItem(LABELS[key] || key, key)
    );
  }

  _changed() {
    this.setState({items: this._createItems()});
  }

  _handleOrderChanged(items) {
    this.setState({items});
    if (this.props.onChange) {
      this.props.onChange(items.map((item) => item.name));
    }
  }
}

PriorityControls.propTypes = {
  order: PropTypes.array.isRequired,
  orderables: PropTypes.array.isRequired,
  horizontal: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

PriorityControls.defaultProps = {
  horizontal: false,
};
