import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {IoIosArrowBack, IoIosArrowForward} from 'react-icons/io';
import L from 'leaflet';

import styles from './ListingResults.module.scss';
import {Panel, PanelSwitcher} from '../PanelSwitcher.react';
import Sizer from '../common/Sizer';
import ListingQuery, {ListingSearchResultSet} from '../../../lib/search/ListingQuery';
import ListingResultMap from './ListingResultMap';
import ListingResultList from './ListingResultList';
import Configuration from '../../../lib/configuration';
import {changeListingSearchMapSize, setListingSearchMapFocus} from '../../../lib/actions';
import {FixedSidebarSplitView} from '../common/FixedSidebarSplitView';
import {ExpandToWindowBottom} from '../common/ExpandToWindowBottom';
import {IconButton} from '../common/IconButton';
import {getOffsetTop} from '../../../lib/node_utils';

// Minimum pixel width before we can show a split map/result view.
const MIN_SPLITVIEW_WIDTH = 960;

// Map width.
const MAP_WIDTH = 400;

export const ListingResults = connect(({listingSearchMap}) => ({
  mapMaximized: listingSearchMap.maximized,
}))(
  class ListingResults extends React.Component {
    static propTypes = {
      showMap: PropTypes.bool.isRequired,
      onUpdateQuery: PropTypes.func.isRequired,
      mapMaximized: PropTypes.bool,
      query: PropTypes.instanceOf(ListingQuery).isRequired,
      inflight: PropTypes.bool,
      resultSet: PropTypes.instanceOf(ListingSearchResultSet),
      dispatch: PropTypes.func.isRequired,

      disableAds: PropTypes.bool,
      tilesPerRow: PropTypes.number,

      // Tile decorator function passed on to <ListingTile>.
      tileDecorator: PropTypes.func,

      // Props passed to <ListingTile> children.
      tileProps: PropTypes.object.isRequired,
    };

    static defaultProps = {
      showMap: true,
      tilesPerRow: 0,
      tileDecorator: (child) => child,
      tileProps: Object.freeze({}),
    };

    state = {
      panel: 'grid',
    };

    componentDidUpdate(prevProps, prevState) {
      if (prevState.panel !== this.state.panel && this.state.panel === 'map') {
        const rootElement = document.querySelector('*[data-is-scroll-root=true]');
        if (rootElement) {
          window.scrollTo(0, getOffsetTop(rootElement));
        }
      }
    }

    render() {
      const {query, resultSet, inflight, mapMaximized, tilesPerRow, disableAds} = this.props;

      const renderMap = (height, showMaximize = false) => {
        let bounds = query.get('bounds');
        const territory = query.get('territory');
        if (territory?.bounds && territory.bounds.northeast && territory.bounds.southwest) {
          const ne = territory.bounds.northeast;
          const sw = territory.bounds.southwest;
          bounds = L.latLngBounds(L.latLng(ne.lat, ne.lng), L.latLng(sw.lat, sw.lng));
        }
        return (
          <ListingResultMap
            height={height}
            query={query}
            shape={query.get('shape')}
            latLng={query.get('latlng')}
            bounds={bounds}
            isCurrentLocation={query.get('isCurrentLocation')}
            maximized={mapMaximized}
            onShapeChanged={this._handleShapeChanged}
            onToggleMaximize={this._handleMapToggleMaximize}
            showMaximize={showMaximize}
          />
        );
      };

      const renderResults = (width) => (
        <ListingResultList
          width={width}
          tileDecorator={this.props.tileDecorator}
          tileProps={this.props.tileProps}
          tilesPerRow={tilesPerRow}
          disableAds={disableAds}
          loading={inflight}
          query={query}
          resultSet={resultSet}
          showPagination={true}
          showMapHint={true}
          onListingHover={this._handleListingHover}
          onOrderChange={this._handleOrderChange}
        />
      );

      if (!this._mapDataAvailable() || !this.props.showMap) {
        return <Sizer child={({width}) => renderResults(width)} />;
      }
      if (mapMaximized) {
        return <ExpandToWindowBottom child={(height) => renderMap(height, true)} />;
      }
      return (
        <div className={styles.root}>
          <Sizer
            child={({width}) =>
              width < MIN_SPLITVIEW_WIDTH ? (
                <PanelSwitcher
                  current={this.state.panel}
                  panels={[
                    <Panel
                      name="grid"
                      content={
                        <div className={styles.panel}>
                          <div className={styles.top}>
                            <IconButton
                              iconAfter={IoIosArrowForward}
                              size={20}
                              onClick={() => this.setState(() => ({panel: 'map'}))}>
                              Show results on map
                            </IconButton>
                          </div>
                          {renderResults(width)}
                        </div>
                      }
                    />,
                    <Panel
                      name="map"
                      content={
                        <div className={styles.panel}>
                          <div className={styles.top}>
                            <IconButton
                              icon={IoIosArrowBack}
                              size={20}
                              onClick={() => this.setState(() => ({panel: 'grid'}))}>
                              Back to search results
                            </IconButton>
                          </div>
                          <ExpandToWindowBottom child={(height) => renderMap(height)} />
                        </div>
                      }
                    />,
                  ]}
                />
              ) : (
                <FixedSidebarSplitView
                  sidebar={({height}) => (
                    <div style={{width: MAP_WIDTH}}>{renderMap(height, true)}</div>
                  )}>
                  {renderResults(width - MAP_WIDTH)}
                </FixedSidebarSplitView>
              )
            }
          />
        </div>
      );
    }

    _mapDataAvailable = () => {
      const {query} = this.props;
      return !(
        Configuration.get('ui.search.require_location') === true &&
        query.get('agent_uid') == null &&
        query.get('latlng') == null &&
        query.get('shape') == null &&
        query.get('territory') == null &&
        query.get('addressQuery') == null
      );
    };
    _handleOrderChange = (order) => {
      this.props.onUpdateQuery((query) => query.setOrder(order));
    };

    _handleListingHover = (listing) => {
      this.props.dispatch(setListingSearchMapFocus(listing));
    };

    _handleTabChange = (panel) => {
      this.setState({panel});
    };

    _handleMapToggleMaximize = (maximize) => {
      this.props.dispatch(changeListingSearchMapSize(maximize));
    };

    _handleShapeChanged = (shape) => {
      if (shape) {
        this.props.onUpdateQuery((query) => {
          query.set({
            shape,
            latlng: null,
            addressQuery: null,
            isCurrentLocation: false,
          });
        });
      } else {
        this.props.onUpdateQuery((query) => {
          query.set({
            shape: null,
          });
        });
      }
    };
  }
);
