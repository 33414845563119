import React from 'react';
import PropTypes from 'prop-types';

import Platform from '../../../lib/platform';

export default class PhoneLink extends React.Component {
  static propTypes = {
    onShow: PropTypes.func.isRequired,
    number: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    extension: PropTypes.string,
  };

  state = {visible: false};

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.number !== this.props.number ||
      nextProps.type !== this.props.type ||
      nextProps.extension !== this.props.extension
    ) {
      this.setState({visible: false});
    }
  }

  render() {
    const {number, extension, onShow} = this.props;
    const {visible} = this.state;
    return (
      <span className="PhoneLink">
        <a
          href={visible ? `tel:${number}` : '#'}
          onClick={(event) => {
            if (visible) {
              if (Platform.dialPhone(number)) {
                event.preventDefault();
              }
            } else {
              event.preventDefault();
              this.setState({visible: true});
              if (onShow) {
                onShow();
              }
            }
          }}>
          {visible ? number : 'Show phone number'}
        </a>
        {visible && extension != null && (
          <span>
            <em>ext</em> {extension}
          </span>
        )}
      </span>
    );
  }
}
