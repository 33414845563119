import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import Page from '../common/Page';
import ErrorPage from '../ErrorPage';
import AgentMain from '../agents/AgentMain';
import {Agent, Listing} from '../../../lib/models';
import {List} from 'immutable';
import {analyticsEvent} from '../../../lib/actions';

const AgentPage = ({
  dispatch,
  listingAgent: agentData,
  listingBroker: brokerData,
  listings: listingsData,
}) => {
  const [agent] = useState(agentData ? new Agent(agentData) : null);
  const [listings] = useState(
    listingsData ? new List(listingsData).map((row) => new Listing(row)) : List()
  );
  useEffect(() => {
    dispatch(analyticsEvent('viewedAgent', {agent}));
  }, [dispatch, agent]);
  if (!agent) {
    return (
      <ErrorPage returnLink title="Agent not found" message="This agent may have been deleted." />
    );
  }
  const title = agent.name || '';
  return (
    <Page className="AgentPage" title={title} showBacklink>
      <AgentMain agent={agent} listings={listings} />
    </Page>
  );
};

export default connect()(AgentPage);
