import React from 'react';
import {urlForSearch} from '../../../lib/support/routing';

import styles from './Pagination.module.css';

const Pagination = ({query, loading, resultSet}) => {
  const maxPage = resultSet.maxPage || 1;
  const currentPage = Math.max(1, Math.min(maxPage, resultSet.page));
  const hasPrev = !loading && currentPage > 1;
  const hasNext = !loading && currentPage < maxPage;
  // *1* 2  3 ... 50
  //  1 *2* 3 ... 50
  //  1  2 *3* 4 ... 50
  //  1  2  3  *4* 5 ... 50
  //
  //  1 ... 4 *5* 6 ... 50
  //  1 ... 5 *6* 7 ... 50
  //
  //  1 ... 10 *11* 12 ... 50
  //  1 ... 11 *12* 13 ... 50
  //  1 ... 12 *13* 14 ... 50
  //
  //  1 ... 43 *44* 45 ...50
  //  1 ... 44 *45* 46 ...50
  //  1 ... 45 *46* 47 ...50
  //
  //  1 ... 46 *47* 48 49 50
  //  1 ... 47 *48* 49 50
  //  1 ... 48 *49* 50
  //  1 ... 48 49 *50*
  const hasStartGap = currentPage >= 5;
  const hasEndGap = currentPage <= maxPage - 4;
  let pages = [1];
  if (hasStartGap && hasEndGap) {
    pages.push(0);
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      pages.push(i);
    }
    pages.push(0);
    pages.push(maxPage);
  } else if (hasStartGap && !hasEndGap) {
    pages.push(0);
    for (let i = Math.min(maxPage - 2, currentPage - 1); i <= maxPage; i++) {
      pages.push(i);
    }
  } else if (hasEndGap && !hasStartGap) {
    for (let i = 2; i <= Math.max(3, currentPage + 1); i++) {
      pages.push(i);
    }
    pages.push(0);
    pages.push(maxPage);
  } else {
    for (let i = 2; i <= maxPage; i++) {
      pages.push(i);
    }
  }
  return (
    <div className={styles.root}>
      <div className={styles.controls}>
        {hasPrev ? (
          <a
            className={`${styles.page} ${styles.prev}`}
            href={urlForSearch(query.clone().setPage(currentPage - 1))}>
            <span className={styles.icon}>&lt;</span>
            <span className={styles.label}>Previous</span>
          </a>
        ) : (
          <span className={`${styles.page} ${styles.prev} ${styles.disabled}`}>
            <span className={styles.icon}>&lt;</span>
            <span className={styles.label}>Previous</span>
          </span>
        )}
        {pages.map((page, idx) =>
          page === 0 ? (
            <span key={`gap-${idx}`} className="gap">
              &#8943;
            </span>
          ) : page === currentPage ? (
            <span key={page} className={`${styles.page} ${styles.current}`}>
              {page}
            </span>
          ) : page === maxPage && hasEndGap ? (
            <span key={page} className={`${styles.page} ${styles.disabled}`}>
              {maxPage}
            </span>
          ) : (
            <a key={page} className={styles.page} href={urlForSearch(query.clone().setPage(page))}>
              {page}
            </a>
          )
        )}
        {hasNext ? (
          <a
            className={`${styles.page} ${styles.next}`}
            href={urlForSearch(query.clone().setPage(currentPage + 1))}>
            <span className={styles.label}>Next</span>
            <span className={styles.icon}>&gt;</span>
          </a>
        ) : (
          <span className={`${styles.page} ${styles.next} ${styles.disabled}`}>
            <span className={styles.label}>Next</span>
            <span className={styles.icon}>&gt;</span>
          </span>
        )}
      </div>
    </div>
  );
};
export default Pagination;
