import React from 'react';
import PropTypes from 'prop-types';

export default class Counter extends React.Component {
  static displayName = 'CarouselCounter';

  static propTypes = {
    count: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
  };

  render() {
    const {current, count} = this.props;
    return (
      <div className="CarouselCounter">
        <span className="CarouselCounter_index">{current}</span>
        <span className="CarouselCounter_of"> of </span>
        <span className="CarouselCounter_total">{count}</span>
      </div>
    );
  }
}
