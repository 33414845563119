import {isArray} from 'underscore';

// Simplified version of original custom query string handling that is now
// in legacyQueryString.js.
//
// The input values must already be string, boolean or number.
//
// This project includes the following node libraries that also handle query
// strings: qs, query-string, querystring, querystring-es3, querystringify
//
// It isn't clear why we rolled our own or which one to pick from this list.
export function serializeQueryString(attributes) {
  let result = '';
  if (attributes) {
    for (const [name, value] of Object.entries(attributes)) {
      const serialized = serializeValue(name, value);
      if (serialized === null) {
        continue;
      }
      result += `${result ? '&' : '?'}${encodeURIComponent(name)}=${encodeURIComponent(
        serialized
      )}`;
    }
  }
  return result;
}

function serializeValue(name, value) {
  if (value === null || typeof value === 'undefined') {
    return null;
  }
  switch (typeof value) {
    case 'number':
      return value.toString();
    case 'boolean':
      return value ? '1' : '0';
    case 'string':
      return value;
  }
  throw new Error(
    `Cannot serialize unsupported value name=${name} type=${typeof value}: ${JSON.stringify(value)}`
  );
}

// Deserialize the query params with last one wins semantics for values that
// are specified multiple times. Values without an "=" are skipped. All
// output values are simple strings, if you want a number or array you'll have
// to parse the data further.
//
// All output keys and values are strings.
export function deserializeQueryString(input) {
  let parsed = {};
  const qs = input?.startsWith('?') ? input.substring(1) : input;
  if (qs) {
    for (const token of qs.split('&')) {
      const parts = token.split('=', 2);
      if (parts.length === 1) {
        // skip query params with no value, e.g: ?foo
        continue;
      }
      const name = parts[0];
      if (name.length === 0) {
        // skip nameless params, e.g.: ?=1
        continue;
      }
      const value = parts[1];
      // last one wins semantics
      parsed[decodeURIComponent(name)] = decodeURIComponent(value);
    }
  }
  return parsed;
}

// Arrays are serialized as a single value separated by "!". Carefully chosen
// escape values are used for "!" and "~" just in case our arrays contain
// either, it's doubtful that they do. Additionally "!" and "~" were chosen
// as neither needs to be encoded when part of a URL.
export function serializeStringArray(value) {
  if (value === null || typeof value === 'undefined') {
    return null;
  }
  if (!isArray(value)) {
    throw new Error(`expected an array, got (type ${typeof value}): ${JSON.stringify(value)}`);
  }
  if (value.length === 0) {
    return null;
  }
  return value
    .map((v) => {
      const type = typeof v;
      if (type !== 'string') {
        throw Error(`Cannot serialize non-string array value (type ${type}): ${JSON.stringify(v)}`);
      }
      return v.replaceAll('~', '~T').replaceAll('!', '~B');
    })
    .join('!');
}

export function serializeNumberArray(value) {
  if (value === null || typeof value === 'undefined') {
    return null;
  }
  if (!isArray(value)) {
    throw new Error(`expected an array, got (type ${typeof value}): ${JSON.stringify(value)}`);
  }
  if (value.length === 0) {
    return null;
  }
  return value
    .map((v) => {
      const type = typeof v;
      if (type !== 'number') {
        throw Error(`Cannot serialize non-number array value (type ${type}): ${JSON.stringify(v)}`);
      }
      return v.toString();
    })
    .join('!');
}

// Reverse of the string array encoding used by serializeStringOrNumberArray.
// Expects elements to be separated by "!" and has special logic for unescaping
// "!" and "~" should they have existed in the original data (expected not to
// be triggered).
export function deserializeStringArray(value) {
  if (!value) {
    return null;
  }
  return value.split('!').map((v) => v.replaceAll('~B', '!').replaceAll('~T', '~'));
}

export function deserializeNumberArray(value) {
  return deserializeStringArray(value)?.map(parseFloat);
}
