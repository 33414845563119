import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {contains} from 'underscore';

import {analyticsEvent} from '../../../lib/actions';
import Platform from '../../../lib/platform';

function ListingDirectionsLink({listing, className, namespace, dispatch}) {
  if (listing.undisclosed_address || (!listing.street_address && !listing.property_name)) {
    return null;
  }
  const agent = listing.agent;
  const broker = listing.broker;
  const address = `${
    listing.street_address || listing.property_name
  } ${listing.formatCityStateZip()}`;
  const hasLatLng = !!(listing.geocode?.lat && listing.geocode?.lng);
  const isNewConstruction =
    contains(listing.features, 'type.new_construction') ||
    listing.sale_type === 'new_home' ||
    listing.property_type === 'new_construction';
  const url =
    hasLatLng && isNewConstruction
      ? `https://www.google.com/maps/dir/?api=1&destination=${listing.geocode.lat},${listing.geocode.lng}`
      : `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`;
  const classNameString = 'undefined' !== typeof className ? `${className} ` : '';
  const classNameAndNamespace = classNameString + namespace;
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(analyticsEvent('clickedListingDirections', {listing, agent, broker}));
        Platform.open(url, '_blank');
      }}
      className={classNameAndNamespace}
      title="Get driving directions">
      <span className={`${namespace}_icon`} />
      <span className={`${namespace}_text`}>
        <span className={`${namespace}_longtext`}>Get </span>Directions
      </span>
    </button>
  );
}

ListingDirectionsLink.propTypes = {
  dispatch: PropTypes.func.isRequired,
  listing: PropTypes.object.isRequired,
  className: PropTypes.string,
  namespace: PropTypes.string,
};

export default connect()(ListingDirectionsLink);
