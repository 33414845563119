import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from 'classnames';

export default connect()(
  class Button extends React.Component {
    static propTypes = {
      kind: PropTypes.oneOf(['submit', 'secondary', 'destructive', 'flat', 'simple', 'dark'])
        .isRequired,
      enabled: PropTypes.bool.isRequired,
      size: PropTypes.oneOf(['small', 'big']),
      title: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.node.isRequired,
      className: PropTypes.string,
      active: PropTypes.bool,
      onClick: PropTypes.func,
      dispatch: PropTypes.func.isRequired,
    };

    static defaultProps = {
      enabled: true,
      kind: 'simple',
      size: 'small',
      title: null,
      label: null,
      active: false,
      className: null,
    };

    render() {
      return (
        <a
          className={classNames('Button', this.props.className)}
          href={this.props.href}
          target="_blank"
          data-kind={this.props.kind}
          data-active={this.props.active}
          data-size={this.props.size}
          data-enabled={this.props.enabled}
          disabled={!this.props.enabled}
          title={this.props.title || this.props.label}
          onTouchStart={this._handleClick}
          onClick={this._handleClick}>
          {this.props.label}
        </a>
      );
    }

    _handleClick = (event) => {
      if (!this.props.enabled) {
        event.preventDefault();
        return;
      }
      if (this.props.onClick) {
        this.props.onClick(event);
        event.preventDefault();
        return;
      }
      if (this.props.href) {
        window.location.href = this.props.href;
        event.preventDefault();
      }
    };
  }
);
