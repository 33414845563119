import {List} from 'immutable';
import {clone} from 'underscore';

export function inGroupsOf(array, number, options) {
  if (typeof array.toJS === 'function') {
    return List(inGroupsOf(array.toJS(), number, options));
  }

  const whole = options && options.whole;
  const pad = options && options.pad;

  let result = [];
  if (number < 1 || array.length === 0 || (whole && number > array.length)) {
    result = [];
  } else if (number >= array.length) {
    result.push(array);
  } else {
    let index = -number;
    const slices = [];
    while ((index += number) < array.length) {
      const s = array.slice(index, index + number);
      if (!whole || s.length === number) {
        while (s.length < number) {
          s.push(null);
        }
        slices.push(s);
      }
    }
    result = slices;
  }

  if (pad && result.length > 0) {
    result[result.length - 1] = clone(result[result.length - 1]);
    while (result[result.length - 1].length < number) {
      result[result.length - 1].push(null);
    }
  }
  return result;
}
