/**
 * Function call which can be scheduled for later execution. The function
 * is debounced during the delay. Repeating is also supported.
 */
export class ScheduledFunction {
  constructor(fn) {
    this._fn = fn;
  }

  /**
   * Stop function, if scheduled.
   */
  stop() {
    let stopped = false;
    if (this._intervalId) {
      window.clearInterval(this._intervalId);
      this._intervalId = null;
      stopped = true;
    }
    if (this._timeoutId) {
      window.clearTimeout(this._timeoutId);
      this._timeoutId = null;
      stopped = true;
    }
    return stopped;
  }

  /**
   * Schedules function at regular intervals. Function should
   * return false to stop the interval. Does nothing if already scheduled.
   */
  scheduleEvery(interval, ...args) {
    if (this._intervalId) {
      return this;
    }
    this._intervalId = window.setInterval(() => {
      if (this._fn(...args) === false) {
        this.stop();
      }
    }, interval);
    return this;
  }

  /**
   * Schedule the function to run after a delay. If already scheduled,
   * this will cancel the previous scheduling and then re-schedule.
   * Does not interfere with interval-based schedule.
   */
  schedule(delay, ...args) {
    if (this._timeoutId) {
      window.clearTimeout(this._timeoutId);
    }
    this._timeoutId = window.setTimeout(() => {
      this._timeoutId = null;
      this._fn(...args);
    }, delay);
    return this;
  }

  /**
   * Schedule the function to run after a delay. If already scheduled,
   * this call will be ignored. Does not interfere with interval-based
   * schedule.
   */
  debounce(delay, ...args) {
    if (!this._timeoutId) {
      this._timeoutId = window.setTimeout(() => {
        this._timeoutId = null;
        this._fn(...args);
      }, delay);
    }
    return this;
  }
}

ScheduledFunction.wrap = (fn) => {
  let schedule = fn.__scheduledFunction;
  if (!schedule) {
    schedule = fn.__scheduledFunction = new ScheduledFunction(fn);
  }
  return schedule;
};

ScheduledFunction.unwrap = (fn) => {
  const schedule = fn.__scheduledFunction;
  if (schedule) {
    fn.__scheduledFunction = null;
    schedule.stop();
  }
  return schedule;
};

export default ScheduledFunction;
