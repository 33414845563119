import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {compact} from 'underscore';

import Page from '../common/Page';
import ErrorPage from '../ErrorPage';
import BrokerMain from '../BrokerMain';
import {Broker, Listing} from '../../../lib/models';
import {List} from 'immutable';
import {analyticsEvent} from '../../../lib/actions';

const BrokerPage = ({dispatch, listingBroker: brokerData, listings: listingsData}) => {
  const [broker] = useState(brokerData ? new Broker(brokerData) : null);
  const [listings] = useState(
    listingsData ? new List(listingsData).map((row) => new Listing(row)) : List()
  );
  useEffect(() => {
    dispatch(analyticsEvent('viewedBroker', {broker}));
  }, [dispatch, broker]);
  if (!broker) {
    return (
      <ErrorPage returnLink title="Broker not found" message="This broker may have been deleted." />
    );
  }
  const title = compact([broker.name, broker && broker.name]).join(', ');
  return (
    <Page className="BrokerPage" title={title} showBacklink>
      <BrokerMain broker={broker} listings={listings} />
    </Page>
  );
};

export default connect()(BrokerPage);
