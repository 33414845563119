import React from 'react';
import PropTypes from 'prop-types';

import {DropdownMenuButton} from '../../DropdownMenuButton.react';
import {SelectList} from '../../SelectList.react';
import Button from '../../common/Button';
import ListingQuery from '../../../../lib/search/ListingQuery';
import {
  formatRange,
  getStartRangeFacetAsItems,
  getEndRangeFacetAsItems,
} from '../../../../lib/support/search';
import FilterDropdownItem from './FilterDropdownItem';
import Labeled from './Labeled';

class RangeDropdownFilter extends React.Component {
  static propTypes = {
    query: PropTypes.instanceOf(ListingQuery).isRequired,
    facets: PropTypes.object.isRequired,
    onUpdateQuery: PropTypes.func.isRequired,
    attrName: PropTypes.string.isRequired,
    unsetLabel: PropTypes.string,
    formatter: PropTypes.func,
    mutex: PropTypes.bool,
    ignoreEmpty: PropTypes.bool,
    zIndex: PropTypes.number,
  };

  static defaultProps = {
    mutex: false,
    ignoreEmpty: false,
  };

  render() {
    const {attrName, query, facets, onUpdateQuery, unsetLabel, formatter} = this.props;

    const range = query.getRange(attrName);

    const fromItems = getStartRangeFacetAsItems(facets[`${attrName}_min`], {
      max: range.max,
      includeAny: true,
      formatter,
    });
    const fromSelection = fromItems.filter(
      (item) => item.value != null && item.value === range.min
    );

    const toItems = getEndRangeFacetAsItems(facets[`${attrName}_max`], {
      min: range.min,
      includeAny: true,
      formatter,
    });
    const toSelection = toItems.filter((item) => item.value != null && item.value === range.max);

    return (
      <div className="RangeDropdownFilter">
        <DropdownMenuButton
          zIndex={this.props.zIndex}
          ref={(node) => (this._dropdownNode = node)}
          isValueButton
          label={
            formatRange(range, formatter) ||
            unsetLabel || <span className="RangeDropdownFilter_unset">Any</span>
          }>
          <div className="RangeDropdownFilter_dropdown">
            <div className="RangeDropdownFilter_dropdown_selectors">
              <Labeled label="From" direction="horizontal">
                <SelectList
                  format={FilterDropdownItem}
                  items={fromItems}
                  selectedItems={fromSelection}
                  onSelectionChange={(item) =>
                    onUpdateQuery((q) => q.set(`${attrName}_min`, item.value))
                  }
                />
              </Labeled>
              <Labeled label="To" direction="horizontal">
                <SelectList
                  format={FilterDropdownItem}
                  items={toItems}
                  selectedItems={toSelection}
                  onSelectionChange={(item) =>
                    onUpdateQuery((q) => q.set(`${attrName}_max`, item.value))
                  }
                />
              </Labeled>
            </div>
            <div className="RangeDropdownFilter_dropdown_buttons">
              <Button
                label="Close"
                kind="flat"
                size="big"
                onClick={() => this._dropdownNode.close()}
              />
            </div>
          </div>
        </DropdownMenuButton>
      </div>
    );
  }
}

export default RangeDropdownFilter;
