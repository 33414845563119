import React from 'react';
import PropTypes from 'prop-types';

import {ScheduledFunction} from '../../../../lib/node_utils';

export class YouTubeVideo extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    hasFocus: PropTypes.bool,
  };

  static defaultProps = {
    width: 600,
    height: 400,
    hasFocus: true,
  };

  state = {
    status: 'notLoaded',
  };

  componentDidMount() {
    this._waitForYT = ScheduledFunction.wrap(() => this._setupPlayer()).scheduleEvery(500);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const player = this._player;
    if (player && typeof player.pauseVideo === 'function') {
      const {hasFocus} = this.props;
      if (nextProps.hasFocus !== hasFocus && !nextProps.hasFocus) {
        player.pauseVideo();
      }
    }
  }

  componentWillUnmount() {
    if (this._waitForYT) {
      this._waitForYT.stop();
      this._waitForYT = null;
    }
    this._player = null;
  }

  _setupPlayer() {
    if (!(window.YT && this._playerNode)) {
      return;
    }
    this._waitForYT.stop();
    const {id, width, height} = this.props;
    if (!id) {
      this.setState({status: 'error'});
      return;
    }
    this._player = new window.YT.Player(this._playerNode, {
      height,
      width,
      videoId: id,
      events: {
        onReady: () => this.setState({status: 'loaded'}),
        onError: () => this.setState({status: 'error'}),
      },
    });
  }

  render() {
    return (
      <div className="Video" data-status={this.state.status}>
        <div ref={(e) => (this._playerNode = e)} />
      </div>
    );
  }
}
