import L from 'leaflet';

/**
 * Parse a polygon value which is either a string containing valid GeoJSON,
 * or a GeoJSON array. Always returns a mutable copy. Ensures the polygon is
 * closed and contains more than two points. Returns null if invalid.
 */
export function parseGeoJSONPolygon(value) {
  if (typeof value === 'string') {
    let parsed;
    try {
      parsed = JSON.parse(value);
    } catch (e) {
      return null;
    }
    return parseGeoJSONPolygon(parsed);
  }

  if (!isValidLngLatArray(value)) {
    return null;
  }
  return [].concat(ensureClosedPolygon(value)); // Clone
}

export function parseLatLng(value) {
  if (value == null) {
    return null;
  }
  if (value instanceof L.LatLng) {
    return value;
  }
  if (
    Array.isArray(value) &&
    value.length === 2 &&
    typeof value[0] === 'number' &&
    typeof value[1] === 'number'
  ) {
    return L.latLng(value[0], value[1]);
  }
  if (typeof value === 'object' && typeof value.lat === 'number' && typeof value.lng === 'number') {
    return L.latLng(value.lat, value.lng);
  }
  if (typeof value === 'string') {
    const numbers = value.split(',');
    if (numbers.length == 2) {
      const lat = parseFloat(numbers[0]);
      const lng = parseFloat(numbers[1]);
      if (!(isNaN(lat) || isNaN(lng))) {
        return L.latLng(lat, lng);
      }
    }
  }
  throw new Error(`invalid lat/lng: ${JSON.stringify(value)}`);
}

export function parseLatLngBounds(value) {
  if (value == null) {
    return null;
  }
  if (value instanceof L.LatLngBounds) {
    return value;
  }
  if (
    Array.isArray(value) &&
    value.length === 4 &&
    typeof value[0] === 'number' &&
    typeof value[1] === 'number' &&
    typeof value[2] === 'number' &&
    typeof value[3] === 'number'
  ) {
    // north, east, south, west
    return L.LatLngBounds(L.latLng(value[0], value[1]), L.latLng(value[2], value[3]));
  }
  if (typeof value === 'string') {
    const numbers = value.split(',');
    if (numbers.length == 4) {
      const north = parseFloat(numbers[0]);
      const east = parseFloat(numbers[1]);
      const south = parseFloat(numbers[2]);
      const west = parseFloat(numbers[3]);
      if (!(isNaN(north) || isNaN(east) || isNaN(south) || isNaN(west))) {
        return L.latLngBounds(L.latLng(north, east), L.latLng(south, west));
      }
    }
  }
  throw new Error(`invalid bounds: ${JSON.stringify(value)}`);
}

function isValidLngLatArray(array) {
  return Array.isArray(array) && array.length > 2 && array.every(isValidLngLat);
}

function isValidLngLat(value) {
  return Array.isArray(value) && value.length === 2;
}

function ensureClosedPolygon(points) {
  if (points.length < 2 || points[0] === points[points.length - 1]) {
    return points;
  }
  return points.concat([points[0]]);
}

export function computeCentroid(latlngs) {
  let lat = 0,
    lng = 0;
  for (let i = 0; i < latlngs.length; i++) {
    lat += latlngs[i].lat;
    lng += latlngs[i].lng;
  }
  return [lat / latlngs.length, lng / latlngs.length];
}
