import React from 'react';
import PropTypes from 'prop-types';

import {Broker} from '../../lib/models';
import HeadingBar from './common/HeadingBar';
import Button from './common/Button';
import {ListingGrid} from './listings/ListingGrid';
import BrokerDetails from './BrokerDetails';
import {urlForSearch} from '../../lib/support/routing';
import ListingQuery from '../../lib/search/ListingQuery';

class BrokerMain extends React.Component {
  static propTypes = {
    broker: PropTypes.instanceOf(Broker).isRequired,
    listings: PropTypes.object,
  };

  render() {
    const {broker, listings} = this.props;
    return (
      <div className="BrokerMain">
        <div className="BrokerMain_content">
          <div className="BrokerMain_content_details">
            <BrokerDetails broker={broker} showLink={false} />
          </div>
          {broker.biography && (
            <div className="BrokerMain_content_biography">
              <h4>Biography</h4>
              <div className="BrokerMain_content_biography_text">
                {broker.biography.split(/\n+/).map((s, i) => (
                  <p key={i}>{s}</p>
                ))}
              </div>
            </div>
          )}
          {listings.size > 0 && (
            <div className="BrokerMain_content_listings">
              <HeadingBar title="Available Listings" />
              <ListingGrid listings={listings} />
              <div className="BrokerMain_content_listings_more">
                <Button
                  label="See all listings"
                  kind="submit"
                  href={urlForSearch(ListingQuery.getEmptyListingQueryForBroker(broker))}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default BrokerMain;
