import React from 'react';
import PropTypes from 'prop-types';

import {deserializeQueryString, ObjectReference} from '../../../lib/support/queryStrings';
import {Territory} from '../../../lib/models';
import {zeus} from '../../../lib/zeus';
import ListingQuery from '../../../lib/search/ListingQuery';

class SearchSplatPage extends React.Component {
  static propTypes = {
    splat: PropTypes.string,
  };

  componentDidMount() {
    this._upgradeLegacySplatParams();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.splat !== prevProps.splat) {
      this._upgradeLegacySplatParams();
    }
  }

  render() {
    return null;
  }

  async _upgradeLegacySplatParams() {
    const {splat} = this.props;
    const query = await parseListingQueryString(splat);
    const search = query.toQueryString();
    window.location.href = `/search${search}`;
  }
}

let territoryCache = {};

async function parseListingQueryString(queryString) {
  const parsed = deserializeQueryString(queryString || '');

  let territory;
  {
    const territoryName = parsed.get('territory');
    if (territoryName) {
      territory = territoryCache[territoryName];
      if (!territory) {
        try {
          const data = await zeus(`/api/territory/${encodeURIComponent(territoryName)}`);
          const {name, title, bounds} = data;
          territory = {id: name, title, bounds};
          territoryCache = {[territoryName]: territory};
        } catch (e) {
          console.error('Problem loading territory', e);
          territory = {id: territoryName, title: 'Unknown Territory'};
        }
      }
    }
  }

  const entries = parsed.remove('territory').entries();
  const query = new ListingQuery();
  if (territory) {
    query.set('territory', new Territory(territory));
  }
  for (const [key, value] of entries) {
    if (value instanceof ObjectReference) {
      console.log('Warning: Parsed query string has unsupported object reference');
      continue;
    }
    switch (key) {
      case 'addressQuery':
      case 'agent_uid':
      case 'bathrooms':
      case 'bathrooms_value':
      case 'bedrooms':
      case 'bedrooms_value':
      case 'bounding_area':
      case 'bounds':
      case 'broker_uid':
      case 'city':
      case 'country':
      case 'county':
      case 'created_or_updated_since':
      case 'exclude_features':
      case 'featured_only':
      case 'feed_uid':
      case 'has_photos':
      case 'has_videos':
      case 'has_virtual_tours':
      case 'isCurrentLocation':
      case 'latlng':
      case 'lots_max':
      case 'lots_min':
      case 'lots_value_max':
      case 'lots_value_min':
      case 'nearest_schools':
      case 'not_featured':
      case 'open_house_dates':
      case 'open_house_only':
      case 'order':
      case 'price_max':
      case 'price_min':
      case 'price_value_max':
      case 'price_value_min':
      case 'property_type':
      case 'property_type_only':
      case 'publishing_timeframe':
      case 'rent_max':
      case 'rent_min':
      case 'rent_value_max':
      case 'rent_value_min':
      case 'required_features':
      case 'section':
      case 'shape':
      case 'square_footage_max':
      case 'square_footage_min':
      case 'square_footage_value':
      case 'square_footage_value_max':
      case 'square_footage_value_min':
      case 'state':
      case 'status':
      case 'street_address':
      case 'subdivision':
      case 'territory':
      case 'text':
      case 'type':
      case 'upsells':
      case 'year_built':
      case 'zip_code':
      case 'zip_codes':
        query.set(key, value);
        break;
      case 'listing_ids':
        query.set('listing_id', value);
    }
  }
  return query;
}

export default SearchSplatPage;
