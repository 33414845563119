import React from 'react';
import PropTypes from 'prop-types';

import Platform from '../../../lib/platform';

const ListingVirtualTour = ({url}) => (
  <button
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      Platform.openExternalUrl(url, '_blank');
    }}
    title="View Virtual Tour">
    Virtual Tour
  </button>
);

ListingVirtualTour.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ListingVirtualTour;
