import React from 'react';
import PropTypes from 'prop-types';
import {compact} from 'underscore';
import classNames from 'classnames';

import {isElementOrInside} from '../../../lib/node_utils';
import {Badge} from './Badge';

export class ToolbarMenu extends React.Component {
  static propTypes = {
    align: PropTypes.oneOf(['left', 'right']),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  };

  static defaultProps = {
    align: 'left',
  };

  render() {
    return (
      <div className="ToolbarMenu" data-align={this.props.align}>
        {this.props.children ? this.props.children : null}
      </div>
    );
  }
}

export const ToolbarSpacer = () => <div className="ToolbarSpacer" />;

export class ToolbarItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    heading: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    stretch: PropTypes.bool.isRequired,
    button: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    onOpen: PropTypes.func,
    href: PropTypes.string,
    icon: PropTypes.string,
    menu: PropTypes.element,
    count: PropTypes.number,
  };

  static defaultProps = {
    stretch: false,
    heading: false,
    active: false,
    button: false,
  };

  state = {
    open: false,
    width: null,
  };

  componentDidMount() {
    window.addEventListener('click', this._handleDocumentClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this._handleDocumentClick);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.onOpen && this.state.open && !prevState.open) {
      this.props.onOpen();
    }
  }

  render() {
    let formattedCount;
    const {count} = this.props;
    if (count != null) {
      formattedCount = (
        <span className="Toolbar_count">
          {count > 0 ? <Badge kind="prominent">{count}</Badge> : <Badge>0</Badge>}
        </span>
      );
    }

    let content;
    if (this.props.button || this.props.onClick || this.props.href) {
      content = (
        <a href={this.props.href} onClick={this._handleClick} className="Toolbar_label">
          {this.props.children}
          {formattedCount}
        </a>
      );
    } else {
      content = (
        <label className="Toolbar_label">
          {this.props.children}
          {formattedCount}
        </label>
      );
    }

    return (
      <div
        className={classNames('ToolbarItem', this.props.className)}
        data-heading={this.props.heading}
        data-stretch={this.props.stretch}
        data-active={this.props.active}
        data-button={this.props.button}
        data-has-menu={!!this.props.menu}
        data-icon={this.props.icon}
        data-is-open={this.state.open}
        ref={(node) => (this._node = node)}>
        {content}
        {this.props.menu}
      </div>
    );
  }

  _handleClick = (event) => {
    const {onClick} = this.props;
    if (onClick) {
      event.preventDefault();
      onClick();
    } else if (this.props.button) {
      event.preventDefault();
      this.setState({open: !this.state.open});
    } else if (!this.props.href) {
      event.preventDefault();
    }
  };

  _handleDocumentClick = (event) => {
    if (this._node && this.state.open && !isElementOrInside(event.target, this._node)) {
      this.setState({open: false});
    }
  };
}

export class Toolbar extends React.Component {
  static propTypes = {
    items: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    direction: PropTypes.oneOf(['horizontal', 'vertical']),
    className: PropTypes.string,
    stretch: PropTypes.bool.isRequired,
    kind: PropTypes.oneOf(['local', 'global']),
    size: PropTypes.oneOf(['normal', 'small']),
  };

  static defaultProps = {
    stretch: false,
    direction: 'horizontal',
    size: 'normal',
    kind: 'local',
  };

  render() {
    const items = compact(
      React.Children.map(this.props.items, (child, idx) =>
        child ? React.cloneElement(child, {key: `item-${idx}`}) : null
      )
    );
    if (items.length === 0) {
      return null;
    }
    return (
      <div
        className={classNames('Toolbar', this.props.className)}
        data-direction={this.props.direction}
        data-stretch={this.props.stretch}
        data-size={this.props.size}
        data-kind={this.props.kind}
        ref={(node) => (this._node = node)}>
        <div className="Toolbar_items">{items}</div>
      </div>
    );
  }
}
