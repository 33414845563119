import React from 'react';
import PropTypes from 'prop-types';
import {compact} from 'underscore';

import {isNumberOrNumericRange} from '../../../lib/utils/numbers';
import NumberOrRange from '../common/NumberOrRange';
import Fixable from '../common/Fixable';

class ListingNavigationComponent extends React.Component {
  static propTypes = {
    listing: PropTypes.object.isRequired,
    children: PropTypes.node,
  };

  state = {
    showingEmailForm: false,
  };

  render() {
    const {listing, children} = this.props;

    const amount = listing.price || listing.rent;
    const previousPrice = listing.previousPrice();
    const amountRange = listing.price_range || listing.rent_range;

    return (
      <Fixable zIndex={200000}>
        <div className="ListingNavigation">
          <div className="ListingNavigation_title">
            <div className="ListingNavigation_title_street">
              <span className="ListingNavigation_title_street_address">
                {listing.undisclosed_address ? 'Undisclosed Address' : listing.street_address}
              </span>
              {!listing.undisclosed_address && listing.unit_number && (
                <span className="ListingNavigation_title_unit_number">{listing.unit_number}</span>
              )}
              <span className="ListingNavigation_title_street_city">
                {this._getFormattedCityState()}
              </span>
            </div>
            {!listing.isOffMarket() &&
              (isNumberOrNumericRange(amount) || isNumberOrNumericRange(amountRange)) && (
                <div className="ListingNavigation_title_pricing">
                  <label>
                    {listing.type === 'for_sale'
                      ? listing.isSold()
                        ? 'Sales Price:'
                        : 'Asking Price:'
                      : 'Rent:'}
                  </label>{' '}
                  {previousPrice && (
                    <span className="ListingNavigation_title_pricing_previous_price">
                      <NumberOrRange value={previousPrice} currency="$" />
                    </span>
                  )}
                  <span className="ListingNavigation_title_pricing_price">
                    <NumberOrRange value={amount} range={amountRange} currency="$" />
                  </span>
                  {previousPrice && amount && (
                    <span
                      className="ListingNavigation_title_pricing_price_change"
                      data-change-type={previousPrice > amount ? 'down' : 'up'}>
                      {previousPrice > amount ? 'Reduced' : 'Increased'}
                    </span>
                  )}
                </div>
              )}
          </div>
        </div>
        {children}
      </Fixable>
    );
  }

  _getFormattedCityState = () => {
    const {listing} = this.props;
    return compact([listing.city, listing.state]).join(', ');
  };
}

export default ListingNavigationComponent;
