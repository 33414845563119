import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compact, flatten, indexOf, isArray} from 'underscore';

import {isTrue} from '../../lib/node_utils';
import {DropdownMenuButton} from '../components/DropdownMenuButton.react';
import {SelectListButton} from '../components/SelectListButton.react';
import {TextField} from '../components/TextField.react';
import Button from '../components/common/Button';
import {urlForSearch} from '../../lib/support/routing';
import {Listing} from '../../lib/models';
import ListingQuery, {ListingSearchResultFacets} from '../../lib/search/ListingQuery';
import {
  countSetValues,
  formatPriceRange,
  formatSquareFootageRange,
  getEndRangeFacetAsItems,
  getFacetAsItems,
  getStartRangeFacetAsItems,
  OPEN_HOUSE_ITEMS,
} from '../../lib/support/search';
import LocationSelector from '../components/common/LocationSelector';
import {FilterBar, FilterBarItem} from '../components/common/FilterBar';
import Configuration from '../../lib/configuration';
import {
  PropTypeColor,
  PropTypeDimension,
  validColor,
  validDimension,
  style,
  styleIf,
} from './cssOverrides';
import {zeus} from '../../lib/zeus';

const PropTypeFlag = PropTypes.oneOfType([PropTypes.string, PropTypes.bool]);

class SearchWidget extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(Listing.TYPES),
    title: PropTypes.string,
    section: PropTypes.string,
    property_type: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    featured_only: PropTypeFlag,
    open_house_only: PropTypeFlag,
    open_house_dates: PropTypes.oneOf([
      'today',
      'today_or_tomorrow',
      'next_7_days',
      'next_14_days',
      'next_30_days',
    ]),
    new_listings_only: PropTypeFlag,
    locationPlaceholder: PropTypes.string,

    // Appearance flags
    hide_mls_field: PropTypeFlag,
    hide_title: PropTypeFlag,
    hide_query_beds: PropTypeFlag,
    hide_query_price: PropTypeFlag,
    hide_query_more: PropTypeFlag,
    hide_query_baths: PropTypeFlag,

    // Style customizations for fields
    text_color: PropTypeColor,
    button_color: PropTypeColor,
    border_color: PropTypeColor,
    border_width: PropTypeDimension,
    border_radius: PropTypeDimension,

    // Style customizations for widget background
    background_color: PropTypeColor,
    widget_border_color: PropTypeColor,
    widget_border_width: PropTypeDimension,
    widget_border_radius: PropTypeDimension,
    widget_padding: PropTypeDimension,

    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    type: null,
    title: null,
    hideMlsField: false,
    locationPlaceholder: 'Location',
  };

  state = {
    types: ListingQuery.getListingTypes(),
    query: new ListingQuery(),
    facets: new ListingSearchResultFacets(),
    facetsReady: false,
  };

  constructor(props) {
    super(props);
    this._videos = Configuration.get('ui.search.videos') === true;
  }

  UNSAFE_componentWillMount() {
    const query = new ListingQuery();
    ['type', 'property_type', 'section', 'open_house_dates'].forEach(
      (k) => this.props[k] && query.set(k, this.props[k])
    );
    ['featured_only', 'open_house_only', 'new_listings_only'].forEach(
      (k) => this.props[k] && query.set(k, isTrue(this.props[k]))
    );
    this.setState({query});
    this._fetchFacetCounts(query);
  }

  render() {
    const {query, facets} = this.state;

    const propertyTypeItems = getFacetAsItems(facets.getFacet('property_type'), {
      ignoreEmpty: true,
    });
    const bedroomsItems = getFacetAsItems(facets.getFacet('bedrooms'));
    const bathroomsItems = getFacetAsItems(facets.getFacet('bathrooms'));
    const priceFromItems = getStartRangeFacetAsItems(facets.getFacet('price_min'), {
      max: query.getPriceRange().max,
      type: 'price',
    });
    const priceToItems = getEndRangeFacetAsItems(facets.getFacet('price_max'), {
      min: query.getPriceRange().min,
      type: 'price',
    });
    const rentFromItems = getStartRangeFacetAsItems(facets.getFacet('rent_min'), {
      max: query.getRentRange().max,
      type: 'price',
    });
    const rentToItems = getEndRangeFacetAsItems(facets.getFacet('rent_max'), {
      min: query.getRentRange().min,
      type: 'price',
    });
    const sqFtFrom = getStartRangeFacetAsItems(facets.getFacet('square_footage_min'), {
      max: query.getSquareFootageRange().max,
      type: 'numberWithCommas',
    });
    const sqFtTo = getEndRangeFacetAsItems(facets.getFacet('square_footage_max'), {
      min: query.getSquareFootageRange().min,
      type: 'numberWithCommas',
    });
    const hasPhotos = getFacetAsItems(facets.getFacet('has_photos'), {
      ignoreKeys: ['false'],
    });
    const hasVideos = getFacetAsItems(facets.getFacet('has_videos'), {
      ignoreKeys: ['false'],
    });

    const itemFormat = (item) => (
      <div className="ListingSearch_dropdown_item">
        {item && <span>{item.label}</span>}
        {item && item.count > 0 && (
          <span className="ListingSearch_dropdown_item_count">{item.count}</span>
        )}
      </div>
    );
    const labelFormat = (item) => item && item.label;
    // FIXME: This lookup is inefficient and not good
    const getSelectedItems = (items, key) =>
      items.filter((item) => indexOf(compact(flatten([query.get(key)])), item.value) !== -1);

    return (
      <section className="search_widget">
        <style>
          {`
            .search_widget_table .search_widget_cell input,
            .search_widget_filters .filter_bar_items li .dropdown_menu_button,
            .search_widget_table .search_widget_listing_type_filter .dropdown_menu_button{
              ${style('border-color', validColor(this.props.border_color))}
              ${style('border-width', validDimension(this.props.border_width))}
              ${style('border-radius', validDimension(this.props.border_radius))}
              ${style('background-color', validColor(this.props.background_color))}
            }

            .search_widget_table .search_widget_cell>.Button{
              ${style('border-color', validColor(this.props.border_color))}
              ${style('border-width', validDimension(this.props.border_width))}
              ${style('border-radius', validDimension(this.props.border_radius))}
              ${style('background-color', validColor(this.props.button_color))}
            }

            .search_widget {
              ${style('background-color', validColor(this.props.background_color))}
              ${style('color', validColor(this.props.text_color))}
              ${styleIf(
                this.props.widget_border_color || this.props.widget_border_width,
                'border-style',
                'solid'
              )}
              ${style('border-color', validColor(this.props.widget_border_color))}
              ${style('border-radius', validDimension(this.props.widget_border_radius))}
              ${style('border-width', validDimension(this.props.widget_border_width))}
              ${style('padding', validDimension(this.props.widget_padding))}
            }

            .search_widget .dropdown_menu_button_content_label,
            .search_widget_table input[type="text"] {
              ${style('color', validColor(this.props.text_color))}
            }
          `}
        </style>
        {!isTrue(this.props.hide_title) && (
          <div className="search_widget_header">
            <h2>
              {this.props.title ||
                Configuration.get('ui.widgets.search.title') ||
                'Find Your New Home'}
            </h2>
          </div>
        )}

        <div className="search_widget_table">
          <div className="search_widget_row">
            {this.state.types.length > 1 && !this.props.type && (
              <div className="search_widget_cell search_widget_listing_type_filter">
                <SelectListButton
                  unsetLabelFormat="For sale"
                  labelFormat={labelFormat}
                  itemFormat={itemFormat}
                  items={getFacetAsItems(this.state.facets.getFacet('type'), {
                    mutex: true,
                    onlyKeys: this.state.types,
                  })}
                  selectedItems={getFacetAsItems(this.state.facets.getFacet('type'), {
                    mutex: true,
                  }).filter((i) => i.value === query.getListingType())}
                  loading={!this.state.facetsReady}
                  onChange={this._createFacetChangeHandler('type')}
                />
              </div>
            )}

            {!!Configuration.get('ui.search.sections') && (
              <div className="search_widget_cell search_widget_listing_section_filter">
                <SelectListButton
                  unsetLabelFormat={Configuration.get('ui.search.sections.default')}
                  labelFormat={labelFormat}
                  itemFormat={itemFormat}
                  items={getFacetAsItems(facets.getFacet('section'), {
                    mutex: true,
                    ignoreEmpty: true,
                  })}
                  selectedItems={getSelectedItems(
                    getFacetAsItems(facets.getFacet('section'), {
                      mutex: true,
                      ignoreEmpty: true,
                    }),
                    'section'
                  )}
                  loading={!this.state.facetsReady}
                  onChange={this._createFacetChangeHandler('section')}
                />
              </div>
            )}

            <div className="search_widget_cell">
              <LocationSelector
                placeholder={this.props.locationPlaceholder}
                geoLocation={query.getGeoLocation()}
                onChange={this._handleLocationSelectorChange}
                onUncommittedChange={(uncommittedLocation) => this.setState({uncommittedLocation})}
                onCommittedWithEnterKey={this._handleSearchClick}
                canUseCurrentLocation={false}
              />
            </div>

            {!isTrue(this.props.hide_mls_field) && (
              <div className="search_widget_cell">
                <TextField
                  placeholder="MLS #"
                  value={query.get('listing_id') || ''}
                  onChange={(text) => this._updateQuery((q) => q.set('listing_id', text || null))}
                  size={10}
                />
              </div>
            )}

            <div className="search_widget_cell search_widget_button">
              <Button label="Search" onClick={this._handleSearchClick} />
            </div>
          </div>
        </div>

        <div className="search_widget_filters">
          <FilterBar>
            {!isTrue(this.props.hide_query_beds) && (
              <FilterBarItem label="Beds">
                <SelectListButton
                  labelFormat={labelFormat}
                  itemFormat={itemFormat}
                  items={bedroomsItems}
                  selectedItems={getSelectedItems(bedroomsItems, 'bedrooms')}
                  loading={!this.state.facetsReady}
                  onChange={this._createFacetChangeHandler('bedrooms')}
                />
              </FilterBarItem>
            )}

            {!isTrue(this.props.hide_query_baths) && (
              <FilterBarItem label="Baths">
                <SelectListButton
                  labelFormat={labelFormat}
                  itemFormat={itemFormat}
                  items={bathroomsItems}
                  selectedItems={getSelectedItems(bathroomsItems, 'bathrooms')}
                  loading={!this.state.facetsReady}
                  onChange={this._createFacetChangeHandler('bathrooms')}
                />
              </FilterBarItem>
            )}

            {!isTrue(this.props.hide_query_price) &&
              (query.getListingType() === 'for_sale' ? (
                <FilterBarItem label="Price">
                  <DropdownMenuButton
                    isValueButton
                    label={formatPriceRange(query.getPriceRange()) || ''}>
                    <FilterBar>
                      <FilterBarItem label="From">
                        <SelectListButton
                          labelFormat={labelFormat}
                          itemFormat={itemFormat}
                          items={priceFromItems}
                          selectedItems={getSelectedItems(priceFromItems, 'price_min')}
                          onChange={this._createFacetChangeHandler('price_min')}
                        />
                      </FilterBarItem>

                      <FilterBarItem label="To">
                        <SelectListButton
                          labelFormat={labelFormat}
                          itemFormat={itemFormat}
                          items={priceToItems}
                          selectedItems={getSelectedItems(priceToItems, 'price_max')}
                          onChange={this._createFacetChangeHandler('price_max')}
                        />
                      </FilterBarItem>

                      {this._hasPriceRange() ? (
                        <FilterBarItem>
                          <Button label="Clear" onClick={this._handleResetPriceClick} />
                        </FilterBarItem>
                      ) : null}
                    </FilterBar>
                  </DropdownMenuButton>
                </FilterBarItem>
              ) : (
                <FilterBarItem label="Rent">
                  <DropdownMenuButton
                    isValueButton
                    label={formatPriceRange(query.getRentRange()) || ''}>
                    <FilterBar>
                      <FilterBarItem label="From">
                        <SelectListButton
                          labelFormat={labelFormat}
                          itemFormat={itemFormat}
                          items={rentFromItems}
                          selectedItems={getSelectedItems(rentFromItems, 'rent_min')}
                          onChange={this._createFacetChangeHandler('rent_min')}
                        />
                      </FilterBarItem>

                      <FilterBarItem label="To">
                        <SelectListButton
                          labelFormat={labelFormat}
                          itemFormat={itemFormat}
                          items={rentToItems}
                          selectedItems={getSelectedItems(rentToItems, 'rent_max')}
                          onChange={this._createFacetChangeHandler('rent_max')}
                        />
                      </FilterBarItem>

                      {this._hasRentRange() && (
                        <FilterBarItem>
                          <Button label="Clear" onClick={this._handleResetRentClick} />
                        </FilterBarItem>
                      )}
                    </FilterBar>
                  </DropdownMenuButton>
                </FilterBarItem>
              ))}

            {!isTrue(this.props.hide_query_more) && (
              <FilterBarItem label={<span>&nbsp;</span>}>
                <DropdownMenuButton label={this._formatMoreLabel()}>
                  <FilterBar direction="vertical">
                    <FilterBarItem label="Property Type">
                      <SelectListButton
                        labelFormat={labelFormat}
                        itemFormat={itemFormat}
                        multiSelect
                        items={propertyTypeItems}
                        selectedItems={getSelectedItems(propertyTypeItems, 'property_type')}
                        loading={!this.state.facetsReady}
                        onChange={this._createFacetChangeHandler('property_type')}
                      />
                    </FilterBarItem>

                    <FilterBarItem label="Square Footage">
                      <DropdownMenuButton
                        ref={(e) => (this._squareFootageDropdown = e)}
                        isValueButton
                        label={formatSquareFootageRange(query.getSquareFootageRange()) || ''}
                        loading={!this.state.facetsReady}>
                        <FilterBar>
                          <FilterBarItem label="From">
                            <SelectListButton
                              labelFormat={labelFormat}
                              itemFormat={itemFormat}
                              items={sqFtFrom}
                              selectedItems={getSelectedItems(sqFtFrom, 'square_footage_min')}
                              onChange={this._createFacetChangeHandler('square_footage_min')}
                            />
                          </FilterBarItem>

                          <FilterBarItem label="To">
                            <SelectListButton
                              labelFormat={labelFormat}
                              itemFormat={itemFormat}
                              items={sqFtTo}
                              selectedItems={getSelectedItems(sqFtTo, 'square_footage_max')}
                              onChange={this._createFacetChangeHandler('square_footage_max')}
                            />
                          </FilterBarItem>

                          {this._hasSquareFootageRange() && (
                            <FilterBarItem>
                              <Button label="Clear" onClick={this._handleResetSquareFootageClick} />
                            </FilterBarItem>
                          )}
                        </FilterBar>

                        <Button
                          className="search_widget_close_dropdown_button"
                          label="Close"
                          onClick={this._handleCloseSquareFootageDropdownClick}
                        />
                      </DropdownMenuButton>
                    </FilterBarItem>

                    <FilterBarItem label="Photos">
                      <SelectListButton
                        labelFormat={labelFormat}
                        itemFormat={itemFormat}
                        items={hasPhotos}
                        selectedItems={
                          (query.get('has_photos') && hasPhotos.filter((i) => !!i.value)) || []
                        }
                        onChange={(item) =>
                          this._updateQuery((q) => q.set('has_photos', item.value ? true : null))
                        }
                      />
                    </FilterBarItem>

                    {this._videos && (
                      <FilterBarItem label="Videos">
                        <SelectListButton
                          labelFormat={labelFormat}
                          itemFormat={itemFormat}
                          items={hasVideos}
                          selectedItems={
                            (query.get('has_videos') && hasVideos.filter((i) => !!i.value)) || []
                          }
                          onChange={(item) =>
                            this._updateQuery((q) => q.set('has_videos', item.value ? true : null))
                          }
                        />
                      </FilterBarItem>
                    )}

                    <FilterBarItem label="Open House">
                      <SelectListButton
                        labelFormat={labelFormat}
                        itemFormat={itemFormat}
                        items={OPEN_HOUSE_ITEMS}
                        selectedItems={getSelectedItems(OPEN_HOUSE_ITEMS, 'open_house_dates')}
                        onChange={this._createFacetChangeHandler('open_house_dates')}
                      />
                    </FilterBarItem>
                  </FilterBar>
                </DropdownMenuButton>
              </FilterBarItem>
            )}
          </FilterBar>

          <div className="search_widget_button">
            <Button label="Search" onClick={this._handleSearchClick} />
          </div>
        </div>
      </section>
    );
  }

  _fetchFacetCounts = async (query) => {
    if (
      this.props.type &&
      this.props.hide_mls_field &&
      this.props.hide_query_beds &&
      this.props.hide_query_baths &&
      this.props.hide_query_price &&
      this.props.hide_query_more
    ) {
      // UI doesn't need facet counts, don't pay to fetch them
      return;
    }
    this.setState({
      facets: ListingSearchResultFacets.fromObject(
        await zeus('/api/query/facets', {
          queryObj: {
            ...query.toGraphQL(),
          },
        })
      ),
      facetsReady: true,
    });
  };

  _formatMoreLabel = () => {
    const count = countSetValues(this.state.query, [
      'property_type',
      'square_footage',
      'has_photos',
      'open_house_dates',
    ]);
    let label = <span className="more_label">More</span>;
    if (count > 0) {
      label = (
        <span>
          <span className="more_count">{count}</span> {label}
        </span>
      );
    }
    return label;
  };

  _createFacetChangeHandler = (attribute) => {
    return (items) =>
      this._updateQuery((query) =>
        query.set(attribute, isArray(items) ? items.map((i) => i.value) : items.value)
      );
  };

  _updateQuery = (fn) => {
    const query = this.state.query.clone();
    fn(query);
    this.setState({query});
    this._fetchFacetCounts(query);
  };

  _handleLocationSelectorChange = (geoLocation) => {
    this._updateQuery((query) => query.setGeoLocation(geoLocation));
  };

  _handleSearchClick = async (event) => {
    const {query, uncommittedLocation} = this.state;
    if (uncommittedLocation) {
      query.setGeoLocation(uncommittedLocation);
    }
    if (event) {
      event.preventDefault();
    }
    window.open(urlForSearch(query), '_top');
  };

  _handleResetPriceClick = (event) => {
    this._updateQuery((query) =>
      query.set({
        price_min: null,
        price_max: null,
      })
    );
    event.preventDefault();
  };

  _handleResetRentClick = (event) => {
    this._updateQuery((query) =>
      query.set({
        rent_min: null,
        rent_max: null,
      })
    );
    event.preventDefault();
  };

  _handleResetSquareFootageClick = (event) => {
    this._updateQuery((query) =>
      query.set({
        square_footage_min: null,
        square_footage_max: null,
      })
    );
    event.preventDefault();
  };

  _handleCloseSquareFootageDropdownClick = () => {
    this._squareFootageDropdown.close();
  };

  _hasPriceRange = () => {
    const range = this.state.query.getPriceRange();
    return range.min !== null || range.max !== null;
  };

  _hasRentRange = () => {
    const range = this.state.query.getRentRange();
    return range.min !== null || range.max !== null;
  };

  _hasSquareFootageRange = () => {
    const range = this.state.query.getSquareFootageRange();
    return range.min !== null || range.max !== null;
  };
}

export default connect()(SearchWidget);
