class Timer {
  /**
   * Fire `fn` after `delay` milliseconds. The previously scheduled
   * function, if set, will be canceled.
   */
  fire(fn, delay) {
    this.reset();
    this._timeoutId = setTimeout(fn, delay);
  }

  /**
   * Stop firing.
   */
  reset() {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
      this._timeoutId = null;
    }
  }
}

export default Timer;
