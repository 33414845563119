/**
 * Compute basic hash of string.
 */
export function computeStringHash(s) {
  if (typeof s !== 'string') {
    return 0;
  }
  let hash = 5381;
  let i = s.length;
  while (i) {
    hash = (hash * 33) ^ s.charCodeAt(--i);
  }
  return hash >>> 0;
}
