import React from 'react';
import PropTypes from 'prop-types';

export default class Arrow extends React.Component {
  static displayName = 'CarouselArrow';

  static propTypes = {
    onClick: PropTypes.func,
    size: PropTypes.oneOf(['big', 'small']).isRequired,
    direction: PropTypes.oneOf(['left', 'right']).isRequired,
  };

  render() {
    return (
      <div
        className="CarouselArrow"
        data-size={this.props.size}
        data-direction={this.props.direction}
        onClick={this._handleClick}
      />
    );
  }

  _handleClick = (event) => {
    event.preventDefault();
    if (this.props.onClick) {
      this.props.onClick();
    }
  };
}
