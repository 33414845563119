import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {getOffsetLeft, getOffsetTop, getWidth, getHeight} from '../../lib/node_utils';

export class Balloon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  componentDidMount() {
    this._mounted = true;
    // This forces recalculation of placement
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({active: true});
  }

  UNSAFE_componentWillUnmount() {
    this._mounted = false;
  }

  UNSAFE_componentWillReceiveProps(/* nextProps */) {
    // This forces recalculation of placement
    setTimeout(() => {
      if (this._mounted) {
        this.forceUpdate();
      }
    }, 0);
  }

  render() {
    const element = this.domNode;
    const parentElement = this.props.parentElement;

    const x = getOffsetLeft(parentElement);
    const y = getOffsetTop(parentElement);
    const w = getWidth(parentElement);
    const h = getHeight(parentElement);

    const ww = element ? getWidth(element) : 0;
    const hh = element ? getHeight(element) : 0;

    const style = {
      left: x - ww / 2 + w / 2,
      top: this.props.placement === 'above' ? y - h - hh : y + h,
    };
    if (!element) {
      style.visibility = 'hidden';
    }

    const arrow = this.props.placement === 'above' ? 'below' : 'above';

    return (
      <div
        className={classNames({
          Balloon: true,
          Balloon_active: this.state.active,
        })}
        style={style}
        data-arrow={arrow}
        ref={(node) => (this.domNode = node)}>
        {this.props.children}
      </div>
    );
  }
}

Balloon.propTypes = {
  parentElement: PropTypes.object.isRequired,
  placement: PropTypes.oneOf(['below', 'above']).isRequired,
  children: PropTypes.element.isRequired,
};

Balloon.defaultProps = {
  placement: 'above',
};
