import L from 'leaflet';

import Configuration from '../configuration';
import {computeCentroid} from '../utils/geo';
import {
  CHANGE_LISTING_SEARCH_MAP_SIZE,
  UPDATE_LISTING_SEARCH_MAP,
  SET_LISTING_SEARCH_MAP_FOCUS,
} from '../actions/listingSearchMap';

function getDefaultCenter() {
  const center = Configuration.get('ui.map.center');
  if (center) {
    return L.latLng(center);
  }

  const boundingPolygon = Configuration.get('data.bounding_area');
  if (boundingPolygon) {
    return L.latLng(computeCentroid(boundingPolygon.map((ll) => L.latLng(ll[1], ll[0]))));
  }

  // https://en.wikipedia.org/wiki/Geographic_center_of_the_contiguous_United_States
  return L.latLng(39.833333, -98.583333);
}

function initialState() {
  return {
    focus: null,
    center: getDefaultCenter(),
    maximized: false,
    zoom: Configuration.get('ui.map.zoom') ? Configuration.get('ui.map.zoom') : null,
  };
}

export default function listingSearchMap(state, action) {
  if (!state) {
    state = initialState();
  }
  switch (action.type) {
    case UPDATE_LISTING_SEARCH_MAP:
      return Object.assign({}, state, {
        center: action.center,
        zoom: action.zoom,
      });
    case CHANGE_LISTING_SEARCH_MAP_SIZE:
      return Object.assign({}, state, {
        maximized: action.maximized,
      });
    case SET_LISTING_SEARCH_MAP_FOCUS:
      if ((action.listing && action.listing.id) !== (state.focus && state.focus.id)) {
        return Object.assign({}, state, {
          focus: action.listing,
        });
      }
      break;
  }
  return state;
}
