import {Listing} from '../models';
import {zeus} from '../zeus';

export const CHANGE_LISTING_SEARCH_MAP_SIZE = 'CHANGE_LISTING_SEARCH_MAP_SIZE';
export const UPDATE_LISTING_SEARCH_MAP = 'UPDATE_LISTING_SEARCH_MAP';
export const SET_LISTING_SEARCH_MAP_FOCUS = 'SET_LISTING_SEARCH_MAP_FOCUS';
export const SET_LISTING_SEARCH_MAP_FOCUS_BY_UID = 'SET_LISTING_SEARCH_MAP_FOCUS_BY_UID';

export function updateListingSearchMap({center, zoom}) {
  return {
    type: UPDATE_LISTING_SEARCH_MAP,
    center,
    zoom,
  };
}

export function changeListingSearchMapSize(maximized) {
  return {
    type: CHANGE_LISTING_SEARCH_MAP_SIZE,
    maximized: !!maximized,
  };
}

export function setListingSearchMapFocus(listing = null) {
  return async (dispatch, getState) => {
    const {listingSearchMap: state} = getState();
    if ((state.focus && state.focus.id) == (listing && listing.id)) {
      return;
    }
    dispatch({
      type: SET_LISTING_SEARCH_MAP_FOCUS,
      listing,
    });
  };
}

export function setListingSearchMapFocusById(id) {
  return async (dispatch, getState) => {
    if (!id) {
      return;
    }

    const {listingSearchMap: state} = getState();
    if ((state.focus && state.focus.id) === id) {
      return;
    }

    const data = await zeus(`/api/query/listings/${id}`);
    if (data.listing) {
      dispatch(setListingSearchMapFocus(new Listing(data.listing)));
    }
  };
}
