import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {cloneElementsWithIndexKeys} from '../../../lib/utils/react';

export class TableCell extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    heading: PropTypes.bool,
  };

  render() {
    if (this.props.heading) {
      return <th>{this.props.children}</th>;
    }
    return <td>{this.props.children}</td>;
  }
}

class TableRowComponent extends React.Component {
  static propTypes = {
    cells: PropTypes.arrayOf(PropTypes.node),
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {cells} = this.props;
    const rowProps = {};
    return <tr {...rowProps}>{cloneElementsWithIndexKeys(cells)}</tr>;
  }
}

export const TableRow = connect()(TableRowComponent);

export class Table extends React.Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.node),
    rows: PropTypes.arrayOf(PropTypes.node),
  };

  render() {
    const {columns, rows} = this.props;
    return (
      <div className="Table">
        <table>
          {columns && (
            <thead>
              <tr>{cloneElementsWithIndexKeys(columns)}</tr>
            </thead>
          )}
          <tbody>{cloneElementsWithIndexKeys(rows)}</tbody>
        </table>
      </div>
    );
  }
}
