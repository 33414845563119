import React from 'react';
import PropTypes from 'prop-types';

import {TextField} from '../../TextField.react';
import ListingQuery from '../../../../lib/search/ListingQuery';

const TextFilter = ({attrName, placeholder, query, onUpdateQuery, validator}) => (
  <TextField
    placeholder={placeholder}
    value={query.get(attrName) || ''}
    validator={validator}
    onChange={(value) =>
      onUpdateQuery((q) => q.set(attrName, value.trim().length > 0 ? value : null))
    }
    changeDelay={1000}
    size={30}
  />
);

TextFilter.defaultProps = {
  placeholder: 'Words to search for',
};

TextFilter.propTypes = {
  query: PropTypes.instanceOf(ListingQuery).isRequired,
  onUpdateQuery: PropTypes.func.isRequired,
  validator: PropTypes.func,
  attrName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default TextFilter;
