import {List} from 'immutable';

export function inGroupsOf(array, count, {pad}) {
  if (count < 1) {
    return List();
  }

  if (count >= array.size) {
    return List([array]);
  }

  let result = List();
  let index = 0;
  while (index < array.size) {
    let slice = array.slice(index, index + count);
    if (pad) {
      while (slice.size < count) {
        slice = slice.push(null);
      }
    }
    result = result.push(slice);
    index += count;
  }
  return result;
}
