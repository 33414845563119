import $ from 'jquery';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  unstable_renderSubtreeIntoContainer as renderSubtreeIntoContainer,
  unmountComponentAtNode,
} from 'react-dom';

import {isElementOrInside} from '../../lib/node_utils';

/**
 * An overlay is a "portal" component that renders its children in a parallel
 * tree. For example, this:
 *
 *   <body>
 *     <div id="main">
 *       <Overlay>
 *         <div>hello</div>
 *       </Overlay>
 *     </div>
 *   </body>
 *
 * is rendered as:
 *
 *   <body>
 *     <div id="main"></div>
 *     <div className='react_overlay_stack'>
 *       <div>
 *         <div>hello</div>
 *       </div>
 *     </div>
 *   </body>
 *
 * The stacking of overlays is preserved so that the newest overlay is always
 * on top of the previous ones.
 */
export class Overlay extends Component {
  constructor(props) {
    super(props);

    this._handleDocumentKeyDown = this._handleDocumentKeyDown.bind(this);
    this._handleDocumentMouseDown = this._handleDocumentMouseDown.bind(this);
    this._handleDocumentTouchStart = this._handleDocumentTouchStart.bind(this);
    this._handleFieldKeyDown = this._handleFieldKeyDown.bind(this);
  }

  componentDidMount() {
    let stackElement = Overlay.stackElement;
    if (!stackElement) {
      stackElement = document.createElement('div');
      stackElement.className = 'react_overlay_stack';
      stackElement.style.zIndex = 10000;
      stackElement.style.position = 'absolute';
      stackElement.style.top = 0;
      stackElement.style.left = 0;
      document.body.appendChild(stackElement);
      Overlay.stackElement = stackElement;
    }

    this._node = document.createElement('div');
    this._node.style.position = 'absolute';
    this._node.style.top = 0;
    this._node.style.left = 0;

    // Defer setup slightly to avoid getting any events that are in flight
    setTimeout(() => {
      if (this._node) {
        stackElement.appendChild(this._node);

        $(this._node).find('input, button, select').on('keydown', this._handleFieldKeyDown);

        $(document.body)
          .on('keydown', this._handleDocumentKeyDown)
          .on('mousedown', this._handleDocumentMouseDown)
          .on('touchstart', this._handleDocumentTouchStart);
      }
    }, 0);

    this._renderContent(this.props);
  }

  componentWillUnmount() {
    $(document.body)
      .off('touchstart', this._handleDocumentTouchStart)
      .off('mousedown', this._handleDocumentMouseDown)
      .off('keydown', this._handleDocumentKeyDown);

    unmountComponentAtNode(this._node);

    if (this._node.parentNode) {
      this._node.parentNode.removeChild(this._node);
    }
    this._node = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._renderContent(nextProps);
  }

  render() {
    return <noscript />;
  }

  _renderContent(props) {
    const child = React.Children.only(props.children);
    renderSubtreeIntoContainer(this, child, this._node);
  }

  _shouldClose(originEvent) {
    if (this.props.onShouldClose) {
      // We use a timeout to allow the event to bubble to other elements
      setTimeout(() => {
        if (this._node) {
          this.props.onShouldClose(originEvent);
        }
      }, 0);
    }
  }

  _handleDocumentMouseDown(event) {
    this._checkEventClickTarget(event);
  }

  _handleDocumentTouchStart(event) {
    const originalEvent = event.originalEvent || event;
    if (originalEvent.touches.length === 1) {
      this._checkEventClickTarget(originalEvent.touches.item(0), event);
    }
  }

  _checkEventClickTarget(event, originatingEvent = null) {
    if (
      (!isElementOrInside(event.target, Overlay.stackElement) || this._isCurrent()) &&
      !isElementOrInside(event.target, this._node)
    ) {
      this._shouldClose(originatingEvent || event);
    }
  }

  _handleDocumentKeyDown(event) {
    if (this._isCurrent() && event.keyCode === 27) {
      this._shouldClose(event);
    }
  }

  _handleFieldKeyDown(event) {
    if (this._isCurrent() && event.keyCode === 13) {
      this._shouldClose(event);
    }
  }

  _isCurrent() {
    const childNodes = Overlay.stackElement.childNodes;
    return this._node === childNodes[childNodes.length - 1];
  }
}

Overlay.propTypes = {
  // Called when an event, such as a click outside the flaoter, should cause
  // the floater to close.
  onShouldClose: PropTypes.func,
};
