import L from 'leaflet';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {isEmpty, isEqual} from 'underscore';
import {List} from 'immutable';
import highlighter from 'document-highlighter';
import {debounce} from 'debounce';

import {Floater} from '../Floater.react';
import {Overlay} from '../Overlay.react';
import {SelectList} from '../SelectList.react';
import GeoLocation from '../../../lib/maps/GeoLocation';
import Configuration from '../../../lib/configuration';
import Platform from '../../../lib/platform';
import {zeus} from '../../../lib/zeus';

class LocationSelector extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    geolocRequired: PropTypes.bool,
    canUseCurrentLocation: PropTypes.bool,
    geoLocation: PropTypes.object,
    onChange: PropTypes.func,
    onUncommittedChange: PropTypes.func,
    onCommittedWithEnterKey: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    placeholder: 'Type a location',
    geolocRequired: false,
    geoLocation: new GeoLocation(),
    canUseCurrentLocation: null,
  };

  constructor(props) {
    super(props);
    const geoLocation = this.props.geoLocation;
    this.state = {
      locating: false,
      text: geoLocation ? geoLocation.format() : '',
      geoLocation,
      matches: new List(),
      dirty: false,
    };
  }

  componentDidMount() {
    this._configuration = Configuration.get('location_autocompletion') || {};
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.matches.size > 0 && this.state.matches.size === 0) {
      if (this._input) {
        this._input.focus();
      }
    }

    if (!isEqual(prevState.geoLocation, this.state.geoLocation)) {
      if (this._input) {
        this._input.scrollLeft = 0;
      }
      if (this.props.onChange && (!this.props.geolocRequired || this._hasGeoLocation())) {
        this.props.onChange(this.state.geoLocation);
      }
    }

    const {onUncommittedChange} = this.props;
    if (onUncommittedChange && this.state.text !== prevState.text) {
      onUncommittedChange(this._getUncommittedLocation());
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {geoLocation} = nextProps;
    if (!isEqual(geoLocation, this.props.geoLocation)) {
      this.setState({
        geoLocation,
        text: geoLocation ? geoLocation.format() : '',
        matches: new List(),
      });
    }
  }

  _handleUseCurrentLocationClick = (event) => {
    this.setState({
      locating: true,
      text: '',
      matches: new List(),
    });

    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (!this._isMounted) {
          return;
        }
        const geo = new GeoLocation({
          isCurrentLocation: true,
          latlng: L.latLng(position.coords.latitude, position.coords.longitude),
        });
        this.setState({
          text: geo.format(),
          geoLocation: geo,
          locating: false,
        });
      },
      (err) => {
        this.setState({
          locating: false,
          text: '',
        });
        Platform.alert(`Cannot get your location.\n\n${err ? err.message : 'Unknown error.'}`);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
      }
    );

    event.preventDefault();
  };

  render() {
    const {geoLocation} = this.state;
    const canLocate = !geoLocation.isCurrentLocation && this._canUseCurrentLocation();

    return (
      <div
        className="LocationSelector"
        data-has-geolocation={geoLocation.isSet() && !this._isText()}
        data-has-shape={!!geoLocation.shape}
        data-can-locate={canLocate}
        data-can-clear={geoLocation.isSet()}
        data-is-required={this.props.geolocRequired}
        data-is-locating={this.state.locating}>
        <div className="LocationSelector_value">
          {geoLocation.isCurrentLocation ? (
            <input
              aria-label="Find Near"
              value={this.state.text}
              readOnly="true"
              disabled="true"
              ref={(node) => (this._input = node)}
            />
          ) : geoLocation.shape ? (
            <input
              aria-label="Find Near"
              value="Custom Area"
              readOnly="true"
              disabled="true"
              ref={(node) => (this._input = node)}
            />
          ) : (
            <input
              aria-label="Find Near"
              type="text"
              value={this.state.text}
              size="20"
              ref={(node) => (this._input = node)}
              placeholder={this.props.placeholder}
              onChange={this._handleInputChange}
              onKeyUp={this._handleInputKeyUp}
              onBlur={this._handleInputBlur}
            />
          )}
          <span className="LocationSelector_buttons">
            {this.props.geolocRequired && !this._hasGeoLocation() && (
              <span
                className="LocationSelector_buttons_required"
                title="Geocoded location or shape required"
              />
            )}
            {canLocate && (
              <a
                href="#"
                title="Use your current location"
                className="LocationSelector_buttons_locate"
                onClick={this._handleUseCurrentLocationClick}
              />
            )}
            {geoLocation.isSet() && (
              <a
                href="#"
                title="Clear"
                onClick={this._handleClearButtonClick}
                className="LocationSelector_buttons_clear"
              />
            )}
          </span>
        </div>
        {this.state.matches.size > 0 && (
          <Overlay onShouldClose={() => this._cancel()}>
            <Floater
              className="LocationSelector_dropdown"
              parent={() => this._input}
              offset={[-4, -5]}
              keepWithinViewport={false}>
              <SelectList
                format={this._formatItem}
                items={this.state.matches && this.state.matches.toJS()}
                parentInput={this._input}
                limit={10}
                onSelectionChange={this._handleSelectionChange}
              />
            </Floater>
          </Overlay>
        )}
      </div>
    );
  }

  _formatItem = (item) => {
    const text = this._input && this._input.value.trim();
    const label = item.formatted_address;
    if (text && text.trim().length > 0) {
      const highlighted = highlighter.text(label, text);
      return <a dangerouslySetInnerHTML={{__html: highlighted.text}} />;
    }
    return <a>{label}</a>;
  };

  _isText() {
    return (this.state.geoLocation.fulltext || '').length > 0;
  }

  _getUncommittedLocation() {
    if (this.state.dirty && this._input) {
      const text = this._input.value.trim();
      const geoLocation = new GeoLocation();
      if (/^([0-9-]+)$/.test(text)) {
        geoLocation.zipCode = text;
      } else if (/^([0-9-]+,?\s*)+$/.test(text)) {
        geoLocation.zipCodes = text.split(/,\s*/);
      } else if (text.length > 0) {
        geoLocation.fulltext = text;
      }
      return geoLocation;
    }
  }

  _cancel() {
    this.setState({
      matches: new List(),
    });
  }

  commitText = (cb) => {
    const geoLocation = this._getUncommittedLocation();
    if (geoLocation) {
      this.setState({
        dirty: false,
        matches: new List(),
        geoLocation,
      });
    }
    if (typeof cb === 'function') {
      cb();
    }
  };

  _handleInputBlur = () => {
    // Delay so that select list handler has a chance to kick in
    window.setTimeout(this.commitText, 200);
  };

  _handleInputKeyUp = (event) => {
    this._handleInputChange();

    const text = this._input.value;
    if (event.keyCode === 13) {
      event.preventDefault();
      this.commitText(this.props.onCommittedWithEnterKey);
    } else if (text !== this.state.text) {
      this.setState({text, dirty: true});
    }
  };

  _handleInputChange = () => {
    const text = this._input.value;
    if (text !== this.state.text) {
      this.setState({text, dirty: true}, () => {
        this._geocode();
      });
    }
  };

  _geocode = debounce(async () => {
    const text = (this.state.text || '').trim();
    if (text.length === 0) {
      return;
    }
    const data = await zeus(`/api/geocode?q=${encodeURIComponent(text)}`);
    const {matches} = data;

    if (!this.state.dirty) {
      // Ignore results if we've been modified since we started requests
      return;
    }

    this.setState({
      matches: new List(matches),
    });
  }, 200);

  _handleSelectionChange = (item) => {
    const geoLocation = this._matchToGeoLocation(item);
    this.setState({
      matches: new List(),
      dirty: false,
      geoLocation,
      text: geoLocation.format(),
    });
  };

  _matchToGeoLocation(result) {
    const geo = new GeoLocation();
    geo.streetAddress = result.street_address;
    geo.city = result.city;
    geo.state = result.state;
    geo.zipCode = result.zip_code;
    geo.country = result.country;
    if (result.location) {
      geo.latlng = L.latLng(result.location.lat, result.location.lng);
    }
    if (result.bounds) {
      const ne = result.bounds.northeast;
      const sw = result.bounds.southwest;
      geo.bounds = L.latLngBounds(L.latLng(ne.lat, ne.lng), L.latLng(sw.lat, sw.lng));
    }
    return geo;
  }

  _handleClearButtonClick = (event) => {
    event.preventDefault();
    this.setState({
      geoLocation: new GeoLocation(),
      text: '',
      matches: new List(),
    });
  };

  _canUseCurrentLocation() {
    if (!(navigator.geolocation && navigator.geolocation.getCurrentPosition)) {
      return false;
    }

    if (this.props.canUseCurrentLocation !== null) {
      return this.props.canUseCurrentLocation === true;
    } else {
      // Default to true unless disabled
      return Configuration.get('location_autocompletion.current_location_search') !== false;
    }
  }

  _hasGeoLocation() {
    return (
      this.state.geoLocation.latlng != null ||
      !isEmpty(this.state.geoLocation.shape) ||
      this.state.geoLocation.territory ||
      this.state.geoLocation.fulltext
    );
  }
}

export default connect()(LocationSelector);
