import React from 'react';
import PropTypes from 'prop-types';
import {compact, flatten} from 'underscore';

import {SelectListButton} from '../../SelectListButton.react';
import {Model} from '../../../../lib/models';
import ListingQuery from '../../../../lib/search/ListingQuery';
import {
  getStartRangeFacetAsItems,
  getEndRangeFacetAsItems,
  getFacetAsItems,
} from '../../../../lib/support/search';
import FilterDropdownItem from './FilterDropdownItem';

export default function GenericFacetFilter({
  attrName,
  facetName,
  rangeType,
  rangeKey,
  mutex,
  notResettable,
  ignoreEmpty,
  ignoreKeys,
  onlyKeys,
  query,
  facets,
  normalizer,
  onUpdateQuery,
  unsetLabel,
  multiSelect,
  items,
  formatter,
  zIndex,
}) {
  if (items == null) {
    switch (rangeType) {
      case 'start': {
        const range = query.getRange(attrName);
        items = getStartRangeFacetAsItems(facets[`${attrName}_min`], {
          max: range.max,
          includeAny: !notResettable,
          formatter,
        });
        break;
      }
      case 'end': {
        const range = query.getRange(attrName);
        items = getEndRangeFacetAsItems(facets[`${attrName}_max`], {
          min: range.min,
          includeAny: !notResettable,
          formatter,
        });
        break;
      }
      default: {
        const getOptions = {
          mutex,
          ignoreEmpty,
          ignoreKeys,
          onlyKeys,
          formatter,
          includeAny: !notResettable,
        };
        if (rangeKey != null) {
          getOptions.range = facets[facetName || rangeKey];
        }
        items = getFacetAsItems(facets[facetName || attrName], getOptions);
      }
    }
  }

  // FIXME: This lookup is inefficient and not good
  const selection = (() => {
    let needle = query.get(attrName);
    if (needle instanceof Model) {
      needle = [needle.id];
      return items.filter((item) => item.value != null && needle === item.value);
    }
    const needles = compact(flatten([needle]));
    return items.filter(
      (item) => item.value != null && needles.indexOf(normalizer(item.value)) !== -1
    );
  })();

  return (
    <SelectListButton
      labelFormat={(item) => (item && item.label) || null}
      itemFormat={FilterDropdownItem}
      unsetLabelFormat={unsetLabel || 'Any'}
      items={items}
      selectedItems={selection}
      multiSelect={multiSelect}
      onChange={
        multiSelect
          ? (selected) =>
              onUpdateQuery((q) =>
                q.set(
                  attrName,
                  selected.map((i) => i.value)
                )
              )
          : (selected) => onUpdateQuery((q) => q.set(attrName, selected.value))
      }
      zIndex={zIndex}
    />
  );
}

GenericFacetFilter.defaultProps = {
  mutex: false,
  multiSelect: false,
  ignoreEmpty: false,
  notResettable: false,
  normalizer(v) {
    return v;
  },
};

GenericFacetFilter.propTypes = {
  query: PropTypes.instanceOf(ListingQuery).isRequired,
  facets: PropTypes.object.isRequired,
  onUpdateQuery: PropTypes.func.isRequired,
  normalizer: PropTypes.func,
  attrName: PropTypes.string.isRequired,
  facetName: PropTypes.string,
  rangeKey: PropTypes.string,
  unsetLabel: PropTypes.string,
  rangeType: PropTypes.oneOf(['start', 'end']),
  formatter: PropTypes.func,
  mutex: PropTypes.bool,
  multiSelect: PropTypes.bool,
  onlyKeys: PropTypes.arrayOf(PropTypes.string),
  ignoreEmpty: PropTypes.bool,
  ignoreKeys: PropTypes.arrayOf(PropTypes.string),
  notResettable: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  zIndex: PropTypes.number,
};
