import * as React from 'react';
import * as PropTypes from 'prop-types';

import styles from './Badge.module.scss';

export const Badge = ({children, kind}) => (
  <div className={styles.root} data-kind={kind}>
    {children}
  </div>
);

Badge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  kind: PropTypes.oneOf(['normal', 'prominent']),
};

Badge.defaultProps = {
  kind: 'normal',
};
