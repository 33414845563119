import {find} from 'underscore';

import Model from './Model';
import Broker from './Broker';
import {slugify} from '../utils/urls';

export default class Agent extends Model {
  static TYPES = ['for_rent', 'for_sale'];

  constructor(attributes = null) {
    if (attributes) {
      delete attributes.nuid; // Conflicts with getter
    }
    super(attributes);
    if (this.broker && !(this.broker instanceof Broker)) {
      this.broker = new Broker(this.broker);
    }
  }

  // Returns numeric part of UID. Backwards compatibility with Grove UIDs.
  get nuid() {
    const match = /^post.agent:.*\$(\d+)$/.exec(this.id);
    return match ? match[1] : this.id;
  }

  slugify() {
    return slugify(this.name);
  }

  hasContactInformation() {
    return !!find([this.phone, this.email], function (value) {
      return value && `${value}`.trim().length > 0;
    });
  }
}
