import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import styles from './IconButton.module.scss';

export const IconButton = connect()(
  class IconButton extends React.PureComponent {
    static propTypes = {
      icon: PropTypes.func,
      iconAfter: PropTypes.func,
      children: PropTypes.node,
      size: PropTypes.number,
      href: PropTypes.string,
      onClick: PropTypes.func,
      dispatch: PropTypes.func.isRequired,
    };

    static defaultProps = {
      size: 30,
    };

    render() {
      const {icon: Icon, iconAfter: IconAfter, size} = this.props;
      return (
        <a className={styles.root} href={this.props.href} onClick={this._handleClick} tabIndex="">
          {Icon && (
            <span className={styles.ib}>
              <Icon size={size} />
            </span>
          )}
          {this.props.children && <span className={styles.l}>{this.props.children}</span>}
          {IconAfter && (
            <span className={styles.ia}>
              <IconAfter size={size} />
            </span>
          )}
        </a>
      );
    }

    _handleClick = (event) => {
      if (this.props.onClick) {
        event.preventDefault();
        this.props.onClick();
      }
    };
  }
);
