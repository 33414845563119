import React, {useEffect, useState} from 'react';

import styles from './HeadlinesTile.module.css';
import Configuration from '../../../lib/configuration';
import {zeus} from '../../../lib/zeus';

const HeadlinesTile = () => {
  const [links, setLinks] = useState([]);
  useEffect(() => {
    let cancelled = false;
    (async () => {
      const siteChecksum = Configuration.getSiteChecksum();
      const resp = await zeus(`/api/headlines?c=${encodeURIComponent(siteChecksum)}`);
      if (!cancelled) {
        const newLinks = resp.headlines || [];
        setLinks(newLinks);
      }
    })();
    return () => {
      cancelled = true;
    };
  }, []);
  return (
    <div className={styles.root}>
      <div className={styles.title}>Real Estate News</div>
      <ul className={styles.links}>
        {links.slice(0, 3).map((link, idx) => (
          <li key={idx}>
            <a href={link.link}>
              <p>{link.title}</p>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeadlinesTile;
