import React from 'react';
import PropTypes from 'prop-types';

import {ScheduledFunction} from '../../lib/node_utils';

export default class UpdateOnMount extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  componentDidMount() {
    this.mounted = true;
    ScheduledFunction.wrap(this.forceUpdate.bind(this)).debounce(100);
  }

  componentWillUnmount() {
    this.mounted = false;
    ScheduledFunction.wrap(this.forceUpdate.bind(this)).stop();
  }

  render() {
    return this.mounted ? this.props.children : <span />;
  }
}
