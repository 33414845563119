import React from 'react';
import PropTypes from 'prop-types';

import ShareListingForm from '../ShareListingForm';
import ShareListing from './ShareListing';

export default class ListingSocialButtons extends React.Component {
  static propTypes = {
    listing: PropTypes.object.isRequired,
  };

  state = {
    showEmailForm: false,
  };

  render() {
    const {listing} = this.props;
    return (
      <div className="ListingSocialButtons">
        <ShareListing
          layout="compact"
          listing={listing}
          onEmailButtonClick={() =>
            this.setState({
              showEmailForm: !this.state.showEmailForm,
            })
          }
        />
        {this.state.showEmailForm && <ShareListingForm listing={listing} />}
      </div>
    );
  }
}
