import React from 'react';
import PropTypes from 'prop-types';

export class ListingTabViewItem extends React.Component {
  static propTypes = {
    title: PropTypes.node,
    icon: PropTypes.string,
    children: PropTypes.node,
    default: PropTypes.bool,
    right: PropTypes.bool,
    stretch: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    default: false,
    right: false,
    stretch: false,
    disabled: false,
  };

  render() {
    return <div>{this.props.children}</div>;
  }
}

export class ListingTabView extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.bool])).isRequired,
  };

  state = {
    currentIndex: this._getInitialIndex(),
  };

  _getInitialIndex() {
    const i = this.props.items.findIndex((item) => item && item.props.default);
    return i >= 0 ? i : 0;
  }

  render() {
    const {currentIndex} = this.state;
    const {items} = this.props;
    return (
      <div className="ListingTabView">
        <div className="ListingTabView_tabs">
          {items.map((item, idx) =>
            item ? (
              <div
                className="ListingTabView_tab"
                key={idx}
                data-active={idx === currentIndex}
                data-disabled={item.props.disabled}
                data-right={item.props.right}
                data-stretch={item.props.stretch}
                data-icon={item.props.icon}
                onClick={item.props.disabled ? null : (event) => this._handleTabClick(event, idx)}>
                <div className="ListingTabView_tab_title">{item.props.title}</div>
              </div>
            ) : null
          )}
        </div>
        <div className="ListingTabView_content">{items[currentIndex]}</div>
      </div>
    );
  }

  _handleTabClick(event, index) {
    event.preventDefault();
    this.setState({currentIndex: index});
  }
}
