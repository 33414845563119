import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {analyticsEvent} from '../../../lib/actions';
import {Listing} from '../../../lib/models';
import {urlForAgent} from '../../../lib/support/routing';
import Platform from '../../../lib/platform';
import EmailLink from '../common/EmailLink';
import PhoneLink from '../common/PhoneLink';

export default connect()(
  withRouter(
    class AgentDetails extends React.Component {
      static propTypes = {
        dispatch: PropTypes.func.isRequired,
        location: PropTypes.shape({
          search: PropTypes.string,
        }).isRequired,
        agent: PropTypes.object,
        showBiography: PropTypes.bool,
        truncateBiography: PropTypes.bool,
        showLink: PropTypes.bool,
        type: PropTypes.oneOf(Listing.TYPES),
      };

      static defaultProps = {
        showLink: false,
        showBiography: true,
        truncateBiography: false,
      };

      render() {
        const {agent} = this.props;

        const [websiteUrl, websiteTitle] = getUrlAndTitleForWebsite(agent);

        return (
          <div className="AgentDetails">
            <div className="AgentDetails_content">
              <div className="AgentDetails_content_name">
                <div className="AgentDetails_content_name_name">
                  {this.props.showLink ? (
                    <a
                      href={urlForAgent(agent, this.props.type, this.props.location.search)}
                      onClick={(event) => this._handleLinkClick(event)}>
                      {agent.name}
                    </a>
                  ) : (
                    agent.name
                  )}
                </div>
              </div>
              <ul className="AgentDetails_content_infos">
                {agent.phone && (
                  <li data-item-type="phone">
                    <PhoneLink
                      number={agent.phone}
                      type="phone"
                      extension={agent.phone_extension}
                      onShow={() =>
                        this.props.dispatch(
                          analyticsEvent('clickedAgentClickToCallButton', {agent})
                        )
                      }
                    />
                  </li>
                )}
                {agent.fax && (
                  <li data-item-type="fax">
                    <PhoneLink
                      number={agent.fax}
                      type="fax"
                      onShow={() =>
                        this.props.dispatch(
                          analyticsEvent('clickedAgentClickToCallButton', {agent})
                        )
                      }
                    />
                  </li>
                )}
                {agent.email && (
                  <li data-item-type="email">
                    <EmailLink
                      email={agent.email}
                      onShow={() =>
                        this.props.dispatch(analyticsEvent('clickedAgentShowEmail', {agent}))
                      }
                    />
                  </li>
                )}
                {websiteUrl && (
                  <li data-item-type="web">
                    <a href={websiteUrl} target="_blank">
                      {websiteTitle}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        );
      }

      _handleLinkClick(event) {
        event.preventDefault();
        Platform.openExternalUrl(
          urlForAgent(this.props.agent, this.props.type, this.props.location.search),
          '_top'
        );
      }
    }
  )
);

function getUrlAndTitleForWebsite(agent) {
  const url = agent.website;
  if (!url) {
    return [];
  }

  let title;
  const match = /https?:\/\/(www\.)?([^/]+)/.exec(url);
  if (match) {
    title = match[2];
  } else {
    title = 'Agent web site';
  }
  return [url, title];
}
