export function lookupObjectKeyPath(path, data) {
  const match = /^([^.]+)\.(.*)$/.exec(path);
  if (!match) {
    return data[path];
  }
  const key = match[1],
    rest = match[2],
    value = data[key];
  if (value) {
    return lookupObjectKeyPath(rest, value);
  }
  return null;
}
