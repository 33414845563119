import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import PropTypes from 'prop-types';

/**
 * A high-level component that transmits the dimensions of the parent node
 * to the child. The child prop must be a function that will receive a single
 * argument {width, height}.
 */
export default class Sizer extends React.Component {
  static propTypes = {
    child: PropTypes.func.isRequired,
    onChange: PropTypes.func,
  };

  state = {
    rect: {
      left: 0,
      top: 0,
      width: 0,
      height: 0,
    },
  };

  _observer = new ResizeObserver(() => this._update());

  componentDidMount() {
    this._mounted = true;

    // We also hook scrolling in order to track the top/left components of
    // the node rect.
    window.addEventListener('scroll', this._update);
  }

  componentWillUnmount() {
    this._mounted = false;
    window.removeEventListener('scroll', this._update);
    this._observer.disconnect();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.onChange && !isRectEqual(prevState.rect, this.state.rect)) {
      this.props.onChange(this.state.rect);
    }
  }

  render() {
    return <div ref={this._setRef}>{this.props.child(this.state.rect)}</div>;
  }

  _setRef = (node) => {
    this._node = node;
    if (this._node && this._node.parentNode) {
      this._observer.observe(this._node.parentNode);
    }
    window.requestAnimationFrame(() => this._update());
  };

  _update = () => {
    if (this._node && this._node.parentNode) {
      const rect = this._node.parentNode.getBoundingClientRect();
      this.setState(() => ({rect}));
    }
  };
}

function isRectEqual(a, b) {
  return (
    a && b && a.left === b.left && a.top === b.top && a.width === b.width && a.height === b.height
  );
}
