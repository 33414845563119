import L from 'leaflet';
import Configuration from '../configuration';

let streets;
let satelite;

export function streetsTileLayer() {
  if (streets) {
    return streets;
  }
  const urlTemplate = Configuration.get('ui.map.maptile_url_template')
    .replace('{map_id}', 'streets')
    .replace('{tile_size}', '512')
    .replace('{ext}', 'png');
  streets = L.tileLayer(urlTemplate, {
    tileSize: 512,
    zoomOffset: -1,
    minZoom: 1,
    attribution:
      '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
  });
  return streets;
}

export function satelliteTileLayer() {
  if (satelite) {
    return satelite;
  }
  const urlTemplate = Configuration.get('ui.map.maptile_url_template')
    .replace('{map_id}', 'hybrid')
    .replace('{tile_size}', '512')
    .replace('{ext}', 'jpg');
  satelite = L.tileLayer(urlTemplate, {
    tileSize: 512,
    zoomOffset: -1,
    minZoom: 1,
    attribution:
      '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
  });
  return satelite;
}
