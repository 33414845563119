import {isEqual, compact, isEmpty, find, sortBy, isNaN} from 'underscore';
import {List, Set} from 'immutable';
import moment from 'moment';
import L from 'leaflet';

import {isTrue} from '../node_utils';
import {
  serializeQueryString,
  deserializeQueryString,
  serializeNumberArray,
  serializeStringArray,
  deserializeStringArray,
  deserializeNumberArray,
} from '../support/simpleQueryStrings';
import GeoLocation from '../maps/GeoLocation';
import Listing from '../models/Listing';
import Configuration from '../configuration';
import {parseGeoJSONPolygon} from '../utils/geo';
import {NEW_LISTING_DATE_ITEMS} from '../support/search';
import {Territory} from '../models';
import {zeus} from '../zeus';

export const TYPE_DESCRIPTIONS = {
  for_rent: 'For rent',
  for_sale: 'For sale',
};

export const DEFAULT_ORDER = [
  'location',
  'property_type',
  'price',
  'rent',
  'bedrooms',
  'bathrooms',
  'square_footage',
  'lots',
  'has_photos',
  'has_videos',
];

export const DEFAULTS = {
  order: DEFAULT_ORDER,
  type: 'for_sale',
  isCurrentLocation: false,
  page: 1,
};

export class ListingSearchResult {
  static fromObject({listing, exact, highlighting}) {
    const result = new ListingSearchResult();
    result.listing = new Listing(listing);
    result.exact = exact;
    result.sponsored = false;
    result.highlighting = {};
    if (highlighting) {
      highlighting.forEach(({fieldName, htmlFragments}) => {
        result.highlighting[fieldName] = htmlFragments;
      });
    }
    return result;
  }

  hasExactOrFuzzyState() {
    return this.exact != null;
  }

  isExactMatch() {
    return this.exact === true;
  }

  isFuzzyMatch() {
    return this.exact === false;
  }
}

export class ListingSearchResultSet {
  static fromObject(data) {
    const resultSet = new ListingSearchResultSet();
    if (data) {
      resultSet.results = new List(data.results).map(ListingSearchResult.fromObject);
      if (data.facets) {
        resultSet.facets = data.facets;
      } else if (data.engineFacetsJSON) {
        resultSet.facets = JSON.parse(data.engineFacetsJSON);
      }
      resultSet.exactCount = data.exactCount;
      resultSet.page = data.page;
      resultSet.maxPage = data.maxPage;
    }
    return resultSet;
  }

  constructor() {
    this.results = new List();
  }

  get size() {
    return this.results.size;
  }

  get listings() {
    return this.results.map(({listing}) => listing);
  }

  findNext(listing) {
    return this._findRelative(listing, 1);
  }

  findPrevious(listing) {
    return this._findRelative(listing, -1);
  }

  _findRelative(listing, offset) {
    const listings = this.listings;
    const index = listings.map((m) => m.id).indexOf(listing.id);
    if (index !== -1) {
      const nextIndex = index + offset;
      if (nextIndex >= 0 && nextIndex < listings.size) {
        return listings.get(nextIndex);
      }
    }
    return null;
  }
}

export class ListingSearchResultFacets {
  static fromObject(data) {
    const resultSet = new ListingSearchResultFacets();
    if (data) {
      if (data.facets) {
        resultSet.facets = data.facets;
      } else if (data.engineFacetsJSON) {
        resultSet.facets = JSON.parse(data.engineFacetsJSON);
      }
    }
    return resultSet;
  }

  facets = {};

  getFacet(name) {
    const {facets} = this;
    if (facets && facets[name] && facets[name].data) {
      return facets[name];
    }
    return null;
  }
}

const resetProtectedSearchAttributes = Set([
  'addressQuery',
  'bounds',
  'city',
  'country',
  'county',
  'isCurrentLocation',
  'latlng',
  'shape',
  'state',
  'street_address',
  'territory',
  'type',
  'zip_code',
  'zip_codes',
]);

export const searchAttributes = Set([
  ...resetProtectedSearchAttributes,
  'agent_uid',
  'bathrooms',
  'bathrooms_value',
  'bedrooms',
  'bedrooms_value',
  'bounding_area',
  'broker_uid',
  'created_or_updated_since',
  'exclude_features',
  'featured_only',
  'feed_uid',
  'has_photos',
  'has_videos',
  'has_virtual_tours',
  'listing_id',
  'lots_max',
  'lots_min',
  'lots_value_max',
  'lots_value_min',
  'nearest_schools',
  'not_featured',
  'open_house_dates',
  'open_house_only',
  'order',
  'page',
  'price_max',
  'price_min',
  'price_value_max',
  'price_value_min',
  'property_type',
  'property_type_only',
  'publishing_timeframe',
  'rent_max',
  'rent_min',
  'rent_value_max',
  'rent_value_min',
  'required_features',
  'section',
  'square_footage_max',
  'square_footage_min',
  'square_footage_value',
  'square_footage_value_max',
  'square_footage_value_min',
  'status',
  'subdivision',
  'text',
  'upsells',
  'year_built',
]);

const multiValuedsearchAttributes = Set([
  'exclude_features',
  'order',
  'property_type',
  'required_features',
  'status',
  'zip_codes',
]);

export default class ListingQuery {
  static getListingTypes() {
    return (
      Configuration.get('ui.search.show_listing_types') || [ListingQuery.getDefaultListingType()]
    );
  }

  static getDefaultListingType() {
    const types = Configuration.get('ui.search.show_listing_types');
    if (types) {
      return types[0];
    } else {
      return 'for_sale';
    }
  }

  static newSimilarityQuery(listing) {
    const latLng = listing.getLatLng();
    const size = listing.size;
    return new ListingQuery({
      bedrooms_value: (listing.bedrooms || {}).normalized_count,
      bathrooms_value: (listing.bathrooms || {}).full_count,
      property_type: listing.property_type,
      price_value_min: listing.price,
      price_value_max: listing.price,
      rent_value_min: listing.rent,
      rent_value_max: listing.rent,
      square_footage_value: size ? size.square_footage : null,
      latlng: latLng,
      type: listing.type,
      city: listing.city,
      state: listing.state,
      country: listing.country,
      zip_code: listing.zip_code,
    });
  }

  static getEmptyListingQueryForAgent(agent) {
    const query = new ListingQuery({
      agent_uid: agent.id,
    });
    switch (agent.role) {
      case 'rental_agent':
        query.set('type', 'for_rent');
        break;
      default:
        query.set('type', 'for_sale');
        break;
    }
    return query;
  }

  static getEmptyListingQueryForBroker(broker, {listingType} = {}) {
    const query = new ListingQuery({
      broker_uid: broker.id,
    });
    if (listingType) {
      query.set('type', listingType);
    } else {
      switch (broker.role) {
        case 'rental_broker':
          query.set('type', 'for_rent');
          break;
        default:
          query.set('type', 'for_sale');
          break;
      }
    }
    return query;
  }

  constructor(attributes) {
    this._attributes = attributes ? Object.assign({}, attributes) : {};
  }

  clone() {
    return new ListingQuery(this._attributes);
  }

  // Check if query is equal to another. Slightly faster than
  // calling `isEqual()`.
  equals(other) {
    return other instanceof ListingQuery && isEqual(this._attributes, other._attributes);
  }

  // REMOVEME: For backwards compatibility. Use equals().
  isEqual(other) {
    return this.equals(other);
  }

  // Overrides AbstractQuery
  _getDefaults() {
    return Object.assign({}, DEFAULTS, {
      section: Configuration.get('ui.search.sections.default'),
      status: Configuration.get('ui.search.status_filter.default'),
    });
  }

  // Overrides AbstractQuery
  _validateAttribute(key, value) {
    switch (key) {
      case 'type':
        return Listing.TYPES.indexOf(value) !== -1;
      default:
        // TODO: Validate more stuff here
        return true;
    }
  }

  _normalizeAttribute(key, value) {
    switch (key) {
      // Booleans
      case 'isCurrentLocation':
      case 'has_photos':
      case 'has_videos':
      case 'has_virtual_tours':
        switch (value) {
          case true:
          case 'true':
            return true;
          case false:
          case 'false':
            return false;
        }
        return null;
      // Convert all valid numbers to strings and invalid ones to null
      case 'lots_min':
      case 'lots_max':
      case 'page':
      case 'price_min':
      case 'price_max':
      case 'rent_min':
      case 'rent_max':
      case 'square_footage_min':
      case 'square_footage_max':
        switch (typeof value) {
          case 'string':
            if (isNaN(parseFloat(value))) {
              value = null;
            }
            break;
          case 'number':
            value = `${value}`;
            break;
          default:
            value = null;
        }
        break;
    }
    // Everything else is passed through
    return value;
  }

  // Returns a mutable copy of this query, containing only attributes
  // that contribute to exact criteria, ie. which criteria influence
  // if a result is exact or not.
  getExactCriteria() {
    const attributes = Object.assign({}, this._attributes);
    delete attributes.section;
    delete attributes.isCurrentLocation;
    delete attributes.latlng;
    delete attributes.type;
    delete attributes.order;
    return new ListingQuery(attributes);
  }

  asObject() {
    return Object.assign({}, this._attributes);
  }

  // Returns true if any attributes have been set that will affect
  // whether any exact matches will be returned.
  hasExactCriteria() {
    return Object.keys(this.getExactCriteria()._attributes).length > 0;
  }

  canReset() {
    for (const name of Object.keys(this._attributes)) {
      if (resetProtectedSearchAttributes.contains(name)) {
        continue;
      }
      if (searchAttributes.contains(name)) {
        return true;
      }
    }
    return false;
  }

  reset() {
    for (const name of searchAttributes) {
      if (resetProtectedSearchAttributes.contains(name)) {
        continue;
      }
      delete this._attributes[name];
    }
  }

  // Returns list of names of attributes that have been set in this query.
  modifiedAttributeNameSet() {
    return new Set(Object.keys(this._attributes));
  }

  isSet(key) {
    return key.substring(0, 1) !== '_' && !isEmptyValue(this.get(key));
  }

  getOrderables() {
    return DEFAULT_ORDER.filter((key) => {
      switch (key) {
        case 'bedrooms':
          return this.isSet('bedrooms') || this.isSet('bedrooms_value');
        case 'bathrooms':
          return this.isSet('bathrooms') || this.isSet('bathrooms_value');
        case 'price':
          return (
            this.isSet('price_min') ||
            this.isSet('price_max') ||
            this.isSet('price_value_min') ||
            this.isSet('price_value_max')
          );
        case 'rent':
          return (
            this.isSet('rent_min') ||
            this.isSet('rent_max') ||
            this.isSet('rent_value_min') ||
            this.isSet('rent_value_max')
          );
        case 'lots':
          return (
            this.isSet('lots_min') ||
            this.isSet('lots_max') ||
            this.isSet('lots_value_min') ||
            this.isSet('lots_value_max')
          );
        case 'square_footage':
          return (
            this.isSet('square_footage_min') ||
            this.isSet('square_footage_max') ||
            this.isSet('square_footage_value_min') ||
            this.isSet('square_footage_value_max')
          );
        case 'location':
          return (
            this.isSet('shape') ||
            this.isSet('latlng') ||
            this.isSet('city') ||
            this.isSet('zip_code') ||
            this.isSet('zip_codes') ||
            this.isSet('addressQuery')
          );
      }
      return this.isSet(key);
    });
  }

  canOrder() {
    return this.getOrderables().length > 1;
  }

  getOrder() {
    return this.get('order') || DEFAULT_ORDER;
  }

  setOrder(keys) {
    this.set(
      'order',
      sortBy(DEFAULT_ORDER, (key) => {
        const index = keys.indexOf(key);
        return index !== -1 ? index : Infinity;
      })
    );
  }

  clear() {
    this._attributes = {};
  }

  // Get an attribute.
  get(key) {
    return this._attributes[key] || this._getDefaults()[key] || null;
  }

  set(key, val) {
    if (key === 'type' && val !== this.get(key)) {
      this.set('property_type', null);
    }

    const attrs = {};
    if (typeof key === 'object') {
      Object.assign(attrs, key);
    } else {
      attrs[key] = val;
    }

    const defaults = this._getDefaults();

    // eslint-disable-next-line prefer-const
    for (let [k, v] of Object.entries(attrs)) {
      let keep = false;
      if (!isEmptyValue(v)) {
        if (this._validateAttribute(k, v)) {
          v = this._normalizeAttribute(k, v);
          if (!(v == null || isEqual(v, defaults[k]))) {
            keep = true;
          }
        }
      }
      if (keep) {
        this._attributes[k] = v;
      } else {
        delete this._attributes[k];
      }
    }
    return this;
  }

  toGraphQL() {
    const q = {
      filter: {
        location: {},
      },
      rank: {
        location: {},
      },
      order: this.getOrder(),
    };

    // If nothing important is set, sort by time
    if (this._shouldBoostUpdateTime()) {
      q.rank.newlyUpdated = true;
    }

    const feedId = this.get('feed_uid');
    if (feedId) {
      q.filter.feed = {ids: [feedId]};
    }

    const county = this.get('county');
    if (county) {
      q.filter.location.county = [county];
    }

    const boundingArea = parseGeoJSONPolygon(this.get('bounding_area'));
    if (boundingArea) {
      q.filter.location.area = boundingArea.map(([lat, lng]) => ({lat, lng}));
    }

    const type = this.get('type') || ListingQuery.getDefaultListingType();
    if (type) {
      q.filter.type = [type];
    }

    const section = this.get('section');
    if (section) {
      q.filter.section = [section];
    }

    const status = this.get('status');
    if (status) {
      q.filter.status = status;
    }

    const requiredFeatures = this.get('required_features');
    if (requiredFeatures) {
      q.filter.requiredFeatures = requiredFeatures;
    }

    const excludeFeatures = this.get('exclude_features');
    if (excludeFeatures) {
      q.filter.excludeFeatures = excludeFeatures;
    }

    const agentId = this.get('agent_uid');
    if (agentId) {
      q.filter.agent = {ids: [agentId]};
    }

    const brokerId = this.get('broker_uid');
    if (brokerId) {
      q.filter.broker = {ids: [brokerId]};
    }

    const featureIds = Configuration.get('ui.search.show_feature_ids');
    if (featureIds) {
      q.rank.features = featureIds;
    }

    q.rank.squareFootage = {
      facets: this.getSquareFootageRange(),
      value: this.getRange('square_footage_value'),
    };

    q.rank.lots = {
      facets: this.getLotsRange(),
      value: this.getRange('lots_value'),
    };

    if (type === 'for_sale') {
      q.rank.price = {facets: this.getPriceRange(), value: this.getRange('price_value')};
    } else {
      q.rank.rent = {facets: this.getRentRange(), value: this.getRange('rent_value')};
    }

    const territory = this.get('territory');
    if (territory) {
      q.rank.location.territory = territory.id;
    }

    const upsells = this.get('upsells');
    if (upsells) {
      q.filter.upsells = upsells;
    }

    if (isTrue(this.get('not_featured'))) {
      q.filter.featured = 'exclude';
    } else if (isTrue(this.get('featured_only'))) {
      q.filter.featured = 'only';
    } else {
      q.rank.featured = true;
    }

    const publishingTimeframe = this.get('publishing_timeframe');
    if (publishingTimeframe) {
      const newListingStartTime = find(
        NEW_LISTING_DATE_ITEMS,
        (item) => item.value === publishingTimeframe
      );
      q.filter.publishTime = {
        min: moment(
          newListingStartTime && newListingStartTime.fn && newListingStartTime.fn()
        ).format('YYYY-MM-DD[T]HH:mm:ssZ'),
      };
    }

    let openHouseMode = this.get('open_house_dates');
    if (openHouseMode) {
      if (Array.isArray(openHouseMode)) {
        openHouseMode = openHouseMode[0];
      }

      const start = Listing.getUpcomingOpenHouseStartingTime();

      let end;
      switch (openHouseMode) {
        case 'today':
          end = moment(start).startOf('day').add(1, 'days');
          break;
        case 'today_or_tomorrow':
          end = moment(start).startOf('day').add(2, 'days');
          break;
        case 'next_7_days':
          end = moment(start).startOf('day').add(7, 'days');
          break;
        case 'next_14_days':
          end = moment(start).startOf('day').add(14, 'days');
          break;
        case 'next_30_days':
          end = moment(start).startOf('day').add(30, 'days');
          break;
      }
      if (end) {
        q.filter.openHouseDate = {min: start, max: end};
      }
    } else if (isTrue(this.get('open_house_only'))) {
      q.filter.openHouseDate = {min: Listing.getUpcomingOpenHouseStartingTime()};
    }

    if (this.isSet('has_photos')) {
      q.rank.hasPhotos = isTrue(this.get('has_photos'));
    }
    if (this.isSet('has_videos')) {
      q.rank.hasVideos = isTrue(this.get('has_videos'));
    }
    if (this.isSet('has_virtual_tours')) {
      q.filter.hasVirtualTours = isTrue(this.get('has_virtual_tours'));
    }

    const listingId = this.get('listing_id');
    if (listingId) {
      q.filter.listingId = [String(listingId).trim()];
    }

    const yearBuilt = this.get('year_built');
    if (yearBuilt) {
      q.filter.yearBuilt = parseInt(yearBuilt);
    }

    const shape = this.get('shape');
    if (shape != null && shape.length > 0) {
      q.rank.location.area = shape.map(([lat, lng]) => ({lat, lng}));
    }

    const latLng = this.get('latlng');
    if (latLng) {
      q.rank.location.latLng = {lat: latLng.lat, lng: latLng.lng};
    }

    q.rank.location.streetAddress = this.get('street_address');
    q.rank.location.city = this.get('city');
    q.rank.location.state = this.get('state');
    q.rank.location.zipCode = this.get('zip_code');
    if (this.isSet('zip_codes')) {
      q.rank.location.zipCode = this.get('zip_codes');
    }
    q.rank.location.address = this.get('addressQuery');

    let propertyType = this.get('property_type');
    if (propertyType) {
      if (!Array.isArray(propertyType)) {
        propertyType = [propertyType];
      }
      if (!!this.get('property_type_only')) {
        q.filter.propertyType = propertyType;
      } else {
        q.rank.propertyType = propertyType;
      }
    }

    const text = this.get('text');
    if (text && text.trim() !== '') {
      q.rank.text = text;
    }

    const nearest_schools = this.get('nearest_schools');
    if (nearest_schools && nearest_schools.trim() !== '') {
      q.rank.nearest_schools = nearest_schools;
    }

    const subdivision = this.get('subdivision');
    if (subdivision && subdivision.trim() !== '') {
      q.rank.subdivision = subdivision;
    }

    q.rank.bedrooms = {
      facet: this.get('bedrooms'),
      value: this.get('bedrooms_value'),
    };
    q.rank.bathrooms = {
      facet: this.get('bathrooms'),
      value: this.get('bathrooms_value'),
    };

    const createdOrUpdatedSince = this.get('created_or_updated_since');
    if (createdOrUpdatedSince) {
      q.filter.updateTime = {
        min: moment(createdOrUpdatedSince).format('YYYY-MM-DD[T]HH:mm:ssZ'),
      };
    }

    const daysToIncludeInactiveListings = Configuration.get(
      'data.days_to_include_inactive_listings'
    );
    if (daysToIncludeInactiveListings) {
      q.filter.inactiveTime = {
        min: moment().subtract(daysToIncludeInactiveListings, 'd').format('YYYY-MM-DD[T]HH:mm:ssZ'),
      };
    }

    return q;
  }

  getPage() {
    let page = this.get('page');
    if (page) {
      page = parseInt(page);
    }
    if (!page || isNaN(page)) {
      page = 1;
    }
    return page;
  }

  setPage(page) {
    if (page > 1) {
      this.set('page', page);
    } else {
      delete this._attributes['page'];
    }
    return this;
  }

  getListingType() {
    return this.get('type') || ListingQuery.getDefaultListingType();
  }

  getRange(key) {
    return {min: this.get(`${key}_min`), max: this.get(`${key}_max`)};
  }

  getPriceRange() {
    return this.getRange('price');
  }

  getRentRange() {
    return this.getRange('rent');
  }

  getSquareFootageRange() {
    return this.getRange('square_footage');
  }

  getLotsRange() {
    return this.getRange('lots');
  }

  setGeoLocation(geo) {
    this.set({
      street_address: geo?.streetAddress,
      city: geo?.city,
      state: geo?.state,
      county: geo?.county,
      zip_code: geo?.zipCode,
      zip_codes: geo?.zipCodes,
      country: geo?.country,
      latlng: geo?.latlng,
      bounds: geo?.bounds,
      isCurrentLocation: geo?.isCurrentLocation,
      addressQuery: geo?.fulltext,
      shape: geo?.shape,
      territory: geo?.territory,
    });
  }

  getGeoLocation() {
    const streetAddress = this.get('street_address');
    const city = this.get('city');
    const state = this.get('state');
    const county = this.get('county');
    const zipCode = this.get('zip_code');
    const zipCodes = this.get('zip_codes');
    const country = this.get('country');
    const latlng = this.get('latlng');
    const bounds = this.get('bounds');
    const shape = this.get('shape');
    const isCurrentLocation = this.get('isCurrentLocation');
    const territory = this.get('territory');

    if (
      shape ||
      streetAddress ||
      city ||
      state ||
      county ||
      zipCode ||
      zipCodes ||
      country ||
      latlng ||
      bounds ||
      territory
    ) {
      return new GeoLocation({
        streetAddress,
        city,
        state,
        zipCode,
        zipCodes,
        country,
        county,
        latlng,
        bounds,
        isCurrentLocation,
        shape,
        territory,
      });
    } else {
      const addressQuery = this.get('addressQuery');
      if (addressQuery) {
        return new GeoLocation({fulltext: addressQuery});
      }
      return new GeoLocation();
    }
  }

  _shouldBoostUpdateTime() {
    return this.modifiedAttributeNameSet().delete('type').isEmpty();
  }

  toQueryString() {
    const attrs = {};
    for (let [name, value] of Object.entries(this._attributes)) {
      if (!searchAttributes.contains(name)) {
        continue;
      }
      if (value === null || typeof value === 'undefined') {
        continue;
      }
      if (multiValuedsearchAttributes.contains(name)) {
        value = serializeStringArray(value);
      }
      if (value === null) {
        continue;
      }
      switch (name) {
        case 'latlng': // L.LatLng
          value = serializeNumberArray([value.lat, value.lng]);
          break;
        case 'bounds': // L.LatLngBounds
          value = serializeNumberArray([
            value.getNorth(),
            value.getEast(),
            value.getSouth(),
            value.getWest(),
          ]);
          break;
        case 'shape': // [[lat,lon]]
          const values = [];
          for (const [lat, lon] of value) {
            values.push(lat);
            values.push(lon);
          }
          value = serializeNumberArray(values);
          break;
        case 'territory':
          value = value?.id;
          break;
      }
      if (value === null) {
        continue;
      }
      attrs[name] = value;
    }
    return serializeQueryString(attrs);
  }
}

let territoryCache = {};

export async function parseListingQueryString(queryString) {
  const parsed = deserializeQueryString(queryString);
  const query = new ListingQuery();
  for (let [name, value] of Object.entries(parsed)) {
    if (!searchAttributes.contains(name)) {
      continue;
    }
    if (value === null || typeof value === 'undefined') {
      continue;
    }
    if (multiValuedsearchAttributes.contains(name)) {
      value = deserializeStringArray(value);
    }
    if (value === null) {
      continue;
    }
    switch (name) {
      case 'latlng': {
        // [lat,lon]
        const values = deserializeNumberArray(value);
        value = L.latLng(values[0], values[1]);
        break;
      }
      case 'bounds': {
        // [north,east,south,west]
        const values = deserializeNumberArray(value);
        const [north, east, south, west] = values;
        value = L.latLngBounds(L.latLng(north, east), L.latLng(south, west));
        break;
      }
      case 'shape': {
        // [lat,lon,...]
        const values = deserializeNumberArray(value);
        const shape = [];
        for (let i = 0; i < values.length; i += 2) {
          const lat = values[i];
          const lon = values[i + 1];
          shape.push([lat, lon]);
        }
        value = shape;
        break;
      }
      case 'territory':
        const territoryName = value;
        let territory = territoryCache[territoryName];
        if (!territory) {
          try {
            const data = await zeus(`/api/territory/${encodeURIComponent(territoryName)}`);
            const {name, title, bounds} = data;
            territory = new Territory({id: name, title, bounds});
            territoryCache = {[territoryName]: territory};
          } catch (e) {
            console.error('Problem loading territory', e);
            territory = new Territory({id: territoryName, title: 'Unknown Territory'});
          }
        }
        value = territory;
        break;
    }
    if (value === null) {
      continue;
    }
    query.set(name, value);
  }
  return query;
}

// Helper function to determine if something is considered empty.
function isEmptyValue(value) {
  if (value === null || value === undefined) {
    return true;
  } else if (Array.isArray(value)) {
    // Normalize [] and [null...] to null
    return value.length === 0 || compact(value).length === 0;
  } else if (typeof value === 'string') {
    // Normalize empty strings to null
    return value.length === 0 || value.trim().length === 0;
  } else if (typeof value === 'object' && isEmpty(value)) {
    return true;
  }
  return false;
}
