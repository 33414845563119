import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Floater} from './Floater.react';
import {Overlay} from './Overlay.react';

export class DropdownMenuButton extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    children: PropTypes.node,
    isRequired: PropTypes.bool,
    loading: PropTypes.bool,
    isValueButton: PropTypes.bool,
    zIndex: PropTypes.number,
  };

  static defaultProps = {
    label: null,
    isValueButton: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    return (
      <div
        ref={(e) => (this._button = e)}
        tabIndex="0"
        className={`dropdown_menu_button${
          this.props.isValueButton ? ' dropdown_menu_value_button' : ''
        }`}
        href="#"
        data-loading={this.props.loading}
        data-open={this.state.open}
        onClick={(event) => this._handleClick(event)}
        onMouseDown={(event) => this._handleMouseDownOrTouchStart(event)}>
        <span className="dropdown_menu_button_content">
          {this.props.label != null && (
            <span className="dropdown_menu_button_content_label">
              <span className="dropdown_menu_button_content_label_text">
                {this.props.loading ? (
                  <span />
                ) : this.props.label !== '' ? (
                  this.props.label
                ) : (
                  <span>&nbsp;</span>
                )}
              </span>
            </span>
          )}
          <span className="dropdown_menu_button_content_arrow" />
        </span>
        {this.state.open && (
          <Overlay onShouldClose={() => this.close()}>
            <Floater
              offset={[0, -3]}
              placement={['bottom', 'left']}
              parent={() => this._button}
              zIndex={this.props.zIndex}
              className="dropdown_menu_button_floater">
              <div className="dropdown_menu_button_dropdown">{this.props.children}</div>
            </Floater>
          </Overlay>
        )}
      </div>
    );
  }

  close() {
    this.setState({open: false});
  }

  _handleClick(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  _handleMouseDownOrTouchStart(event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.props.loading) {
      this.setState({open: !this.state.open});
    }
  }
}
