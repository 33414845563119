import React from 'react';
import PropTypes from 'prop-types';

export default function HeadingBar(props) {
  return <div className="HeadingBar">{props.title}</div>;
}

HeadingBar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
};
