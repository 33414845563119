import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

export default connect()(
  class ShareListing extends React.Component {
    static propTypes = {
      layout: PropTypes.oneOf(['compact', 'horizontal', 'vertical']).isRequired,
      listing: PropTypes.object.isRequired,
      onEmailButtonClick: PropTypes.func.isRequired,
    };

    render() {
      const {listing} = this.props;
      return (
        <span className="ShareListing" data-layout={this.props.layout}>
          <button
            className="ShareListingButton ShareListingButtonEmail"
            onClick={() => {
              const {onEmailButtonClick} = this.props;
              onEmailButtonClick(listing);
            }}
            title="Share via email">
            <span className="ShareListingButton_icon" />
            <span className="ShareListingButton_text">Email Listing</span>
          </button>
        </span>
      );
    }
  }
);
