import React from 'react';
import PropTypes from 'prop-types';

const FilterDropdownItem = ({label, count}) => (
  <span className="FilterDropdownItem">
    <span>{label}</span>
    {count > 0 && <span className="FilterDropdownItem_count">{count}</span>}
  </span>
);

FilterDropdownItem.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.number,
};

export default FilterDropdownItem;
