import {isNumber} from 'underscore';

export function isNumberOrNumericRange(value) {
  return (
    value != null &&
    ((value.min != null && value.max != null) ||
      isNumber(value) ||
      isNumber(value.normalized_count))
  );
}
