import React from 'react';
import PropTypes from 'prop-types';

export class Html5Video extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    hasFocus: PropTypes.bool,
  };

  static defaultProps = {
    width: 600,
    height: 400,
    hasFocus: true,
  };

  state = {
    status: 'notLoaded',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const player = this._player;
    if (player && typeof player.pause === 'function') {
      const {hasFocus} = this.props;
      if (nextProps.hasFocus !== hasFocus && !nextProps.hasFocus) {
        player.pause();
      }
    }
  }

  render() {
    return (
      <div className="Video" data-status={this.state.status}>
        <video
          ref={(e) => (this._player = e)}
          src={this.props.url}
          height={this.props.height}
          width={this.props.width}
          controls
          onError={() => this.setState({status: 'error'})}
          onCanPlay={() => this.setState({status: 'loaded'})}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    );
  }
}
