import Configuration from './configuration';

export function shouldSendToOpcity({listing, agent, broker, sov}) {
  if (listing?.type !== 'for_sale' && listing?.type !== 'for_rent') {
    return false;
  }
  if (sov?.agent || sov?.broker) {
    return false;
  }
  if (listing?.type === 'for_sale' && !Configuration.get('lead_routing.buy.opcity.enabled')) {
    return false;
  } else if (
    listing?.type === 'for_rent' &&
    !Configuration.get('lead_routing.rent.opcity.enabled')
  ) {
    return false;
  }
  return true;
}
