import React from 'react';
import ReactDOM from 'react-dom';
import ResizeObserver from 'resize-observer-polyfill';
import PropTypes from 'prop-types';

import {getOffsetTop, getWindowScrollTop} from '../../../lib/node_utils';

export class ExpandToWindowBottom extends React.Component {
  static propTypes = {
    minHeight: PropTypes.number,
    child: PropTypes.func.isRequired,
  };

  state = {
    height: this.props.minHeight || 0,
  };

  constructor(props) {
    super(props);
    this._observer = new ResizeObserver(this._calculate);
  }

  componentDidMount() {
    window.addEventListener('resize', this._calculate);
    window.addEventListener('scroll', this._calculate);

    const node = ReactDOM.findDOMNode(this);
    if (node) {
      this._observer.observe(node);
    } else {
      // TODO: Fall back to interval?
    }
    setTimeout(this._calculate, 0);
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
    this._observer.disconnect();

    window.removeEventListener('scroll', this._calculate);
    window.removeEventListener('resize', this._calculate);
  }

  render() {
    return this.props.child(this.state.height);
  }

  _calculate = () => {
    if (this._mounted) {
      const height = Math.max(
        this.props.minHeight || 0,
        window.innerHeight + getWindowScrollTop() - (getOffsetTop(ReactDOM.findDOMNode(this)) || 0)
      );
      this.setState(() => ({height}));
    }
  };
}
