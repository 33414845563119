import React from 'react';
import PropTypes from 'prop-types';

export default class Ticks extends React.Component {
  static displayName = 'CarouselTicks';

  static propTypes = {
    onClick: PropTypes.func,
    count: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
  };

  render() {
    const {current, count} = this.props;
    return (
      <div className="CarouselTicks">
        {mapRange(0, count - 1, (idx) => (
          <div
            key={idx}
            className="CarouselTicks_tick"
            onClick={(event) => this._handleClick(event, idx)}
            data-active={idx + 1 == current}>
            <svg>
              <circle cx="0" cy="0" r="3" />
            </svg>
          </div>
        ))}
      </div>
    );
  }

  _handleClick(event, idx) {
    event.preventDefault();
    if (this.props.onClick) {
      this.props.onClick(idx);
    }
  }
}

function mapRange(start, end, func) {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(func(i));
  }
  return result;
}
