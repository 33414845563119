import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {DropdownMenuButton} from './DropdownMenuButton.react';
import {SelectList} from './SelectList.react';

export class SelectListButton extends Component {
  static propTypes = {
    onItemSelect: PropTypes.func.isRequired,
    onItemDeselect: PropTypes.func.isRequired,

    // Called when selected items changes. Called with `items` as an
    // argument.
    onChange: PropTypes.func.isRequired,

    // If true, will render a spinner.
    loading: PropTypes.bool,

    // This can be a function which returns a renderable label for the button.
    // If multiselect mode, it will receive each selected item individually. If
    // not provided, the `itemFormat` function will be used to render each item
    // into a list.
    labelFormat: PropTypes.func,

    // This can be a function which returns a renderable label for the button
    // when there are no selected items. This overrides `labelFormat` for the
    // case when there is no selection.
    unsetLabelFormat: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),

    // This is passed on as the `SelectList`'s `format` prop.
    itemFormat: PropTypes.func.isRequired,

    // These properties are passed on to SelectList:
    items: PropTypes.array.isRequired,
    selectedItems: PropTypes.array.isRequired,
    selectableFunc: PropTypes.func,
    multiSelect: PropTypes.bool,

    zIndex: PropTypes.number,
  };

  static defaultProps = {
    items: [],
    labelFormat: null,
    unsetLabelFormat: '',
    itemFormat: (item) => `${item}`,
    multiSelect: false,
    selectableFunc: () => true,
    onItemSelect: () => null,
    onItemDeselect: () => null,
    onChange: () => {},
  };

  render() {
    const {labelFormat, unsetLabelFormat, itemFormat, multiSelect, items, selectedItems} =
      this.props;

    let label;
    if (selectedItems.length > 0) {
      if (labelFormat) {
        if (multiSelect) {
          label = (
            <span className="SelectListButton_list_label">
              {selectedItems.map((item, idx) => (
                <span key={idx}>{labelFormat(item)}</span>
              ))}
            </span>
          );
        } else {
          label = labelFormat(selectedItems[0]);
        }
      } else {
        label = (
          <span className="SelectListButton_list_label">
            {selectedItems.map((item, idx) => (
              <span key={idx}>{itemFormat(item)}</span>
            ))}
          </span>
        );
      }
    } else {
      if (unsetLabelFormat != null) {
        if (typeof unsetLabelFormat === 'function') {
          label = unsetLabelFormat();
        } else {
          label = unsetLabelFormat;
        }
      } else {
        label = '';
      }
      label = <span className="SelectListButton_unset_label">{label}</span>;
    }

    return (
      <DropdownMenuButton
        label={label}
        ref="button"
        isValueButton
        loading={this.props.loading}
        zIndex={this.props.zIndex}>
        <SelectList
          items={items}
          selectedItems={selectedItems}
          format={itemFormat}
          multiSelect={multiSelect}
          selectableFunc={this.props.selectableFunc}
          onItemSelect={this.props.onItemSelect}
          onItemDeselect={this.props.onItemDeselect}
          onSelectionChange={(selection, eventData) =>
            this._handleSelectionChange(selection, eventData)
          }
        />
      </DropdownMenuButton>
    );
  }

  _handleSelectionChange(selection, eventData) {
    if (eventData.triggeredBy === 'click' && !this.props.multiSelect) {
      this._closeButton();
    }
    this.props.onChange(selection);
  }

  _closeButton() {
    if (this.refs && this.refs.button) {
      this.refs.button.close();
    }
  }
}
