import querystring from 'querystring';

function flatten(obj, prefix = null) {
  let all = [];

  const useBlankKey = Array.isArray(obj);

  Object.keys(obj).forEach((key) => {
    const newKey = prefix === null ? key : `${prefix}[${useBlankKey ? '' : key}]`;
    if (obj[key] === null) {
      // ignore
    } else if (Array.isArray(obj[key]) || typeof obj[key] === 'object') {
      all = all.concat(flatten(obj[key], newKey));
    } else {
      all.push([newKey, obj[key]]);
    }
  });
  return all;
}

export async function zeus(url, options = {method: 'GET'}) {
  let qs = '';
  if (options.queryObj) {
    const flattenedQuery = flatten(JSON.parse(JSON.stringify(options.queryObj)))
      .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
      .join('&');
    qs = `?${flattenedQuery}`;
  } else if (options.method.toUpperCase() === 'GET' && options.params) {
    qs = `?${querystring.stringify(options.params)}`;
  }
  const resp = await fetch(`${url}${qs}`);
  return await resp.json();
}
