import {isArray} from 'underscore';

export function isTrue(value) {
  if (isArray(value) && value.length === 1) {
    value = value[0];
  }
  return value === true || value === 'true';
}

export function singularOrPlural(value, singular, plural) {
  return value === 1 ? singular : plural;
}

export function formatNumberWithCommas(x) {
  return `${x}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function truncateText(s, maxLength) {
  if (s.length > maxLength) {
    s = `${s.slice(0, maxLength)}…`;
  }
  return s;
}

export function zeroPad(s, l) {
  if (s !== null) {
    s = `${s}`;
    while (s.length < l) {
      s = `0${s}`;
    }
  }
  return s;
}
