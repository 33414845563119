import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

import {Agent, Listing} from '../../../lib/models';
import HeadingBar from '../common/HeadingBar';
import Button from '../common/Button';
import {ListingGrid} from '../listings/ListingGrid';
import AgentDetails from './AgentDetails';
import {urlForSearch} from '../../../lib/support/routing';
import ListingQuery from '../../../lib/search/ListingQuery';

class AgentMain extends React.Component {
  static propTypes = {
    agent: PropTypes.instanceOf(Agent).isRequired,
    listings: ImmutablePropTypes.listOf(PropTypes.instanceOf(Listing)),
  };

  render() {
    const {agent, listings} = this.props;
    return (
      <div className="AgentMain">
        <div className="AgentMain_content">
          <div className="AgentMain_content_details">
            <AgentDetails agent={agent} showLink={false} />
          </div>
          {agent.biography && (
            <div className="AgentMain_content_biography">
              <h4>Biography</h4>
              <div className="AgentMain_content_biography_text">
                {agent.biography.split(/\n+/).map((s, i) => (
                  <p key={i}>{s}</p>
                ))}
              </div>
            </div>
          )}
          {listings.size > 0 && (
            <div className="AgentMain_content_listings">
              <HeadingBar title="Available Listings" />
              <ListingGrid listings={listings} />
              <div className="AgentMain_content_listings_more">
                <Button
                  label="See all listings"
                  kind="submit"
                  href={urlForSearch(ListingQuery.getEmptyListingQueryForAgent(agent))}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AgentMain;
