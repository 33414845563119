export function slugify(s) {
  if (s && s.length > 0) {
    s = s.trim().replace(/[^\w0-9]+/gm, '-');
  }
  return s && s.length > 0 ? s : '-';
}

export function absoluteUrlFromPath(url) {
  if (
    /^\//.test(url) &&
    typeof window !== 'undefined' &&
    window.location.protocol !== 'file:' &&
    window.location.hostname
  ) {
    url = `${window.location.protocol}//${window.location.hostname}${url}`;
  }
  return url;
}

export function relativizeUrl(url) {
  if (url) {
    if (url.slice(0, 1) === '/') {
      return url;
    }

    const match = /https?:\/\/(?:[^/]+)(\/[^?]+)(?:.+)?/.exec(url);
    if (match) {
      return match[1];
    }
  }

  return null;
}
