import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {getDisplayName} from './util';

export const WindowSizeShape = PropTypes.shape({
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
});

/**
 * High-level component that reports the current window size to a wrapped
 * component. All props are passed through. The window dimensions are passed
 * as the prop `windowDimensions`.
 */
export function observeWindowSize(WrappedComponent) {
  return class Observer extends Component {
    static displayName = `WindowSizeObserver(${getDisplayName(WrappedComponent)})`;

    state = getWindowDimensions();

    componentDidMount() {
      this._mounted = true;
      window.addEventListener('resize', this._update);
    }

    componentWillUnmount() {
      this._mounted = false;
      window.removeEventListener('resize', this._update);
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }

    _update = () => {
      if (this._mounted) {
        const newDimensions = getWindowDimensions();
        if (!compare(newDimensions, this.state)) {
          this.setState(newDimensions);
        }
      }
    };
  };
}

function compare(a, b) {
  return (
    a.windowDimensions.width === b.windowDimensions.width &&
    a.windowDimensions.height === b.windowDimensions.height
  );
}

function getWindowDimensions() {
  return {
    windowDimensions: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  };
}
