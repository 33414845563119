import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {findDOMNode} from 'react-dom';
import {isEqual, zip, every, some, reject} from 'underscore';
import domEvent from 'dom-event';

export class SelectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: props.selectedItems,
      currentItem: null,
    };
    this._handleParentInputKeydown = this._handleParentInputKeydown.bind(this);
  }

  componentDidMount() {
    const {parentInputElement} = this.props;
    if (parentInputElement) {
      domEvent.on(parentInputElement, 'keydown', this._handleParentInputKeydown);
    }
  }

  componentWillUnmount() {
    const {parentInputElement} = this.props;
    if (parentInputElement) {
      domEvent.off(parentInputElement, 'keydown', this._handleParentInputKeydown);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this._itemArraysEqual(this.state.selectedItems, nextProps.selectedItems)) {
      this.setState({selectedItems: nextProps.selectedItems});
    }
  }

  render() {
    const {selectableFunc, format} = this.props;
    const {currentItem} = this.state;
    return (
      <div
        className="SelectList"
        data-multi-select={this.props.multiSelect}
        onClick={(event) => this._handleItemClick(event)}>
        <ol>
          {this.props.items.map((item, idx) => {
            return (
              <li
                key={idx}
                data-index={idx}
                data-current={this._itemsEqual(currentItem, item)}
                data-selected={this._isItemSelected(item)}
                data-selectable={selectableFunc(item)}>
                <a>{format(item)}</a>
              </li>
            );
          })}
        </ol>
      </div>
    );
  }

  _handleItemClick(event) {
    const domNode = findDOMNode(this);

    let {target} = event;
    while (target && target !== domNode) {
      if (target.tagName === 'LI' && target.hasAttribute('data-index')) {
        const index = +target.getAttribute('data-index');
        if (index >= 0 && index < this.props.items.length) {
          const item = this.props.items[index];
          if (this.props.selectableFunc(item)) {
            if (this.props.multiSelect) {
              this._toggleSelectItem(item, {triggeredBy: 'click'});
            } else {
              this._selectItem(item, {triggeredBy: 'click'});
            }
          }
          event.preventDefault();
        }
        break;
      }
      target = target.parentNode;
    }
  }

  _setSelection(items, eventData = {}, options = {}) {
    if (options.forceChangeEvent || !this._itemArraysEqual(this.state.selectedItems, items)) {
      this.setState({selectedItems: items});
      this.props.onSelectionChange(this.props.multiSelect ? items : items[0], eventData);
      return true;
    }
  }

  _itemArraysEqual(a, b) {
    if (a.length === b.length) {
      return every(zip(a, b), ([aa, bb]) => this._itemsEqual(aa, bb));
    }
    return false;
  }

  _itemsEqual(a, b) {
    if (a === null || a === undefined) {
      return b === null || b === undefined;
    } else if (a === b) {
      return true;
    } else if (typeof a.equals === 'function' && typeof b.equals === 'function') {
      return a.equals(b);
    } else {
      return isEqual(a, b);
    }
  }

  _isItemSelected(item) {
    const {selectedItems} = this.state;
    if (selectedItems.length === 0 && item.value === null) {
      return true;
    } else {
      return some(selectedItems, (anItem) => this._itemsEqual(item, anItem));
    }
  }

  _selectItem(item, eventData = {}) {
    if (item) {
      let newSelection;
      if (this.props.multiSelect) {
        if (item.value === null) {
          newSelection = [];
        } else if (!this._isItemSelected(item)) {
          newSelection = this.state.selectedItems.concat([item]);
        }
      } else {
        newSelection = [item];
      }
      if (this._setSelection(newSelection, eventData, {forceChangeEvent: true})) {
        this.props.onItemSelect(item);
        return true;
      }
    }
  }

  _unselectItem(item, eventData = {}) {
    if (
      this._setSelection(
        reject(this.state.selectedItems, (anItem) => this._itemsEqual(item, anItem)),
        eventData
      )
    ) {
      this.props.onItemDeselect(item);
      return true;
    }
  }

  _toggleSelectItem(item, eventData = {}) {
    if (item) {
      return this._isItemSelected(item)
        ? this._unselectItem(item, eventData, eventData)
        : this._selectItem(item, eventData, eventData);
    }
  }

  _handleParentInputKeydown(event) {
    switch (event.keyCode) {
      // Arrow up
      case 38:
        this._selectPreviousItem();
        event.preventDefault();
        break;

      // Arrow down
      case 40:
        this._selectNextItem();
        event.preventDefault();
        break;

      // Enter and space
      case 13:
      case 20:
        this._selectCurrentItem();
        event.preventDefault();
        break;
    }
  }

  _selectCurrentItem() {
    this._selectItem(this.state.currentItem);
  }

  _selectPreviousItem() {
    this.setState({currentItem: this._findAdjacentItemRelativeTo(this.state.currentItem, -1)});
  }

  _selectNextItem() {
    this.setState({currentItem: this._findAdjacentItemRelativeTo(this.state.currentItem, 1)});
  }

  _findAdjacentItemRelativeTo(item, direction) {
    const {items} = this.props;
    if (items.length) {
      return items[(items.indexOf(item) + direction) % items.length];
    } else {
      return null;
    }
  }
}

SelectList.propTypes = {
  // List of items. Note that items should either be primitives
  // (eg., strings) or implement `equals()`; otherwise they are compared
  // structurally using Underscore's `isEqual()`.
  items: PropTypes.array.isRequired,

  // List of selected items.
  selectedItems: PropTypes.array,

  // If true, multiple items can be selected.
  multiSelect: PropTypes.bool,

  // This must be either a React element that can render the item, or a
  // a function. The function may returns a React element, a string, or any
  // other React-compatible value. The function will receive a single item
  // to render.
  format: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),

  // If set, this must be a DOM element whose keyboard input (eg., arrow keys)
  // will be tracked to move the selection.
  parentInputElement: PropTypes.object,

  // If set, this function will be called with an item to determine if it is
  // selectable. The default is for all items to be selectable.
  selectableFunc: PropTypes.func,

  // Function called when selection changes. Signature is
  // `(selection, eventData)`; if in multiselect mode, the selection
  // argument is always a list, otherwise a single item.
  //
  // The `eventData` argument may have these keys:
  //
  // * `triggeredBy`: is either `"click"` or `"keyboard"` or (if the
  //   selection was modified through a prop change) null.
  // * `lastItem`: Last item selected, if by click or keyboard.
  //
  onSelectionChange: PropTypes.func.isRequired,

  onItemSelect: PropTypes.func.isRequired,
  onItemDeselect: PropTypes.func.isRequired,
};

SelectList.defaultProps = {
  multiSelect: false,
  format: (item) => item,
  parentInputElement: null,
  items: [],
  selectedItems: [],
  selectableFunc() {
    return true;
  },
  onSelectionChange() {},
  onItemSelect() {},
  onItemDeselect() {},
};
