import React from 'react';
import PropTypes from 'prop-types';

import {getListingQueryDescriptionTerms} from '../../../lib/listings/listingQueryDescription';

export default class ListingQueryDescription extends React.PureComponent {
  static propTypes = {
    query: PropTypes.object.isRequired,
    compareWithQuery: PropTypes.object,
  };

  render() {
    const items = this._computeItems();
    return (
      <span className="ListingQueryDescription">
        {items.isEmpty()
          ? this.props.compareWithQuery
            ? 'No changes'
            : 'Default search'
          : items
              .valueSeq()
              .map((item, idx) => <span key={idx}>{item}</span>)
              .toJS()}
      </span>
    );
  }

  _computeItems() {
    const {query, compareWithQuery} = this.props;

    const thisTerms = getListingQueryDescriptionTerms(query).toSet();
    if (!compareWithQuery) {
      return thisTerms.map((item) => <span>{item.description}</span>);
    }

    const otherTerms = getListingQueryDescriptionTerms(compareWithQuery).toSet();

    return thisTerms
      .subtract(otherTerms)
      .map((item) => (
        <ins>
          <strong>{'Added'}</strong> {item.description}
        </ins>
      ))
      .union(
        otherTerms.subtract(thisTerms).map((item) => (
          <del>
            <strong>{'Removed'}</strong> {item.description}
          </del>
        ))
      );
  }
}
