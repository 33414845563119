import $ from 'jquery';

// Is an element inside a parent, or the actual parent?
export function isElementOrInside(node, parentNode) {
  // TOOD: Avoid using jQuery
  return node && parentNode && (node === parentNode || $.contains(parentNode, node));
}

export function getWindowScrollTop() {
  return window.pageYOffset || window.scrollY || window.scrollTop || 0;
}

export function getWidth(element) {
  // TOOD: Avoid using jQuery
  return $(element).outerWidth();
}

export function getHeight(element) {
  // TOOD: Avoid using jQuery
  return $(element).outerHeight();
}

export function getOffsetTop(element) {
  // TOOD: Avoid using jQuery
  return $(element).offset().top;
}

export function getOffsetLeft(element) {
  return $(element).offset().left;
}

export function isPointInsideElement(element, point) {
  const $el = $(element);
  const left = $el.offset().left;
  const top = $el.offset().top;
  const width = $el.outerWidth();
  const height = $el.outerHeight();
  return point[0] >= left && point[0] < left + width && point[1] >= top && point[1] < top + height;
}

/**
 * Returns x where 0 <= x <= 1. 0 if none of the element's bouding rect is on screen, 1 if all of it is.
 * @param {DomNode} element
 */
// based on https://math.stackexchange.com/questions/99565/simplest-way-to-calculate-the-intersect-area-of-two-rectangles
export function portionOfElementInViewport(element) {
  const rect1 = element.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;

  const rect2 = {left: 0, top: 0, right: windowWidth, bottom: windowHeight};
  const area = rect1.width * rect1.height;

  const xOverlap = Math.max(
    0,
    Math.min(rect1.right, rect2.right) - Math.max(rect1.left, rect2.left)
  );
  const yOverlap = Math.max(
    0,
    Math.min(rect1.bottom, rect2.bottom) - Math.max(rect1.top, rect2.top)
  );
  const overlapArea = xOverlap * yOverlap;

  return overlapArea / area;
}
