import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';

import {streetsTileLayer, satelliteTileLayer} from '../../../lib/maps/leaflet';

export default class ListingMap extends React.Component {
  invalidate() {
    if (this._mapObject) {
      this._mapObject.invalidateSize();
    }
  }

  static propTypes = {
    listing: PropTypes.object.isRequired,
    height: PropTypes.number,
  };

  state = {
    latLng: this.props.listing.getLatLng(),
  };

  componentDidMount() {
    this._ensureMap();
  }

  componentWillUnmount() {
    if (this._mapObject) {
      this._mapObject.remove();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const latLng = this.props.listing.getLatLng();
    if (latLng && (this.state.latLng === null || !latLng.equals(this.state.latLng))) {
      setTimeout(() => {
        this.setState({latLng});
      }, 1000 / 30);
    }

    if (
      prevState.latLng === null ||
      (this.state.latLng && !this.state.latLng.equals(prevState.latLng))
    ) {
      this._ensureMap();
      if (this.state.latLng) {
        this._mapObject.setView(this.state.latLng);
        this._marker.setLatLng(this.state.latLng);
      }
    }
  }

  render() {
    return (
      <div className="ListingMap" style={{height: this.props.height}}>
        {this.state.latLng && (
          <div ref={(node) => (this._mapNode = node)} className="ListingMap_map" />
        )}
      </div>
    );
  }

  _ensureMap() {
    if (!(!this._mapObject && this.state.latLng)) {
      return;
    }

    this._mapObject = L.map(this._mapNode, {
      center: this.state.latLng,
      zoom: 14,
      zoomControl: true,
      scrollWheelZoom: false,
      attributionControl: true,
    });

    streetsTileLayer().addTo(this._mapObject);

    L.control
      .layers({Streets: streetsTileLayer(), Satellite: satelliteTileLayer()})
      .addTo(this._mapObject);

    this._marker = new L.Marker(this.state.latLng, {
      icon: new L.Icon.Default(),
    }).addTo(this._mapObject);
  }
}
