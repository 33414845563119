// <script
//   async
//   src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=pageadpageaXXX"
//   crossOrigin="anonymous"></script>
export function setupAdSense(adSenseId) {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${encodeURIComponent(
    adSenseId
  )}`;
  script.crossOrigin = 'anonymous';
  document.head.appendChild(script);
}
