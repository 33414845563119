import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {withRouter} from 'react-router';

import {formatVerboseDate, formatInformalTime} from '../../../lib/node_utils';
import ListingInfoBox from './ListingInfoBox';
import ListingSocialButtons from './ListingSocialButtons';
import Ad from '../Ad';
import Configuration from '../../../lib/configuration';
import {urlForAgent, urlForBroker} from '../../../lib/support/routing';
import NumberOrRange from '../common/NumberOrRange';
import ListingDirectionsLink from './ListingDirectionsLink';
import ListingVirtualTour from './ListingVirtualTour';
import ListingDetailMortgageCalculator from './ListingDetailMortgageCalculator';

const ExternalListingLink = connect()(
  class ExternalListingLink extends React.PureComponent {
    static propTypes = {
      dispatch: PropTypes.func.isRequired,
      listing: PropTypes.object.isRequired,
    };

    render() {
      const url = this.props.listing.external_listing?.listing_url;
      if (!url) {
        return null;
      }
      return (
        <div className="ListingDetailDescription_description_listing_url">
          <a href={url} target="_blank">
            {'View more information about this listing'}
          </a>
        </div>
      );
    }
  }
);

const ListingDetailDescriptionWithRedux = connect()(
  withRouter(
    class ListingDetailDescription extends React.Component {
      static propTypes = {
        dispatch: PropTypes.func.isRequired,
        location: PropTypes.shape({
          search: PropTypes.string,
        }).isRequired,
        listing: PropTypes.object.isRequired,
        agent: PropTypes.object,
        broker: PropTypes.object,
        onShowContactForm: PropTypes.func,
      };

      state = {
        showExternalListingLink: Configuration.get('ui.details_page.show_external_listing_url'),
        showFeedSource: false,
      };

      render() {
        const {agent, broker, listing} = this.props;

        const updateTime = this._parseDate(this.props.listing.updated_at);

        const externalListing = listing.external_listing || {};

        const legalDisclaimer = Configuration.get('ui.details_page.legal_disclaimer');

        const amenities = listing.amenities || [];
        const appliances = listing.appliances || [];
        const equipment = listing.equipment || [];
        const accessibility = listing.accessibility || [];
        const rooms_other = listing.rooms_other || [];
        const heating = listing.heating || [];
        const cooling = listing.cooling || [];
        const utilities = listing.utilities || [];
        const flooring = listing.flooring || [];
        const roof = listing.roof || [];
        const foundation = listing.foundation || [];
        const exterior_features = listing.exterior_features || [];
        const parking = listing.parking || [];
        const views = listing.views || [];
        const waterfront = listing.waterfront || [];
        const virtualTourUrl = (listing.virtual_tour || {}).url;

        return (
          <div className="ListingDetailDescription">
            <div className="ListingDetailDescription_columns">
              <div className="ListingDetailDescription_description">
                <div className="ListingDetailDescription_section">
                  <h3>Description</h3>
                  <div className="ListingDetailDescription_description_text">
                    {(listing.description || 'This listing has no description.')
                      .split(/\n/)
                      .map((v, idx) => (
                        <p key={idx}>{v}</p>
                      ))}
                  </div>
                  {listing && listing.marketing_text && (
                    <div className="ListingDetailDescription_marketing_text">
                      {listing.marketing_text}
                    </div>
                  )}
                </div>

                {rooms_other.length > 0 && (
                  <div className="ListingDetailDescription_section">
                    <h3>{'Rooms'}</h3>
                    <ul>
                      {rooms_other.map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {amenities.length > 0 && (
                  <div className="ListingDetailDescription_section">
                    <h3>{'Building Amenities'}</h3>
                    <ul>
                      {amenities.map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {appliances.length > 0 && (
                  <div className="ListingDetailDescription_section">
                    <h3>{'Appliances'}</h3>
                    <ul>
                      {appliances.map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {equipment.length > 0 && (
                  <div className="ListingDetailDescription_section">
                    <h3>{'Equipment'}</h3>
                    <ul>
                      {equipment.map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {heating.length > 0 && (
                  <div className="ListingDetailDescription_section">
                    <h3>{'Heating'}</h3>
                    <ul>
                      {heating.map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {cooling.length > 0 && (
                  <div className="ListingDetailDescription_section">
                    <h3>{'Cooling'}</h3>
                    <ul>
                      {cooling.map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {utilities.length > 0 && (
                  <div className="ListingDetailDescription_section">
                    <h3>{'Utilities'}</h3>
                    <ul>
                      {utilities.map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {accessibility.length > 0 && (
                  <div className="ListingDetailDescription_section">
                    <h3>{'Accessibility'}</h3>
                    <ul>
                      {accessibility.map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {flooring.length > 0 && (
                  <div className="ListingDetailDescription_section">
                    <h3>{'Flooring'}</h3>
                    <ul>
                      {flooring.map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {roof.length > 0 && (
                  <div className="ListingDetailDescription_section">
                    <h3>{'Roof'}</h3>
                    <ul>
                      {roof.map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {foundation.length > 0 && (
                  <div className="ListingDetailDescription_section">
                    <h3>{'Foundation'}</h3>
                    <ul>
                      {foundation.map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {exterior_features.length > 0 && (
                  <div className="ListingDetailDescription_section">
                    <h3>{'Exterior Features'}</h3>
                    <ul>
                      {exterior_features.map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {parking.length > 0 && (
                  <div className="ListingDetailDescription_section">
                    <h3>{'Parking'}</h3>
                    <ul>
                      {parking.map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {views.length > 0 && (
                  <div className="ListingDetailDescription_section">
                    <h3>{'Views'}</h3>
                    <ul>
                      {views.map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {waterfront.length > 0 && (
                  <div className="ListingDetailDescription_section">
                    <h3>{'Waterfront'}</h3>
                    <ul>
                      {waterfront.map((s, idx) => (
                        <li key={idx}>{s}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {listing.price_history && (
                  <div>
                    <h3>Price History</h3>
                    <table className="ListingDetailDescription_table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Price</th>
                          <th>Change</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listing.price_history.map(({date, old_price, new_price}) => (
                          <tr key={`${date}-${new_price}`}>
                            <td>{date}</td>
                            <td>
                              <NumberOrRange value={new_price} currency="$" />
                            </td>
                            <td>
                              <NumberOrRange value={new_price - old_price} currency="$" />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {listing.type === 'for_sale' && (
                  <ListingDetailMortgageCalculator listing={listing} />
                )}

                {this.state.showExternalListingLink && <ExternalListingLink listing={listing} />}

                {externalListing.source_attribution && (
                  <div className="ListingDetailDescription_source">
                    {externalListing.source_attribution}
                  </div>
                )}
                <div onDoubleClick={this._handleToggleShowFeedSource}>
                  {updateTime && (
                    <div className="ListingDetailDescription_updated_at">
                      {'Last updated '}
                      {formatVerboseDate(updateTime)}
                      {' at '}
                      {formatInformalTime(updateTime)}
                    </div>
                  )}
                  {externalListing.copyright && (
                    <div className="ListingDetailDescription_source">
                      {externalListing.copyright}
                    </div>
                  )}
                  {this.state.showFeedSource && externalListing.source && (
                    <div className="ListingDetailDescription_source">
                      {'Source: '}
                      {externalListing.source}
                    </div>
                  )}
                </div>
              </div>
              <div className="ListingDetailDescription_side">
                <ListingInfoBox listing={listing} />

                <div className="ListingDetailDescription_social">
                  {listing.isActive() && (
                    <div>
                      <h3>{'Share Listing'}</h3>
                      <ListingSocialButtons listing={listing} />
                      <div className="ListingDirectionsButton">
                        <ListingDirectionsLink listing={listing} />
                      </div>
                    </div>
                  )}
                  {virtualTourUrl && (
                    <div className="ListingVirtualTourButton">
                      <ListingVirtualTour url={virtualTourUrl} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {!listing.isActive() && (
              <div className="ListingDetailDescription_inactive_listing_ad">
                <Ad name="listing_detail_tile" />
              </div>
            )}

            {listing.isActive() && (
              <div className="ListingDetailDescription_agent">
                <div className="ListingDetailDescription_agent_info">
                  <h3>Listed by</h3>
                  {agent && (
                    <div className="ListingDetailDescription_agent_info_agent">
                      <a href={urlForAgent(agent, listing.type, this.props.location.search)}>
                        {agent.name}
                      </a>
                    </div>
                  )}
                  {agent && agent.license && (
                    <div className="ListingDetailDescription_agent_info_license">
                      {agent.license}
                    </div>
                  )}
                  {broker && (
                    <div className="ListingDetailDescription_agent_info_broker">
                      <a href={urlForBroker(broker, listing.type, this.props.location.search)}>
                        {broker.name}
                      </a>
                    </div>
                  )}
                  {
                    // ListHub requires we display: Brokerage Office Phone Number
                    // https://app.shortcut.com/t11e/story/8807/listhub-publisher-compliance-notice-koto-homes
                    broker &&
                      broker.phone &&
                      listing.external_listing &&
                      listing.external_listing.listhub_listing_key && (
                        <div className="ListingDetailDescription_agent_info_broker_phone">
                          {broker.phone}
                        </div>
                      )
                  }
                </div>
                <div className="ListingDetailDescription_agent_ad">
                  <Ad name="listing_detail_tile" />
                </div>
              </div>
            )}

            {legalDisclaimer && (
              <div className="ListingDetailDescription_legal_disclaimer">{legalDisclaimer}</div>
            )}
          </div>
        );
      }

      _parseDate(text) {
        return text ? moment(text, moment.ISO_8601).toDate() : null;
      }

      _handleToggleShowFeedSource = () => {
        this.setState({showFeedSource: !this.state.showFeedSource});
      };
    }
  )
);

export default ListingDetailDescriptionWithRedux;
