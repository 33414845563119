import {
  LOAD_MARKER_CLUSTERS_REQUEST,
  LOAD_MARKER_CLUSTERS_SUCCESS,
  LOAD_MARKER_CLUSTERS_FAILURE,
} from '../actions/clusteredMarkers';

const INITIAL_STATE = {
  query: null,
  bounds: null,
  zoomLevel: null,
  clusters: [],
  inflight: null,
};

export default function clusteredMarkers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_MARKER_CLUSTERS_REQUEST:
      return Object.assign({}, state, {
        inflight: {
          id: action.id,
          query: action.query,
          bounds: action.bounds,
          zoomLevel: action.zoomLevel,
        },
        query: action.query,
      });
    case LOAD_MARKER_CLUSTERS_SUCCESS:
      if (!(state.inflight && state.inflight.id === action.id)) {
        return state;
      }
      return Object.assign({}, state, {
        clusters: action.clusters,
        limited: action.limited,
        bounds: action.bounds,
        zoomLevel: action.zoomLevel,
        inflight: null,
        err: null,
      });
    case LOAD_MARKER_CLUSTERS_FAILURE:
      if (!(state.inflight && state.inflight.id === action.id)) {
        return state;
      }
      return Object.assign({}, state, {
        inflight: null,
        err: action.err,
      });
    default:
      return state;
  }
}
