import React from 'react';
import PropTypes from 'prop-types';

export class MapToolbarToggleButton extends React.Component {
  static propTypes = {
    enabled: PropTypes.bool,
    active: PropTypes.bool,
    compact: PropTypes.bool,
    name: PropTypes.string,
    title: PropTypes.node,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    enabled: true,
    compact: false,
    active: false,
  };

  render() {
    return (
      <a
        className="MapToolbarButton"
        href="#"
        aria-label={this.props.title || this.props.name}
        data-compact={this.props.compact}
        data-name={this.props.name}
        data-title={this.props.title}
        data-enabled={this.props.enabled}
        data-active={this.props.active}
        onClick={this._handleClick}>
        <span className="MapToolbarButton_label">{this.props.title}</span>
      </a>
    );
  }

  _handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onClick();
  };
}

export class MapToolbarButton extends React.Component {
  static propTypes = {
    enabled: PropTypes.bool,
    active: PropTypes.bool,
    compact: PropTypes.bool,
    name: PropTypes.string,
    title: PropTypes.node,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    enabled: true,
    compact: false,
  };

  render() {
    return (
      <a
        className="MapToolbarButton"
        href="#"
        aria-label={this.props.title || this.props.name}
        data-compact={this.props.compact}
        data-name={this.props.name}
        data-title={this.props.title}
        data-enabled={this.props.enabled}
        title={this.props.title}
        onClick={this._handleClick}>
        <span className="MapToolbarButton_label">{this.props.title}</span>
      </a>
    );
  }

  _handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };
}

export class MapToolbar extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <div className="MapToolbar">
        <div className="MapToolbar_items">
          <ul>
            {React.Children.map(this.props.children, (child, idx) => (
              <li key={idx}>{child}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
