import {List} from 'immutable';
import {isEqual} from 'underscore';

import {formatNumberWithCommas, computeStringHash} from '../node_utils';
import {OPEN_HOUSE_ITEMS, formatPropertyType, NEW_LISTING_DATE_ITEMS} from '../support/search';
import {TYPE_DESCRIPTIONS, DEFAULTS} from '../search/ListingQuery';
import {decapitalize} from '../utils/text';

// Description that maps directly to a single query attribute.
export class AttributeTerm {
  constructor(attribute, description, value) {
    this.attribute = attribute;
    this.description = description;
    this.value = value;
  }

  hashCode() {
    return (
      computeStringHash(this.attribute) ^
      computeStringHash(this.description) ^
      computeStringHash(this.value)
    );
  }

  equals(other) {
    return (
      other instanceof AttributeTerm &&
      other.attribute === this.attribute &&
      other.description === this.description &&
      (other.value != null ? isEqual(other.value, this.value) : this.value == null)
    );
  }
}

// Complex object that doesn't map to a single attribute.
export class ComplexTerm {
  constructor(key, description, value) {
    this.key = key;
    this.description = description;
    this.value = value;
  }

  hashCode() {
    return (
      computeStringHash(this.key) ^
      computeStringHash(this.description) ^
      computeStringHash(this.value)
    );
  }

  equals(other) {
    return (
      other instanceof ComplexTerm &&
      other.key === this.key &&
      other.description === this.description &&
      (other.value != null ? isEqual(other.value, this.value) : this.value == null)
    );
  }
}

export function getListingQueryDescriptionTerms(query) {
  let terms = List(Object.entries(Object.assign({}, DEFAULTS, query.asObject())))
    .filterNot(([_, value]) => value == null)
    .map(([attribute, value]) => {
      switch (attribute) {
        case 'bedrooms':
        case 'bedrooms_value':
          return new AttributeTerm(attribute, `${value} beds`);
        case 'bathrooms':
        case 'bathrooms_value':
          return new AttributeTerm(attribute, `${value} baths`);
        case 'has_photos':
          if (value === true) {
            return new AttributeTerm(attribute, 'has photos');
          }
          return null;
        case 'has_videos':
          if (value === true) {
            return new AttributeTerm(attribute, 'has videos');
          }
          return null;
        case 'open_house_dates': {
          const openHouseDateItem = OPEN_HOUSE_ITEMS.find((item) => item.value === value);
          if (openHouseDateItem) {
            return new AttributeTerm(
              attribute,
              `open house ${openHouseDateItem.label.toLowerCase()}`
            );
          }
          return null;
        }
        case 'type':
          return new AttributeTerm(attribute, decapitalize(TYPE_DESCRIPTIONS[value] || value));
        case 'addressQuery':
          return new AttributeTerm(attribute, `address containing "${value}"`);
        case 'property_type': {
          const types = Array.isArray(value) ? value : [value];
          if (types.length > 0) {
            return new AttributeTerm(
              attribute,
              types.map((t) => decapitalize(formatPropertyType(t))).join(', ')
            );
          }
          break;
        }
        case 'text':
          return new AttributeTerm(attribute, `contains text "${value}"`);
        case 'mls_number':
          return new AttributeTerm(attribute, `MLS # "${value}"`);
        case 'publishing_timeframe': {
          const newListingDateItem = NEW_LISTING_DATE_ITEMS.find((item) => item.value === value);
          return newListingDateItem
            ? new AttributeTerm(attribute, `new ${newListingDateItem.label.toLowerCase()}`)
            : null;
        }
        case 'isCurrentLocation':
          if (value === true) {
            return new AttributeTerm(attribute, 'near you');
          }
          return null;
      }
      return null;
    })
    .filterNot((v) => v == null);

  const geoLocation = query.getGeoLocation();
  if (geoLocation && geoLocation.isSet() && !geoLocation.isCurrentLocation) {
    if (geoLocation.shape) {
      terms = terms.push(new ComplexTerm('shape', 'custom map area', geoLocation.shape));
    } else {
      terms = terms.push(new ComplexTerm('geoLocation', `near ${geoLocation.format()}`));
    }
  }

  {
    const {min: priceMin, max: priceMax} = query.getRange('price');
    if (priceMin != null || priceMax != null) {
      terms = terms.push(new ComplexTerm('price', rangeToString(priceMin, priceMax, '$')));
    }
  }
  {
    const {min: priceMin, max: priceMax} = query.getRange('price_value');
    if (priceMin != null || priceMax != null) {
      terms = terms.push(new ComplexTerm('price', rangeToString(priceMin, priceMax, '$')));
    }
  }
  {
    const {min: rentMin, max: rentMax} = query.getRange('rent');
    if (rentMin != null || rentMax != null) {
      terms = terms.push(new ComplexTerm('rent', rangeToString(rentMin, rentMax, '$')));
    }
  }
  {
    const {min: rentMin, max: rentMax} = query.getRange('rent_value');
    if (rentMin != null || rentMax != null) {
      terms = terms.push(new ComplexTerm('rent', rangeToString(rentMin, rentMax, '$')));
    }
  }
  {
    const {min: lotsMin, max: lotsMax} = query.getRange('lots');
    if (lotsMin != null || lotsMax != null) {
      terms = terms.push(new ComplexTerm('lots', rangeToString(lotsMin, lotsMax, '', ' acres')));
    }
  }
  {
    const {min: lotsMin, max: lotsMax} = query.getRange('lots_value');
    if (lotsMin != null || lotsMax != null) {
      terms = terms.push(new ComplexTerm('lots', rangeToString(lotsMin, lotsMax, '', ' acres')));
    }
  }
  {
    const {min: sqMin, max: sqMax} = query.getRange('square_footage');
    if (sqMin != null || sqMax != null) {
      terms = terms.push(
        new ComplexTerm('square_footage', rangeToString(sqMin, sqMax, '', ' sq. ft.'))
      );
    }
  }
  {
    const {min: sqMin, max: sqMax} = query.getRange('square_footage_value');
    if (sqMin != null || sqMax != null) {
      terms = terms.push(
        new ComplexTerm('square_footage', rangeToString(sqMin, sqMax, '', ' sq. ft.'))
      );
    }
  }
  return terms.toSet();
}

function rangeToString(min, max, prefix, suffix) {
  if (min && max) {
    if (min == max) {
      return `exactly ${prefix || ''}${formatNumberWithCommas(min)}`;
    }
    return `${prefix || ''}${formatNumberWithCommas(min)} — ${formatNumberWithCommas(max)}${
      suffix || ''
    }`;
  }
  if (min) {
    return `from ${prefix || ''}${formatNumberWithCommas(min)}${suffix || ''}`;
  }
  return `up to ${prefix || ''}${formatNumberWithCommas(max)}${suffix || ''}`;
}
