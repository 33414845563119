import React from 'react';
import PropTypes from 'prop-types';

import ListingQuery from '../../../../lib/search/ListingQuery';
import LocationSelector from '../../common/LocationSelector';

const LocationFilter = ({required, query, onUpdateQuery}) => (
  <div className="LocationFilter">
    <LocationSelector
      geolocRequired={required}
      placeholder={required ? 'Choose search location' : 'Type a location'}
      geoLocation={query.getGeoLocation()}
      onChange={(geoLoc) => onUpdateQuery((q) => q.setGeoLocation(geoLoc))}
    />
  </div>
);

LocationFilter.defaultProps = {
  required: false,
};

LocationFilter.propTypes = {
  query: PropTypes.instanceOf(ListingQuery).isRequired,
  onUpdateQuery: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default LocationFilter;
