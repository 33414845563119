import {searchAttributes} from '../search/ListingQuery';

export function urlForListing(listing, prevSearch = '') {
  const filteredSearch = filterSearch(prevSearch);
  return `/listings/${encodeURIComponent(listing.slugify())}/${encodeURIComponent(
    listing.nuid
  )}${filteredSearch}`;
}

export function urlForAgent(agent, listingType = null, prevSearch = null) {
  const filteredSearch = filterSearch(prevSearch);
  let search = filteredSearch || '';
  if (listingType) {
    search += `${search ? '&' : '?'}listingType=${encodeURIComponent(listingType)}`;
  }
  return `/agents/${encodeURIComponent(agent.slugify())}/${encodeURIComponent(
    agent.nuid
  )}${search}`;
}

export function urlForBroker(broker, listingType = null, prevSearch = null) {
  const filteredSearch = filterSearch(prevSearch);
  let search = filteredSearch || '';
  if (listingType) {
    search += `${search ? '&' : '?'}listingType=${encodeURIComponent(listingType)}`;
  }
  return `/brokers/${encodeURIComponent(broker.slugify())}/${encodeURIComponent(
    broker.nuid
  )}${search}`;
}

export function urlForSearch(query = null) {
  const search = query?.toQueryString() || '';
  return `/search${search}`;
}

export function urlForBackToSearch(prevSearch = '', backToSearchListingType = null) {
  let filteredSearch = filterSearch(prevSearch);
  if (filteredSearch === '' && backToSearchListingType && backToSearchListingType !== 'for_sale') {
    filteredSearch = `?type=${encodeURIComponent(backToSearchListingType)}`;
  }
  return `/search${filteredSearch}`;
}

function filterSearch(search) {
  if (!search) {
    return '';
  }
  const parts = search
    .substring(1)
    .split('&')
    .filter((part) => {
      const key = part.split('=', 2)[0];
      return searchAttributes.contains(key);
    });
  if (parts.length === 0) {
    return '';
  }
  return `?${parts.join('&')}`;
}
