import React from 'react';
import PropTypes from 'prop-types';
import offset from 'document-offset';

import {getHeight, getWidth} from '../../../lib/node_utils';

let id = 1;

export default class Fixable extends React.Component {
  static propTypes = {
    // Content of this container.
    children: PropTypes.node,

    // Allows Z-index to be overridden.
    zIndex: PropTypes.number,

    // Behavior
    mode: PropTypes.oneOf(['never', 'always', 'upscroll']),

    // Optional height setting.
    height: PropTypes.number,

    // Optional width setting, if the width can change while the content element
    // is in a fixed position.
    width: PropTypes.number,
  };

  static defaultProps = {
    zIndex: 10000,
    mode: 'always',
  };

  state = {
    fixed: false,
    scrollDirection: null,

    // Static absolute Y position of the content, before we changed to a fixed
    // position
    staticTop: null,

    width: null,
    height: null,
  };

  constructor(props) {
    super(props);
    this._id = id++;
  }

  componentDidMount() {
    window.addEventListener('scroll', this._handleDocumentEvents);
    window.addEventListener('resize', this._handleDocumentEvents);
    window.addEventListener('touchmove', this._handleDocumentEvents);

    // When a user swipes very fast, and lets go of the touch event
    window.addEventListener('touchend', this._handleDocumentEvents);

    if (this.props.mode === 'always') {
      this._checkFixed();
    }
  }

  componentWillUnmount() {
    this._node = null;
    window.removeEventListener('resize', this._handleDocumentEvents);
    window.removeEventListener('scroll', this._handleDocumentEvents);
    window.removeEventListener('touchmove', this._handleDocumentEvents);
    window.removeEventListener('touchend', this._handleDocumentEvents);
  }

  componentDidUpdate(prevProps, prevState) {
    const domNode = this._node;

    if (
      this.state.fixed &&
      !this.props.width &&
      (this.state.windowWidth !== prevState.windowWidth ||
        this.state.windowHeight !== prevState.windowHeight)
    ) {
      const newWidth = getWidth(domNode.parentNode);
      if (newWidth !== this.state.width) {
        setTimeout(() => {
          this.setState({width: newWidth});
        }, 1000 / 30);
      }
    }

    switch (this.props.mode) {
      case 'never':
        this._setFixed(false);
        break;
      case 'always':
        if (
          this.state.windowWidth !== prevState.windowWidth ||
          this.state.windowHeight !== prevState.windowHeight ||
          this.state.scrollTop !== prevState.scrollTop
        ) {
          this._setFixed(this.state.scrollTop > this.state.staticTop);
        }
        break;
      case 'upscroll':
        if (
          this.state.windowWidth !== prevState.windowWidth ||
          this.state.windowHeight !== prevState.windowHeight
        ) {
          this._setFixed(false);
        } else if (this.state.scrollDirection !== prevState.scrollDirection) {
          this._setFixed(
            this.state.scrollDirection === 'up' &&
              this.state.scrollTop >= this.state.staticTop + getHeight(domNode)
          );
        } else if (this.state.fixed && this.state.scrollTop <= this.state.staticTop) {
          this._setFixed(false);
        }
        break;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.width !== this.props.width) {
      this.setState({width: nextProps.width});
    }
  }

  render() {
    if (this.state.fixed) {
      return (
        <div ref={(e) => (this._node = e)} className="Fixable" data-fixed>
          <div
            className="Fixable_content"
            style={{
              position: 'fixed',
              top: 0,
              width: this.state.width,
              height: this.props.height || this.state.height,
              zIndex: this.props.zIndex,
            }}>
            {this.props.children}
          </div>
          ,
          <div
            className="Fixable_placeholder"
            style={{
              width: this.state.width,
              height: this.state.height,
            }}
          />
        </div>
      );
    }

    const resetStyle = {width: 'auto'}; // Works around React bug where style isn't removed

    return (
      <div ref={(e) => (this._node = e)} className="Fixable" style={resetStyle}>
        <div className="Fixable_content">{this.props.children}</div>
      </div>
    );
  }

  _handleDocumentEvents = (/* event */) => {
    const domNode = this._node;
    if (!domNode) {
      return;
    }

    const {staticTop: lastStaticTop, scrollTop: lastScrollTop} = this.state;
    const scrollTop = window.pageYOffset || window.scrollY || window.scrollTop || 0;

    if (!this.state.fixed) {
      const staticTop = Math.max(0, (offset(domNode) || {}).top || 0);
      if (staticTop !== lastStaticTop) {
        this.setState({staticTop});
      }
    }

    if (scrollTop !== lastScrollTop) {
      this.setState({
        scrollTop,
        scrollDirection: scrollTop < lastScrollTop ? 'up' : 'down',
      });
    }

    if (
      window.innerWidth !== this.state.windowWidth ||
      window.innerHeight !== this.state.windowHeight
    ) {
      this.setState({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    }
  };

  _checkFixed = () => {
    this._setFixed(this.state.scrollTop > this.state.staticTop);
  };

  _setFixed = (fixed) => {
    if (!this._node) {
      return;
    }
    if (fixed !== this.state.fixed) {
      if (fixed) {
        this.setState({
          fixed: true,
          width: getWidth(this._node),
          height: getHeight(this._node),
        });
      } else {
        this.setState({
          fixed: false,
          width: null,
          height: null,
        });
      }
    }
  };
}
