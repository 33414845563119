import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {isArray} from 'underscore';
import capitalize from 'capitalize';
import highlighter from 'document-highlighter';
import {withRouter} from 'react-router';

import './listing_tile.scss';
import {truncateText} from '../../lib/node_utils';
import {formatPropertyType, shortenAddress} from '../../lib/support/search';
import {isNumberOrNumericRange} from '../../lib/utils/numbers';
import Configuration from '../../lib/configuration';
import {urlForListing} from '../../lib/support/routing';
import NumberOrRange from './common/NumberOrRange';
import {CalendarDateIcon} from './common/CalendarDateIcon';
import {ListingSearchResult} from '../../lib/search/ListingQuery';
import Platform from '../../lib/platform';
import {Listing} from '../../lib/models';

class ListingMiniStats extends React.Component {
  static propTypes = {
    query: PropTypes.object,
    listing: PropTypes.instanceOf(Listing).isRequired,
  };

  render() {
    const {listing, query} = this.props;
    const amount = listing.price || listing.rent;
    const amountRange = listing.price_range || listing.rent_range;
    const squareFootage = listing.size?.square_footage;
    const squareFootageRange = listing.size?.square_footage_range;
    const bedrooms = listing.bedrooms;
    const bathrooms = listing.bathrooms;

    let formattedMlsId;
    if (query) {
      const expectedMls = query.get('mls_number');
      if (expectedMls && !isArray(expectedMls)) {
        // Display MLS if specified in query. Manual highlighting
        // as engine apparently doesn't highlight keywords.
        const actualMls = listing.mls_number;
        formattedMlsId = (
          <span>
            #{' '}
            {String(expectedMls).toLowerCase() === String(actualMls).toLowerCase() ? (
              <span className="highlight">{actualMls}</span>
            ) : (
              actualMls
            )}
          </span>
        );
      }
    }

    return (
      <div className="ListingMiniStats">
        {(isNumberOrNumericRange(bedrooms) ||
          isNumberOrNumericRange(bedrooms && bedrooms.normalized_count_range)) && (
          <NumberOrRange
            value={bedrooms.normalized_count}
            range={bedrooms.normalized_count_range}
            unit="Bed"
            type="bedrooms"
          />
        )}
        {(isNumberOrNumericRange(bathrooms) ||
          isNumberOrNumericRange(bathrooms && bathrooms.normalized_count_range)) && (
          <NumberOrRange
            value={bathrooms.normalized_count}
            range={bathrooms.normalized_count_range}
            unit="Bath"
            trimZeros
          />
        )}
        {(isNumberOrNumericRange(squareFootage) || isNumberOrNumericRange(squareFootageRange)) && (
          <NumberOrRange value={squareFootage} range={squareFootageRange} unit="ft²" />
        )}
        {!listing.isOffMarket() && amountRange && (
          <NumberOrRange value={amountRange.min} currency="$" prefix="From" />
        )}
        {!listing.isOffMarket() && amount && !amountRange && (
          <NumberOrRange value={amount} currency="$" />
        )}
        {formattedMlsId}
      </div>
    );
  }
}

class ListingTile extends PureComponent {
  state = {
    hasFocus: false,
  };

  static propTypes = {
    layout: PropTypes.oneOf(['portrait', 'landscape']),
    compact: PropTypes.bool,
    showSponsoredLabel: PropTypes.bool,
    showFeaturedLabel: PropTypes.bool,
    showNewLabel: PropTypes.bool,
    showExactnessLabel: PropTypes.bool,
    showVideosLabel: PropTypes.bool,
    showDirectionsLink: PropTypes.bool,
    showSharingButton: PropTypes.bool,
    showEmailLink: PropTypes.bool,
    showMapHint: PropTypes.bool,
    showOpenHouseIcon: PropTypes.bool.isRequired,
    listing: PropTypes.instanceOf(Listing).isRequired,
    result: PropTypes.instanceOf(ListingSearchResult),
    query: PropTypes.object,
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    stickySearch: PropTypes.bool,
  };

  static defaultProps = {
    layout: 'portrait',
    compact: false,
    listing: null,
    query: null,
    showOpenHouseIcon: true,
    showExactnessLabel: true,
    showSponsoredLabel: true,
    showFeaturedLabel: true,
    showSharingButton: true,
    showNewLabel: true,
    showVideosLabel: true,
    showDirectionsLink: true,
    showEmailLink: true,
    showMapHint: false,
    stickySearch: false,
  };

  render() {
    const {listing, query, result, showVideosLabel} = this.props;

    const highlighting = (result ? result.highlighting : null) || {};

    const hasExactOrFuzzyState =
      this.props.showExactnessLabel && result && result.hasExactOrFuzzyState();

    let title;
    if (highlighting.street_address && !listing.undisclosed_address) {
      title = <span dangerouslySetInnerHTML={{__html: highlighting.street_address.join('')}} />;
    } else {
      title = truncateText(
        shortenAddress(
          (!listing.undisclosed_address && listing.street_address) ||
            listing.property_name ||
            (Configuration.get('ui.hide_undisclosed_addresses') ? '' : '[Undisclosed address]')
        ) || '',
        100
      );
    }

    let locationDescription = null;
    if (listing.city) {
      locationDescription =
        (highlighting.city && highlighting.city.join('')) || capitalize.words(listing.city);
    }

    const state = listing.state;
    if (state) {
      if (locationDescription) {
        locationDescription += ', ';
      } else {
        locationDescription = '';
      }
      locationDescription += (highlighting.state && highlighting.state.join('')) || state;
    }

    const zipCode = listing.zip_code;
    if (zipCode) {
      if (locationDescription) {
        locationDescription += ' ';
      } else {
        locationDescription = '';
      }
      locationDescription += (highlighting.zip_code && highlighting.zip_code.join('')) || zipCode;
    }

    const amount = listing.price || listing.rent;
    const previousPrice = listing.previousPrice();
    const priceChange = previousPrice && amount - previousPrice;

    const photoUrl = listing.getPrimaryPhotoUrl('medium');

    const hasVirtualTour = listing.hasVirtualTour();
    const hasVideo = showVideosLabel && listing.hasVideos();

    const formattedType = formatPropertyType(listing.property_type);

    const upcomingOpenHouseItem = this.props.showOpenHouseIcon
      ? listing.getUpcomingOpenHouseItems()[0]
      : null;

    const url = urlForListing(listing, this.props.stickySearch ? this.props.location.search : '');

    const exactOrFuzzy = hasExactOrFuzzyState ? result.isExactMatch() : null;

    return (
      <div className="ListingTile" data-listing-id={listing.id} data-layout={this.props.layout}>
        <div className="ListingTile_inside">
          <a
            className="ListingTile_card"
            href={url}
            data-compact={this.props.compact || null}
            data-exact={exactOrFuzzy != null ? exactOrFuzzy : null}
            onClick={this._handleClick}
            tabIndex="0"
            onMouseEnter={() => {
              this.setState({hasFocus: true});
              if (this.props.onHover) {
                this.props.onHover(listing);
              }
            }}
            onMouseLeave={() => {
              this.setState({hasFocus: false});
              if (this.props.onHover) {
                this.props.onHover(null);
              }
            }}>
            <div className="ListingTile_card_inside">
              {this.props.showSponsoredLabel && result && result.sponsored && (
                <span className="ListingTile_sponsored" aria-label="Sponsored listing" />
              )}

              {this.props.showFeaturedLabel &&
              listing.isFeatured() &&
              !(result && result.sponsored) ? (
                <span className="ListingTile_featured" aria-label="Featured listing" />
              ) : null}
              {this.props.showNewLabel &&
              listing.isNewerThan(14) &&
              !(result && result.sponsored) ? (
                listing.isNewerThan(1) ? (
                  <span className="ListingTile_justListed" aria-label="Just listed" />
                ) : (
                  <span className="ListingTile_new" aria-label="New listing" />
                )
              ) : null}
              {upcomingOpenHouseItem && (
                <span className="ListingTile_date" aria-label="Upcoming open house">
                  <CalendarDateIcon
                    date={upcomingOpenHouseItem.start}
                    virtual={upcomingOpenHouseItem.isVirtual}
                  />
                </span>
              )}
              <div className="ListingTile_photo">
                {photoUrl && (
                  <div
                    className="ListingTile_photo_background"
                    style={{backgroundImage: `url("${photoUrl}")`}}
                  />
                )}
                {hasVirtualTour || hasVideo ? (
                  <span
                    className="ListingTile_virtual_tour_video"
                    aria-label={`${hasVirtualTour ? 'Virtual Tour' : ''} ${
                      hasVideo ? 'Video' : ''
                    }`}
                  />
                ) : null}

                {this.props.showMapHint &&
                  this.state.hasFocus &&
                  !Platform.hasTouchEvents() &&
                  listing.getLatLng() && (
                    <div className="ListingTile_card_inside_map_zoom">
                      {'Hold '}
                      <strong>Shift</strong>
                      {' to show on map'}
                    </div>
                  )}
              </div>

              <div className="ListingTile_body">
                {(upcomingOpenHouseItem || {}).isVirtual && (
                  <div className="ListingTile_virtual_open_house">Virtual Open House</div>
                )}
                <div className="ListingTile_description">
                  {hasExactOrFuzzyState ? (
                    <div className="ListingTile_matches">
                      {
                        <span className="ListingTile_match_type">
                          {exactOrFuzzy ? (
                            <span className="ListingTile_match_label">{'Exact Match'}</span>
                          ) : (
                            <span className="ListingTile_match_label">{'Close Match'}</span>
                          )}
                        </span>
                      }
                    </div>
                  ) : (
                    <div className="ListingTile_matches" />
                  )}
                  <h3>{title}</h3>
                  <h4>
                    {locationDescription && (
                      <span dangerouslySetInnerHTML={{__html: locationDescription}} />
                    )}
                    {formattedType && <span>{formattedType}</span>}
                  </h4>
                  <ListingMiniStats listing={listing} query={query} />
                  {priceChange && (
                    <span
                      className="ListingTile_price_change"
                      data-change-type={priceChange < 0 ? 'down' : 'up'}>
                      <NumberOrRange value={priceChange} currency="$" />
                    </span>
                  )}

                  {query && query.get('nearest_schools') && listing.nearest_schools && (
                    <div className="ListingFilteredAttribute">
                      <h4>Schools</h4>
                      {['elementary', 'middle', 'high'].map(
                        (s) =>
                          listing.nearest_schools[s] && (
                            <div key={s}>
                              {highlighting[`nearest_schools/${s}/name`] ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: highlighting[`nearest_schools/${s}/name`][0],
                                  }}
                                />
                              ) : (
                                listing.nearest_schools[s].name
                              )}
                            </div>
                          )
                      )}

                      {(listing.nearest_schools.uncategorized_list || [])
                        .filter((s) => s != 'Unassigned')
                        .map((s) => {
                          const highlighted = highlighter.text(s, query.get('nearest_schools'), {
                            before: '<span class="highlight">',
                            after: '</span>',
                          });
                          return (
                            <div key={s} dangerouslySetInnerHTML={{__html: highlighted.text}} />
                          );
                        })}
                    </div>
                  )}

                  {query && query.get('subdivision') && listing.subdivision && (
                    <div className="ListingFilteredAttribute">
                      <h4>Subdivision</h4>
                      <div>
                        {highlighting.subdivision ? (
                          <span dangerouslySetInnerHTML={{__html: highlighting.subdivision[0]}} />
                        ) : (
                          listing.subdivision
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  }

  _handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.metaKey || event.altKey || event.ctrlKey) {
      return;
    }

    const {onClick, listing} = this.props;
    if (onClick) {
      onClick(listing, {});
    } else {
      window.location.href = urlForListing(
        listing,
        this.props.stickySearch ? this.props.location.search : ''
      );
    }
  };

  _handleModalToggle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
    }));
  };
}

export default connect()(withRouter(ListingTile));
