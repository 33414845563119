import L from 'leaflet';

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  ((tick) => {
    window.setTimeout(() => tick(Date.now()), 1000 / 30);
  });

function easeInOutSine(t, b, c, d) {
  return (-c / 2) * (Math.cos((Math.PI * t) / d) - 1) + b;
}

class MarkerPositionAnimation {
  constructor(marker, targetPosition, duration, onDone) {
    this.marker = marker;
    this.targetPosition = targetPosition;
    this.duration = duration;
    this.onDone = onDone;

    this._startPosition = marker.getLatLng();
    this._startTime = Date.now();
    this._running = false;

    this._tick = this._tick.bind(this);
  }

  start() {
    this._running = true;
    requestAnimationFrame(this._tick);
    return this;
  }

  stop() {
    if (this._running) {
      this._running = false;
      this.onDone();
    }
  }

  restart(targetPosition) {
    this._startTime = Date.now();
    this.targetPosition = targetPosition;
    if (!this._running) {
      this._running = true;
      requestAnimationFrame(this._tick);
    }
    return this;
  }

  _tick() {
    if (!this._running) {
      return;
    }

    const delta = Date.now() - this._startTime;
    if (delta >= this.duration) {
      this._running = false;
      this.onDone();
    }

    this.marker.setLatLng(
      L.latLng(
        easeInOutSine(
          delta,
          this._startPosition.lat,
          this.targetPosition.lat - this._startPosition.lat,
          this.duration
        ),
        easeInOutSine(
          delta,
          this._startPosition.lng,
          this.targetPosition.lng - this._startPosition.lng,
          this.duration
        )
      )
    );

    requestAnimationFrame(this._tick);
  }
}

export default MarkerPositionAnimation;
