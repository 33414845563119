import React from 'react';
import PropTypes from 'prop-types';

import {getWidth} from '../../lib/node_utils';
import {Balloon} from './Balloon.react';
import LayeredComponent from './LayeredComponent';

function findBlockLevelParent(element) {
  const parent = element.parentElement;
  if (parent) {
    switch (parent.tagName) {
      case 'ADDRESS':
      case 'BLOCKQUOTE':
      case 'DIV':
      case 'DL':
      case 'FIELDSET':
      case 'FORM':
      case 'H1':
      case 'H2':
      case 'H3':
      case 'H4':
      case 'H5':
      case 'H6':
      case 'HR':
      case 'NOSCRIPT':
      case 'OL':
      case 'P':
      case 'PRE':
      case 'TABLE':
      case 'UL':
      case 'DD':
      case 'DT':
      case 'LI':
      case 'TBODY':
      case 'TD':
      case 'TFOOT':
      case 'TH':
      case 'THEAD':
      case 'TR':
        return parent;

      default:
        if (parent === document.documentElement || parent === document.body) {
          return parent;
        } else {
          return findBlockLevelParent(parent);
        }
    }
  } else {
    return document.documentElement || document.body;
  }
}

export class TextField extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    size: PropTypes.number,

    // Used to decorate inputs, mostly so things like password managers can work.
    inputName: PropTypes.string,

    type: PropTypes.oneOf(['string', 'text', 'integer', 'password']),

    // Function of the form function(value) which returns either null or true if
    // the value is valid, otherwise either false, a string or a React element. If
    // a string or a React element is returned, it will be displayed in a tooltip.
    validator: PropTypes.func,

    onKeyPress: PropTypes.func,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    inline: PropTypes.bool,
    changeDelay: PropTypes.number,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    placeholder: '',
    type: 'string',
    inline: false,
    delayed: false,
    disabled: false,
    changeDelay: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      width: null,
    };
  }

  componentDidMount() {
    this._mounted = true;
    this._updateTemplate();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {value} = nextProps;
    if (value !== this.props.value && value !== this.state.value) {
      this.setState({value});
    }
  }

  render() {
    const {value} = this.state;
    const {disabled, validator} = this.props;
    let isValid = true;
    let validationResult;
    if (validator) {
      validationResult = validator(value);
      isValid = validationResult == null || validationResult === true;
    }

    const commonProps = {
      name: this.props.inputName,
      onKeyPress: this._handleInputKeyPress,
      onKeyUp: this._handleInputChange,
      onBlur: this._handleInputBlur,
      onChange: this._handleInputChange,
      onFocus: this._updateTemplate,
      disabled,
    };

    let content;
    switch (this.props.type) {
      case 'text':
        content = (
          <textarea
            ref={(e) => (this.input = e)}
            rows="5"
            cols={this.props.size}
            placeholder={this.props.placeholder}
            {...commonProps}
            value={value}
          />
        );
        break;

      case 'string':
      case 'integer':
        content = (
          <input
            ref={(e) => (this.input = e)}
            type="text"
            size={this.props.size}
            style={{width: this.state.width}}
            value={value || ''}
            placeholder={this.props.placeholder}
            {...commonProps}
          />
        );
        break;

      case 'password':
        content = (
          <input
            ref={(e) => (this.input = e)}
            type="password"
            size={this.props.size}
            style={{width: this.state.width}}
            value={value || ''}
            placeholder={this.props.placeholder}
            {...commonProps}
          />
        );
        break;

      default:
        content = null;
    }

    return (
      <span
        className="TextField"
        data-type={this.props.type}
        data-inline={this.props.inline}
        data-enabled={!disabled}
        data-is-valid={isValid}>
        {content}
        {this.props.inline && (
          <span className="TextField_template" ref={(e) => (this.template = e)}>
            {value}
          </span>
        )}
        {validationResult && (
          <LayeredComponent
            renderLayer={() => (
              <Balloon placement="below" parentElement={this.input.parentElement}>
                <span>{validationResult}</span>
              </Balloon>
            )}
          />
        )}
      </span>
    );
  }

  focus = () => {
    setTimeout(() => {
      if (this.input) {
        const node = this.input;
        if (node) {
          node.focus();
        }
      }
    }, 0);
  };

  getValue = () => {
    return this.state.value;
  };

  _handleInputBlur = () => {
    if (this.props.inline) {
      this._submit();
    }
  };

  _handleInputChange = () => {
    if (this._mounted && this.input) {
      const value = this.input.value;
      if (value !== this.state.value) {
        this.setState({value});
        this._updateTemplate(value);
      }
      this._scheduleChangeHandler();
    }
  };

  _scheduleChangeHandler = () => {
    if (this.props.onChange) {
      const handler = () => {
        if (this._mounted && this.input && this.props.onChange) {
          this.props.onChange(this.input.value);
        }
      };
      if (this.props.changeDelay != null) {
        if (this._updateTimeoutId) {
          window.clearTimeout(this._updateTimeoutId);
        }
        this._updateTimeoutId = window.setTimeout(handler, this.props.changeDelay);
      } else {
        handler();
      }
    }
  };

  _handleInputKeyPress = (event) => {
    if (event.which === 13) {
      this._submit();
    }

    const {onKeyPress} = this.props;
    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  _updateTemplate = (value = null) => {
    if (this.template) {
      this.template.value = value || this.state.value;
      setTimeout(this._updateWidth, 0);
    }
  };

  _updateWidth = () => {
    if (this.template) {
      const node = this.template;
      if (node) {
        const width = Math.min(getWidth(findBlockLevelParent(node)), getWidth(node));
        this.setState({width});
      }
    }
  };

  _submit = () => {
    const {onSubmit} = this.props;
    if (onSubmit) {
      onSubmit(this.state.value);
    }
  };
}
