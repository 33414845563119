import React, {Component} from 'react';
import PropTypes from 'prop-types';

export class Spinner extends Component {
  render() {
    return (
      <div className="Spinner">
        <div className="Spinner_content">
          {this.props.title && <div className="Spinner_content_title">{this.props.title}</div>}
          {this.props.children}
        </div>
      </div>
    );
  }
}

Spinner.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
