import {List} from 'immutable';
import {last, pairs, sortBy} from 'underscore';
import moment from 'moment';
import capitalize from 'capitalize';

import {formatNumberWithCommas} from '../node_utils';
import {formatNumberWithDecimals} from '../utils/text';

export function formatBedroomCount(count) {
  if (typeof count === 'number') {
    if (count === 0) {
      return 'Studio';
    } else {
      return count;
    }
  } else {
    return 'Unknown';
  }
}

export function shortenAddress(s) {
  if (s && s.trim) {
    return s
      .trim()
      .replace(/(ave)nue$/i, '$1')
      .replace(/(st)reet$/i, '$1')
      .replace(/(dr)ive$/i, '$1')
      .replace(/(l)a(n)e$/i, '$1$2')
      .replace(/(pl)ace$/i, '$1')
      .replace(/(r)oa(d)$/i, '$1$2')
      .replace(/(c)our(t)$/i, '$1$2');
  }
}

export function formatPrice(price, options) {
  let s = '';
  if (!(options && options.unit === false)) {
    s += '$';
  }

  if (price <= 10000) {
    s += price;
  } else if (price < 1000000 && price > 10000) {
    s += `${price / 1000}k`;
  } else {
    s += `${price / 1000000}M`;
  }
  return s;
}

const PROPERTY_TYPE_MAPPINGS = {
  mobile_manufactured: 'mobile/manu.',
  condominium: 'condo',
  apartment: 'apt',
  lots_land: 'lot/land',
  multifamily: 'multi-family',
};

export function formatPropertyType(type) {
  if (Array.isArray(type)) {
    return type.map(formatPropertyType).join(', ');
  }
  if (typeof type === 'string') {
    type = type.toLowerCase();
    return capitalize.words(PROPERTY_TYPE_MAPPINGS[type] || type.replace(/_/g, ' '));
  }
}

export function getFacetAsItems(
  facet,
  {includeAny, ignoreKeys, onlyKeys, ignoreEmpty, mutex, formatter} = {}
) {
  const facetData = facet && facet.data;
  const items = [];
  if (includeAny !== false && (includeAny || (!mutex && !ignoreEmpty))) {
    items.push({value: null, label: 'Any'});
  }
  if (facetData) {
    let keys = Object.keys(facetData);
    if (ignoreKeys) {
      keys = keys.filter((k) => ignoreKeys.indexOf(k) === -1);
    }
    if (onlyKeys) {
      keys = keys.filter((k) => onlyKeys.indexOf(k) !== -1);
    }
    if (ignoreEmpty) {
      keys = keys.filter((k) => !(k && facetData[k].count === 0 && !facetData[k].selected));
    }
    keys.forEach((key) => {
      const datum = facetData[key];
      const label = datum.label || key;
      items.push({
        value: key,
        label: formatter ? formatter(label) : label,
        count: datum.count,
      });
    });
  }
  return items;
}

function format(value, type) {
  switch (type) {
    case 'price':
      return formatPrice(value);
    case 'numberWithCommas':
      return formatNumberWithCommas(value);
    case 'numberWithDecimals': {
      const number = +value;
      const rounded = Math.round(number);
      if (rounded !== number) {
        return formatNumberWithDecimals(number);
      }
      return rounded;
    }
  }
  return value;
}

export function getStartRangeFacetAsItems(facet, {includeAny, type, max, formatter} = {}) {
  const facetData = facet && facet.data;
  const items = [];

  if (facetData) {
    const data = sortBy(
      pairs(facetData)
        .filter(([_, datum]) => !!datum)
        .map(([key, datum]) => Object.assign({id: +key}, datum)),
      (datum) => (datum.bounds ? datum.bounds.min : Infinity)
    );

    data.forEach((datum) => {
      // FIXME: This uses numbers for the value, but during querying, we rely
      //   on facets being identified by their label string. ListingQuery currently
      //   normalizes these back to strings to make this work.
      const value = datum.bounds.min || 0;
      if (!max || value < max) {
        const lastItem = last(items);
        if (lastItem && lastItem.count === datum.count) {
          items.pop();
        }
        items.push({
          value: datum.id,
          label: formatter ? formatter(value) : format(value, type),
          count: datum.count,
        });
      }
    });
  }

  if (includeAny) {
    items.unshift({value: null, label: 'Any'});
  }
  return items;
}

export function getEndRangeFacetAsItems(facet, {includeAny, type, min, formatter} = {}) {
  const facetData = facet && facet.data;
  const items = [];

  if (facetData) {
    const data = sortBy(
      pairs(facetData)
        .filter(([_, datum]) => !!datum)
        .map(([key, datum]) => Object.assign({id: +key}, datum)),
      (datum) => (datum.bounds ? datum.bounds.max : Infinity)
    );

    let prevItem;
    data.forEach((datum) => {
      if (!(!min || min < datum.bounds.max)) {
        return;
      }
      // FIXME: This uses numbers for the value, but during querying, we rely
      //   on facets being identified by their label string. ListingQuery currently
      //   normalizes these back to strings to make this work.
      const value = datum.bounds.max;
      if (value == null) {
        return;
      }
      if (prevItem != null && prevItem.count === datum.count) {
        return;
      }
      prevItem = {
        value: datum.id,
        label: formatter ? formatter(value) : format(value, type),
        count: datum.count,
      };
      items.push(prevItem);
    });
  }

  if (includeAny) {
    items.unshift({value: null, label: 'Any'});
  }
  return items;
}

export function formatRange(range, formatter = (v) => v) {
  if (!range || (range.min == null && range.max == null)) {
    return null;
  }
  if (range.min != null && range.max != null) {
    return `${formatter(range.min)} – ${formatter(range.max)}`;
  }
  if (range.min != null) {
    return `${formatter(range.min)}+`;
  }
  return `<${formatter(range.max)}`;
}

export function formatPriceRange(range) {
  return formatRange(range, formatPrice);
}

export function formatSquareFootageRange(range) {
  return formatRange(range, formatNumberWithCommas);
}

export function countSetValues(query, attributeNames) {
  return List(attributeNames)
    .map((name) => query.get(name) || query.get(`${name}_min`) || query.get(`${name}_max`))
    .filterNot((v) => v == null)
    .filterNot((v) => Array.isArray(v) && v.length === 0)
    .filterNot((v) => typeof v === 'string' && v.trim().length === 0).size;
}

function compactTime(time, skipPeriod = null) {
  let formatSpec = 'h';
  if (time.minute() !== 0) {
    formatSpec = `${formatSpec}:mm`;
  }
  if (time.format('a') !== (skipPeriod || '').toLowerCase()) {
    formatSpec = `${formatSpec} A`;
  }
  return time.format(formatSpec);
}

export function formatOpenHouseDateItem(item, options = {}) {
  let description = '';
  if (item.start.hour() === 0) {
    // Midnight, don't show time
  } else if (item.start.isSame(item.end)) {
    description = compactTime(item.start);
  } else {
    description = `${compactTime(item.start, item.end.format('a'))} — ${compactTime(item.end)}`;
  }
  if (options.includeDate) {
    if (description.length) {
      description += ', ';
    }
    description += item.start.format('D MMM');
  }
  return description;
}

export const OPEN_HOUSE_ITEMS = [
  {
    value: null,
    label: "Don't filter",
  },
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Today or tomorrow',
    value: 'today_or_tomorrow',
  },
  {
    label: 'Next 7 days',
    value: 'next_7_days',
  },
  {
    label: 'Next 14 days',
    value: 'next_14_days',
  },
  {
    label: 'Next 30 days',
    value: 'next_30_days',
  },
];

export const PUBLISHING_TIMEFRAME_LAST_14_DAYS = 'LAST_14_DAYS';
export const PUBLISHING_TIMEFRAME_LAST_7_DAYS = 'LAST_7_DAYS';
export const PUBLISHING_TIMEFRAME_LAST_1_DAY = 'LAST_1_DAY';

export const NEW_LISTING_DATE_ITEMS = [
  {
    label: "Don't filter",
    value: null,
  },
  {
    label: 'Last 14 days',
    value: PUBLISHING_TIMEFRAME_LAST_14_DAYS,
    fn: () => {
      return moment().startOf('day').subtract(14, 'days').toDate();
    },
  },
  {
    label: 'Last 7 days',
    value: PUBLISHING_TIMEFRAME_LAST_7_DAYS,
    fn: () => {
      return moment().startOf('day').subtract(7, 'days').toDate();
    },
  },
  {
    label: 'Last 1 day',
    value: PUBLISHING_TIMEFRAME_LAST_1_DAY,
    fn: () => {
      return moment().startOf('day').subtract(1, 'days').toDate();
    },
  },
];
