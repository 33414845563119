import React from 'react';
import PropTypes from 'prop-types';

import styles from './CMMortgageCalculatorDonutBranded.module.scss';

// https://ads.cordlessmedia.com/documentation/index.html#brandedDonutChartMtg.md
export class CMMortgageCalculatorDonutBranded extends React.Component {
  static propTypes = {
    address: PropTypes.string.isRequired,
    pid: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    apt: PropTypes.string,
    price: PropTypes.string,
    downPayment: PropTypes.string,
    interestRate: PropTypes.string,
    hoa: PropTypes.string,
    homeIns: PropTypes.string,
    propTax: PropTypes.string,
    chartColor: PropTypes.arrayOf(PropTypes.string),
    linkColor: PropTypes.string,
  };

  async componentDidMount() {
    const {
      address,
      pid,
      zip,
      apt,
      price,

      downPayment,
      interestRate,
      hoa,
      homeIns,
      propTax,
      chartColor,
      linkColor,
    } = this.props;
    /*eslint-disable */
    (function (w, d, s, o, f, js, fjs) {
      w['CM-BDCWidget'] = o; w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
      js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
      js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
    }(window, document, 'script', 'cmbdcw', 'https://cdn.cordlessmedia.com/branded-calc/widget.js'));
    /*eslint-enable */
    window.cmbdcw('message', {
      address,
      pid,
      zip,
      apt,
      price,
      downPayment,
      interestRate,
      hoa,
      homeIns,
      propTax,
      chartColor,
      linkColor,
    });
  }

  componentWillUnmount() {
    // prevent the widget growing as you view multiple listings
    window.cmbdcw.q.length = 0;
  }

  render() {
    return (
      <div className={styles.root}>
        <div id="cmbdcwidget" />
      </div>
    );
  }
}
