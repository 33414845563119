import L from 'leaflet';
import {find, each} from 'underscore';

import exactIcon from '../../images/pins/map_pin_house_21ad41_25x31.png';
import closeIcon from '../../images/pins/map_pin_house_242d35_25x31.png';
import {formatNumberWithCommas} from '../node_utils';
import MarkerPositionAnimation from './MarkerPositionAnimation';

const MAX_BUBBLE_RADIUS_PIXELS = 50; // Largest bubble radius to render

function toLatLng(array) {
  return {lat: array[0], lng: array[1]};
}

L.ClusterBubble = L.Icon.extend({
  options: {
    exact: false,
    radius: 0,
    label: null,
    fontSize: 10,
    shadowAnchor: [0, 0],
    shadowSize: [0, 0],
  },

  createIcon(/* oldIcon */) {
    const {exact, radius, fontSize, label} = this.options;

    const div = document.createElement('div');
    div.setAttribute('data-exact', exact ? 'true' : 'false');
    this._setIconStyles(div, 'cluster');

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    div.appendChild(svg);
    svg.setAttribute('width', radius * 2);
    svg.setAttribute('height', radius * 2);

    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    svg.appendChild(circle);
    circle.setAttribute('cx', radius);
    circle.setAttribute('cy', radius);
    circle.setAttribute('r', radius);

    if (label) {
      const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      svg.appendChild(text);
      text.setAttribute('x', radius);
      text.setAttribute('y', radius);
      text.setAttribute('style', `font-size:${fontSize}px`);
      text.appendChild(document.createTextNode(label));
    }

    return div;
  },

  createShadow() {
    return null;
  },
});

const ClusterLayer = L.Handler.extend({
  includes: L.Evented.prototype,

  initialize(map, maxZoomLevel) {
    L.Handler.prototype.initialize.call(this, map);

    this._maxZoomLevel = maxZoomLevel;
    this._container = map._container;
    this._hoveringCluster = null;
    this._clusters = [];
    this._previousZoomLevel = null;
    this._markers = {};
    this._animations = {};
    this._pendingClusters = [];

    this._exactIcon = L.icon({
      iconUrl: exactIcon,
      iconSize: [25, 31],
      iconAnchor: [25 / 2, 31],
    });

    this._closeIcon = L.icon({
      iconUrl: closeIcon,
      iconSize: [25, 31],
      iconAnchor: [25 / 2, 31],
    });

    this._handleZoomStart = this._handleZoomStart.bind(this);
    this._handleZoomEnd = this._handleZoomEnd.bind(this);
  },

  setOptions(options) {
    L.setOptions(this, options);
  },

  addHooks() {
    if (!this._map) {
      return;
    }

    this._zooming = false;

    this._map.on('zoomstart', this._handleZoomStart).on('zoomend', this._handleZoomEnd);

    this._setHover(null);

    this._ensureGroup();

    this._clusters.forEach((cluster) => {
      this._addMarker(cluster);
    });
  },

  removeHooks() {
    if (this._map) {
      this._map.off('zoomstart', this._handleZoomStart).off('zoomend', this._handleZoomEnd);
      if (this._group) {
        this._map.removeLayer(this._group);
      }
    }
    this._group = null;
    this._markers = {};
    this._previousZoomLevel = null;
  },

  _setHover(cluster) {
    if (cluster !== this._hoveringCluster) {
      this._hoveringCluster = cluster;
      this.fire('hover', cluster);
    }
  },

  _handleZoomStart() {
    this._zooming = true;
  },

  _handleZoomEnd() {
    this._zooming = false;
    if (this._pendingClusters.length) {
      this._repopulate();
    }
  },

  _handleMouseDown() {
    this._bounds = this._map.getBounds();
  },

  _handleClick(cluster) {
    // Hack to avoid bogus taps
    if (this._bounds != null && !this._bounds.equals(this._map.getBounds())) {
      return;
    }
    this.fire('click', cluster);
  },

  setClusters(clusters, hasExactCriteria) {
    this._hasExactCriteria = hasExactCriteria;
    this._pendingClusters = clusters || [];
    if (!this._zooming) {
      this._repopulate();
    }
  },

  _repopulate() {
    const clusters = this._pendingClusters;
    this._pendingClusters = [];

    const group = this._ensureGroup();

    const newClusters = [];
    const oldClusters = this._clusters;
    const keepClusters = {};

    const zoomLevel = this._map.getZoom();
    const previousZoomLevel = this._previousZoomLevel || zoomLevel;
    const shouldAnimate = Math.abs(zoomLevel - previousZoomLevel) == 1;
    if (!shouldAnimate) {
      each(this._animations, (animation) => {
        animation.stop();
      });
      this._animations = {};
    }

    const pendingMovements = [];
    const pendingRemovals = [];

    clusters.forEach((cluster) => {
      const old = find(
        oldClusters,
        (c) => c.id === cluster.id && c.exact === cluster.exact && c.fuzzy == cluster.fuzzy
      );
      if (old) {
        keepClusters[old.id] = true;
      } else {
        newClusters.push(cluster);
      }
    });

    oldClusters.forEach((cluster) => {
      const marker = this._markers[cluster.id];
      if (marker) {
        if (keepClusters[cluster.id]) {
          marker.setIcon(this._iconForCluster(cluster));
        } else {
          const bounds = L.latLngBounds(
            L.latLng(toLatLng(cluster.b.min)),
            L.latLng(toLatLng(cluster.b.max))
          );

          const parent = find(newClusters, (newCluster) => {
            if (newCluster.subset === cluster.subset) {
              const newBounds = L.latLngBounds(
                L.latLng(toLatLng(newCluster.b.min)),
                L.latLng(toLatLng(newCluster.b.max))
              );
              if (bounds.contains(newBounds) || newBounds.contains(bounds)) {
                return true;
              }
            }
          });

          delete this._markers[cluster.id];

          if (parent && shouldAnimate) {
            pendingRemovals.push(marker);
            pendingMovements.push([cluster, marker, L.latLng(parent.p), 0.0]);
          } else {
            group.removeLayer(marker);
            delete this._animations[cluster.id];
          }
        }
      }
    });

    newClusters.forEach((cluster) => {
      const bounds = L.latLngBounds(
        L.latLng(toLatLng(cluster.b.min)),
        L.latLng(toLatLng(cluster.b.max))
      );

      const parent = find(oldClusters, (oldCluster) => {
        if (oldCluster.subset === cluster.subset) {
          const oldBounds = L.latLngBounds(
            L.latLng(toLatLng(oldCluster.b.min)),
            L.latLng(toLatLng(oldCluster.b.max))
          );
          if (bounds.contains(oldBounds) || oldBounds.contains(bounds)) {
            return true;
          }
        }
      });

      const marker = this._addMarker(cluster);

      if (parent && shouldAnimate) {
        const point = L.latLng(toLatLng(cluster.p));
        const parentPoint = L.latLng(toLatLng(parent.p));
        if (!point.equals(parentPoint)) {
          marker.setOpacity(0).setLatLng(parentPoint);
          pendingMovements.push([cluster, marker, point, 1.0]);
        }
      }
    });

    if (pendingMovements.length > 0) {
      setTimeout(() => {
        pendingMovements.forEach(([cluster, marker, latLng, opacity]) => {
          marker.setOpacity(opacity);

          const animation = this._animations[cluster.id];
          if (animation) {
            animation.restart(latLng);
          } else {
            this._animations[cluster.id] = new MarkerPositionAnimation(marker, latLng, 300, () => {
              delete this._animations[cluster.id];
            }).start();
          }
        });
      }, 0);
    }

    if (pendingRemovals.length > 0) {
      setTimeout(() => {
        pendingRemovals.forEach((marker) => {
          group.removeLayer(marker);
        });
      }, 500); // Must be as long as transition time
    }

    this._clusters = clusters;
    this._previousZoomLevel = zoomLevel;
    return this;
  },

  _addMarker(cluster) {
    const group = this._ensureGroup();

    let marker = this._markers[cluster.id];
    if (marker) {
      // This should never happen, but we do it to be on the safe side
      group.removeLayer(marker);
      delete this._markers[cluster.id];
      delete this._animations[cluster.id];
    }

    marker = this._createMarker(cluster);
    if (marker) {
      group.addLayer(marker);
      this._markers[cluster.id] = marker;
    }
    return marker;
  },

  _createMarker(cluster) {
    const exact = this._hasExactCriteria && cluster.fuzzy === 0;

    return new L.Marker(L.latLng(toLatLng(cluster.p)), {
      icon: this._iconForCluster(cluster),
      riseOnHover: true,
      pane: 'overlayPane',
      zIndexOffset: exact ? -10000 : -20000,
    })
      .on('mouseover', () => this._setHover(cluster))
      .on('mouseout', () => this._setHover(null))
      .on('mousedown', () => this._handleMouseDown())
      .on('touchstart', () => this._handleMouseDown())
      .on('click', () => this._handleClick(cluster));
  },

  _iconForCluster(cluster) {
    const zoomLevel = this._map.getZoom();
    const hasQuery = this._hasExactCriteria;
    const baseFontSize = 12;
    const padding = 10;

    const count = cluster.exact + cluster.fuzzy;
    const exact = hasQuery && cluster.exact === count;

    const label = formatNumberWithCommas(cluster.exact || cluster.fuzzy);

    let fontSize = baseFontSize;
    if (count < 100) {
      if (count < 10) {
        fontSize *= 0.8;
      } else {
        fontSize *= 0.9;
      }
    } else if (count > 1000) {
      fontSize *= 1.5;
    }
    const digitSize = fontSize / 1.8;
    const coverageRadius = Math.min(
      metersToPixels(cluster.r, cluster.p[0], zoomLevel),
      MAX_BUBBLE_RADIUS_PIXELS
    );
    const minRadius = padding + (digitSize * label.length) / 2;
    const radius = Math.round(
      minRadius + Math.max(0, (coverageRadius - minRadius) * Math.min(1, count / 1000))
    );

    if (this._map.getZoom() <= this._maxZoomLevel) {
      return new L.ClusterBubble({
        iconSize: [radius * 2, radius * 2],
        iconAnchor: [radius, radius],
        radius,
        exact,
        fontSize,
        label,
      });
    } else {
      return exact ? this._exactIcon : this._closeIcon;
    }

    function metersPerPixel(latitude, zoom) {
      const earthCircumference = 40075017;
      const latitudeRadians = latitude * (Math.PI / 180);
      return (earthCircumference * Math.cos(latitudeRadians)) / Math.pow(2, zoom + 8);
    }

    function metersToPixels(m, latitude, zoom) {
      return m / metersPerPixel(latitude, zoom);
    }
  },

  _ensureGroup() {
    let group = this._group;
    if (!group) {
      group = this._group = new L.LayerGroup();
      this._map.addLayer(group);
    }
    return group;
  },
});

export default ClusterLayer;
