import React from 'react';
import PropTypes from 'prop-types';

import styles from './FixedSidebarSplitView.module.scss';
import {getWindowScrollTop} from '../../../lib/node_utils';
import Fixable from './Fixable';
import Sizer from './Sizer';

/**
 * A component that displays a view with a sidebar. The sidebar is pinned
 * so that it never scrolls, even as the main content scrolls. Its height
 * is always set to be equal to that of the window, minus its offset within
 * the page.
 */
export class FixedSidebarSplitView extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    sidebar: PropTypes.func,
  };

  render() {
    return (
      <Sizer
        child={({top, height}) => {
          let h;
          if (height > 0) {
            const scrollTop = getWindowScrollTop();
            h = Math.min(window.innerHeight, Math.max(0, window.innerHeight - top + scrollTop));
            const bottom = top + height;
            if (bottom < window.innerHeight) {
              h = bottom;
            }
          }
          if (this.props.sidebar == null || h == null) {
            // TODO: With React >= 16 we can use a fragment instead of a div
            return <div>{this.props.children}</div>;
          }
          return (
            <div className={styles.root}>
              <div className={styles.main}>{this.props.children}</div>
              <div className={styles.sidebar}>
                <Fixable zIndex={999} height={h}>
                  {this.props.sidebar({height: h})}
                </Fixable>
              </div>
            </div>
          );
        }}
      />
    );
  }
}
