import {Promise} from 'bluebird';

export default class ScriptInjector {
  _state = 'notLoaded';
  _error = null;

  constructor(url) {
    this._url = url;
  }

  load() {
    switch (this._state) {
      case 'notLoaded':
        return this._load();
      case 'loaded':
        return Promise.resolve();
      case 'loading':
        return this._waitForLoad();
      case 'failed':
        return Promise.reject(this._error);
      default:
        return Promise.reject(new Error(`ScriptInjector: Invalid state: ${this._state}`));
    }
  }

  _load() {
    this._state = 'loading';
    this._deadline = Date.now() + deadline;
    return new Promise((resolve, reject) => {
      let done = false;
      const element = document.createElement('script');
      element.src = this._url;
      element.addEventListener(
        'load',
        () => {
          if (!done) {
            done = true;
            this._setLoaded();
            resolve();
          }
        },
        false
      );
      element.addEventListener(
        'error',
        () => {
          if (!done) {
            done = true;
            reject(this._setFailedWithErr(this._createError('unknown')));
          }
        },
        false
      );
      document.body.appendChild(element);
      setTimeout(() => {
        if (this._state !== 'loaded' && !done) {
          done = true;
          reject(this._setFailedWithErr(this._createError('timeout')));
        }
      }, deadline);
    });
  }

  _waitForLoad() {
    return new Promise((resolve, reject) => {
      poll(1000, () => {
        switch (this._state) {
          case 'notLoaded':
          case 'loaded':
            resolve();
            return true;
          case 'failed':
            reject(this._error);
            return true;
          case 'loading':
            if (Date.now() >= this._deadline) {
              reject(this._setFailedWithErr(this._createError('timeout')));
              return true;
            }
            return false;
          default:
            reject(new Error(`ScriptInjector: Invalid state: ${this._state}`));
            return true;
        }
      });
    });
  }

  _setLoaded() {
    if (this._state === 'loading') {
      this._state = 'loaded';
    }
  }

  _setFailedWithErr(err) {
    if (this._state === 'loading') {
      this._state = 'failed';
      this._error = err;
    }
    return err;
  }

  _createError(reason) {
    return new Error(`Script failed to load (${reason}): ${this._url}`);
  }
}

// Calls a function repeatedly until it returns true.
function poll(interval, f) {
  setTimeout(() => {
    if (f() !== true) {
      poll(interval, f);
    }
  }, interval);
}

const deadline = 5000;
