import moment from 'moment';
import crypto from 'crypto';

import {lookupKeyPath} from './utils/keypaths';

class Configuration {
  constructor() {
    this.configure(null);
  }

  get(keypath = null) {
    if (!keypath) {
      return this._site;
    }
    return lookupKeyPath(keypath, this._site);
  }

  configure(site) {
    this._site = site;
  }

  getSiteChecksum() {
    const raw = this.get('updated_at');
    if (!raw) {
      return null;
    }
    const updatedAt = moment(raw, moment.ISO_8601);
    return crypto.createHash('md5').update(updatedAt.toISOString()).digest('hex');
  }
}

export default new Configuration();
