import L from 'leaflet';
import {Map} from 'immutable';
import {isArray} from 'underscore';
import LZString from 'lz-string';
import {fromByteArray, toByteArray} from 'base64-js';

import {parseLatLng, parseLatLngBounds} from '../utils/geo';

const EMPTY = '-';

export class ObjectReference {
  constructor(uid) {
    this.uid = uid;
  }
}

export function deserializeQueryString(string) {
  let parsed = Map();
  if (string && string != EMPTY) {
    for (const part of string.split('&')) {
      const [strName, strValue] = part.split('=');
      if (strName.length === 0 || strValue.length === 0) {
        continue;
      }

      const [name, value] = deserializePair(strName, strValue);
      if (name && value) {
        parsed = parsed.set(name, value);
      }
    }
  }
  return parsed;
}

// Deserializes parameter serialized with `serializePair()`.
function deserializePair(strName, strValue) {
  const [name, type] = parseType(strName);
  switch (type) {
    case TYPE_STRING:
      return [name, decodeURIComponent(strValue)];
    case TYPE_BOOLEAN:
      return [name, strValue === 'true'];
    case TYPE_BASE64_JSON:
      return [name, deserializeBase64JSON(strValue)];
    case TYPE_BASE64_LZ_JSON:
      return [name, deserializeBase64LZJSON(strValue)];
    case TYPE_NUMBER:
      return [name, parseFloat(strValue)];
    case TYPE_OBJECT_REFERENCE:
      return [name, new ObjectReference(strValue)];
    case TYPE_LATLNG:
      return [name, parseLatLng(strValue)];
    case TYPE_LATLNG_BOUNDS:
      return [name, parseLatLngBounds(strValue)];
  }
  throw new Error(`Invalid param type "${type}" for ${name}`);
}

export function serializeQueryString(attributes) {
  let s = null;
  for (const [name, value] of Object.entries(attributes || {})) {
    if (name.length > 0 && name[0] === '_') {
      continue;
    }

    const [strName, strValue] = serializePair(name, value);
    if (strName == null || strValue == null) {
      continue;
    }

    if (s) {
      s += '&';
    } else {
      s = '';
    }
    s += `${strName}=${strValue}`;
  }
  return s || EMPTY;
}

// Serializes a canonical key/value as a query string key/value:
//
// * A null value is ignored.
// * An empty array is ignored.
// * Arrays and hashes (maps) become encoded as <k>:b=<Base64(JSON(value))>.
// * A model object reference becomes `<k>:r=<uid>`.
// * All other values become `<k>=<v>` where v is stringified with `toString()`.
//
function serializePair(name, value) {
  if (value == null) {
    return null;
  }

  if (isArray(value)) {
    if (value.length === 0) {
      return null;
    }
    return [`${name}:${TYPE_BASE64_JSON}`, serializeBase64JSON(value)];
  }

  if (value instanceof L.LatLng) {
    return [`${name}:${TYPE_LATLNG}`, `${value.lat},${value.lng}`];
  }

  if (value instanceof L.LatLngBounds) {
    return [
      `${name}:${TYPE_LATLNG_BOUNDS}`,
      `${value.getNorth()},${value.getEast()},${value.getSouth()},${value.getWest()}`,
    ];
  }

  if (value instanceof ObjectReference) {
    return [`${name}:${TYPE_OBJECT_REFERENCE}`, simpleEncodeUri(value.uid)];
  }

  switch (typeof value) {
    case 'number':
      return [`${name}:${TYPE_NUMBER}`, value.toString()];
    case 'boolean':
      return [`${name}:${TYPE_BOOLEAN}`, value.toString()];
    case 'string':
      return [name, simpleEncodeUri(value)];
  }

  throw new Error(
    `Cannot serialize unsupported value "${name}" (type ${typeof value}): ${JSON.stringify(value)}`
  );
}

function parseType(name) {
  const match = /^(.*?):(.+)$/.exec(name);
  if (!match) {
    return [name, TYPE_STRING];
  }
  return [match[1], match[2]];
}

function simpleEncodeUri(value) {
  return value
    .replace('[', '%5b')
    .replace(']', '%5d')
    .replace('+', '%2b')
    .replace(' ', '%20')
    .replace(',', '%2c');
}

function deserializeBase64LZJSON(base64) {
  try {
    return JSON.parse(LZString.decompressFromBase64(base64));
  } catch (e) {
    console.error(e);
    console.error('Value was', base64);
    return null;
  }
}

function serializeBase64JSON(value) {
  return base64Encode(JSON.stringify(value));
}

function deserializeBase64JSON(base64) {
  try {
    return JSON.parse(base64Decode(base64));
  } catch (e) {
    console.error(e);
    console.error('Value was', base64);
    return null;
  }
}

function base64Decode(s) {
  // Work around issue with base64-js being too strict
  const needLength = Math.ceil(s.length / 4.0) * 4;
  while (s.length < needLength) {
    s += '=';
  }

  let result = '';
  const bytes = toByteArray(s);
  for (let i = 0; i < bytes.length; i++) {
    result += String.fromCharCode(bytes[i]);
  }
  return result;
}

function base64Encode(s) {
  const bytes = [];
  for (let i = 0; i < s.length; i++) {
    bytes.push(s.charCodeAt(i));
  }
  return fromByteArray(bytes).replace(/=+$/, '');
}

// Type suffixes
const TYPE_STRING = 's';
const TYPE_BOOLEAN = 'B';
const TYPE_BASE64_LZ_JSON = 'b';
const TYPE_BASE64_JSON = 'j';
const TYPE_NUMBER = 'n';
const TYPE_OBJECT_REFERENCE = 'r';
const TYPE_LATLNG = 'll';
const TYPE_LATLNG_BOUNDS = 'llb';
