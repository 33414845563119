export function linkState(component, key) {
  const cache = component.__linkStateHandlers || (component.__linkStateHandlers = {});
  return cache[key] || (cache[key] = createValueChangeHandler(component, key));
}

// Adapted from https://blog.rescale.com/addressing-valuelink-deprecation-in-react-15/
function createValueChangeHandler(component, key) {
  return (e) => {
    const el = e.target;
    const value = el.type === 'checkbox' ? el.checked : el.value;
    component.setState({
      [key]: value,
    });
  };
}
