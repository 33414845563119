import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import styles from './CalendarDateIcon.module.scss';

export class CalendarDateIcon extends React.PureComponent {
  static propTypes = {
    date: PropTypes.object,
    active: PropTypes.bool,
    virtual: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'big']),
  };

  static defaultProps = {
    active: true,
    virtual: false,
    size: 'small',
  };

  render() {
    const date = moment(this.props.date);
    return (
      <div
        className={styles.root}
        data-active={this.props.active}
        data-virtual={this.props.virtual}
        data-size={this.props.size}
        aria-label={date.format('MMMM D')}>
        <div className={styles.month}>{date.format('MMM')}</div>
        <div className={styles.day}>{date.format('D')}</div>
      </div>
    );
  }
}
