import React from 'react';
import PropTypes from 'prop-types';
import {flatten} from 'underscore';

import styles from './OpenHouseBar.module.scss';
import {CalendarDateIcon} from '../common/CalendarDateIcon';
import {formatOpenHouseDateItem} from '../../../lib/support/search';

export class OpenHouseBar extends React.PureComponent {
  static propTypes = {
    listing: PropTypes.object,
  };

  render() {
    const {listing} = this.props;
    const openHouseItems = listing.getUpcomingOpenHouseItems();
    return (
      <div className={styles.root}>
        <span className={styles.title}>
          {openHouseItems.length > 1 ? 'Open Houses' : 'Open House'}
        </span>
        {flatten(
          openHouseItems.map((item, idx) => {
            const description = formatOpenHouseDateItem(item);
            return [
              <span key={`${idx}-date`}>
                <CalendarDateIcon
                  date={item.start}
                  active={false}
                  size={'big'}
                  virtual={item.isVirtual}
                />
              </span>,
              <div className={styles.details} key={`${idx}-details`}>
                {description && (
                  <span key={`${idx}-hours`} className={styles.hours}>
                    {description}
                  </span>
                )}
                {item.isVirtual && (
                  <span key={`${idx}-virtual`} className={styles.virtualTourLink}>
                    <a href={item.url} target="_blank">
                      Virtual Open House Link
                    </a>
                  </span>
                )}
              </div>,
            ];
          })
        )}
      </div>
    );
  }
}
