import React from 'react';
import PropTypes from 'prop-types';

export class Checkbox extends React.PureComponent {
  static propTypes = {
    checked: PropTypes.bool,
    enabled: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    checked: false,
    enabled: true,
    onChange() {},
    onClick() {},
  };

  state = {
    checked: this.props.checked,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {checked} = nextProps;
    if (checked !== this.state.checked) {
      this.setState({checked});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {checked} = this.state;
    if (prevState.checked !== checked) {
      this.props.onChange(checked);
    }
  }

  render() {
    return (
      <span
        className="Checkbox"
        data-checked={this.state.checked}
        data-enabled={this.props.enabled}
        onClick={(event) => this._handleClick(event)}>
        <span className="Checkbox_value" />
        {this.props.label && <label>{this.props.label}</label>}
      </span>
    );
  }

  _handleClick(event) {
    event.preventDefault();
    if (this.props.enabled) {
      this.setState(
        (state) => ({checked: !state.checked}),
        () => this.props.onClick(this.state.checked)
      );
    }
  }
}
