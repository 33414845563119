import React from 'react';
import PropTypes from 'prop-types';

export default class Image extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
  };

  state = {
    status: 'notLoaded',
  };

  render() {
    return (
      <div className="Image" data-status={this.state.status}>
        <img
          src={this.props.url}
          alt={this.props.alt}
          onDragStart={(event) => event.preventDefault()}
          onTouchStart={(event) => event.preventDefault()}
          onLoad={() => this.setState({status: 'loaded'})}
          onError={() => this.setState({status: 'error'})}
          onAbort={() => this.setState({status: 'error'})}
        />
      </div>
    );
  }
}
