import PropTypes from 'prop-types';

// PropTypes for override params
export const PropTypeColor = PropTypes.string;
export const PropTypeDimension = PropTypes.number; // int, css unit in px

// Validators for injected CSS
// Each of these returns `null` for invalid input.
export const validColor = (colorStr) =>
  colorStr && colorStr.match(/^#[a-f0-9]+$/i) !== null ? colorStr : null;
export const validDimension = (dim) => (dim && typeof dim === 'number' ? `${dim}px` : null);

// Style generators
// Each of these returns a "param: value !important" css rule string
export const style = (name, value) => (value ? `${name}: ${value} !important;` : '');
export const styleIf = (cond, name, value) => (cond ? style(name, value) : '');
