import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {VelocityTransitionGroup} from 'velocity-react';

import {isElementOrInside, isPointInsideElement} from '../../../lib/node_utils';
import {Overlay} from '../Overlay.react';
import Button from './Button';

const transitionDelay = 300;

export default class Dialog extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    visible: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
    onShouldClose: PropTypes.func,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
    canClose: PropTypes.bool,
    showCloseButton: PropTypes.bool,
    frameless: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    canClose: true,
    frameless: false,
    visible: true,
    onClose: () => {},
    onSubmit: () => {},
    onShouldClose: () => true,
  };

  componentDidMount() {
    if (this.props.canClose) {
      document.body.addEventListener('keydown', this._handleDocumentKeyDown);
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this._handleDocumentKeyDown);
  }

  render() {
    const dialogContainer = (
      <div className="DialogContainer" onClick={this._handleContainerClick}>
        <div
          className={classNames('Dialog', this.props.className)}
          ref={(e) => (this._element = e)}
          data-frameless={this.props.frameless}>
          {this.props.title && <h1>{this.props.title}</h1>}
          <div className="Dialog_content">{this.props.children}</div>
          {this.props.showCloseButton && (
            <div className="Dialog_buttons">
              <Button kind="submit" label="Close" onClick={this._close} />
            </div>
          )}
        </div>
      </div>
    );
    return (
      <Overlay onShouldClose={this.props.onShouldClose}>
        <ModalContainer
          visible={this.props.visible}
          fn={(visible) => (
            <VelocityTransitionGroup
              runOnMount
              component="div"
              enter={{animation: 'transition.slideDownIn', duration: transitionDelay}}
              leave={{animation: 'transition.slideDownOut', duration: transitionDelay}}>
              {visible && dialogContainer}
            </VelocityTransitionGroup>
          )}
        />
      </Overlay>
    );
  }

  _close = () => {
    this.props.onClose();
  };

  _shouldClose = () => {
    if (this.props.onShouldClose()) {
      this._close();
    }
  };

  _handleContainerClick = (event) => {
    if (
      this.props.visible &&
      this._element &&
      !(
        isElementOrInside(event.target, this._element) ||
        isPointInsideElement(this._element, [event.clientX, event.clientY])
      )
    ) {
      this._shouldClose();
    }
  };

  _handleDocumentKeyDown = (event) => {
    if (!this.props.visible) {
      return;
    }
    switch (event.keyCode) {
      case 27:
        this._shouldClose();
        break;
      case 13:
        this.props.onSubmit();
        break;
    }
  };
}

class ModalContainer extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    fn: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this._setBackgroundFrozen(this.props.visible);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._setBackgroundFrozen(nextProps.visible);
  }

  componentWillUnmount() {
    this._setBackgroundFrozen(false);
  }

  render() {
    return (
      <VelocityTransitionGroup
        runOnMount
        component="div"
        enter={{animation: 'transition.fadeIn', duration: transitionDelay}}
        leave={{animation: 'transition.fadeOut', duration: transitionDelay}}>
        {this.props.visible && (
          <div
            className="ModalContainer"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowY: 'scroll',
            }}>
            {this.props.fn(this.props.visible)}
          </div>
        )}
      </VelocityTransitionGroup>
    );
  }

  _setBackgroundFrozen(freeze) {
    document.body.style.overflowY = freeze ? 'hidden' : '';
  }
}
