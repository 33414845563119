import React from 'react';
import PropTypes from 'prop-types';

const Labeled = ({label, direction, children}) => {
  return (
    <div className="Labeled" data-direction={direction}>
      <label>{label}</label>
      <div className="Labeled_value">{children}</div>
    </div>
  );
};

Labeled.propTypes = {
  label: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  children: PropTypes.node,
};

Labeled.defaultProps = {
  direction: 'vertical',
};

export default Labeled;
