import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {select, extend, reduce} from 'underscore';

import {findIndex, getHeight} from '../../lib/node_utils';

// Polyfill for MutationObserver, but only in browser mode.
if (typeof window !== 'undefined') {
  require('mutationobserver-shim');
}

const transitionStyles = {
  transition: 'all .3s ease-out',
  WebkitTransition: 'all .3s ease-out',
};

export class Panel extends Component {
  render() {
    return this.props.content;
  }
}

Panel.propTypes = {
  name: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  content: PropTypes.element.isRequired,
};

Panel.defaultProps = {
  visible: true,
};

export class PanelSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: undefined,
    };
    if (window && window.MutationObserver) {
      this._observer = new MutationObserver(() => this._updateHeight());
    }
  }

  componentDidMount() {
    this._mounted = true;

    if (this._node) {
      // TODO: We should ideally observe only the current page.
      if (this._observer) {
        this._observer.observe(this._node, {
          childList: true,
          attributes: true,
          characterData: true,
          subtree: true,
        });
      }
      setTimeout(() => this._updateHeight(), 0);

      // Hack to work around some browsers remembering a bogus scroll position
      this._node.scrollLeft = 0;
    }
  }

  componentWillUnmount() {
    if (this._observer) {
      this._observer.disconnect();
    }
    this._mounted = false;
  }

  componentDidUpdate(/* prevProps, prevState */) {
    this._updateHeight();
  }

  render() {
    const {panels} = this.props;

    const visiblePanels = select(panels, (p) => p.props.visible);
    const count = visiblePanels.length;
    if (count === 0) {
      return null;
    }
    if (count === 1) {
      return visiblePanels[0].props.content;
    }

    const currentIndex = this._getCurrentIndex();
    if (currentIndex === -1) {
      return null;
    }

    const style = {
      overflowX: 'hidden',
    };

    const translation = (100 / count) * currentIndex;

    const containerStyle = {
      display: 'flex',
      flexFlow: 'row nowrap',
      transform: `translateX(-${translation}%)`,
      WebkitTransform: `translateX(-${translation}%)`,
      width: `${Math.round(count * 100)}%`,
      height: this.state.height,
    };

    const currentPageStyle = {
      width: `${100 / count}%`,
      flex: '1',
      overflow: 'hidden',
      opacity: 1.0,
    };

    if (this.props.animate) {
      extend(containerStyle, transitionStyles);
      extend(currentPageStyle, transitionStyles);
    }

    const pageStyle = extend({}, currentPageStyle, {
      opacity: 0,
      visibility: 'hidden',
    });

    const innerStyle = {
      // This causes margins to collapse so we get the right height
      float: 'left',
      width: '100%',
    };

    return (
      <div ref={(node) => (this._node = node)} style={style}>
        <div style={containerStyle}>
          {panels.map((panel, idx) =>
            panel.props.visible ? (
              <div
                key={panel.props.name}
                style={idx === currentIndex ? currentPageStyle : pageStyle}>
                <div style={innerStyle} ref={`page-${idx}`}>
                  {panel.props.content}
                </div>
              </div>
            ) : null
          )}
        </div>
      </div>
    );
  }

  _getCurrentIndex() {
    const {panels} = this.props;
    let {current} = this.props;
    if (!current && panels.length) {
      current = panels[0].props.name;
    }
    return findIndex(panels, (panel) => panel.props.name === current);
  }

  _updateHeight() {
    if (!this._mounted) {
      return;
    }

    const currentIndex = this._getCurrentIndex();
    if (!~currentIndex) {
      return;
    }

    const page = this.refs[`page-${currentIndex}`];
    if (!page) {
      return;
    }

    const height = reduce(page.childNodes, (h, node) => Math.max(h, getHeight(node)), 0);
    if (height !== this.state.height) {
      if (height === 0) {
        // Try again shortly
        return setTimeout(() => this._updateHeight(), 0);
      }
      this.setState({height});
    }
  }
}

PanelSwitcher.propTypes = {
  panels: PropTypes.arrayOf(PropTypes.element).isRequired,
  current: PropTypes.string,
  animate: PropTypes.bool,
};

PanelSwitcher.defaultProps = {
  animate: true,
};
